<script lang="ts" setup>
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';

const globalState = inject('globalState') as GlobalState;
const {  modelValue } = defineProps<{
  modelValue: boolean
}>();

const emit = defineEmits<{
  'click': [],
  'update:modelValue': [value: boolean],
}>();


const localValue = ref(modelValue);

watch(() => modelValue, (value) => {
  localValue.value = value;
});

const click = () => {
  localValue.value = !localValue.value;
  emit('update:modelValue', localValue.value);
  emit('click');
}


</script>

<template>
  <div class="select-none w-fit flex items-center cursor-pointer" @click="click">
    <div class="h-8 w-20 border rounded">
      <div :class="{
          'justify-start' : !modelValue,
          'justify-end' : modelValue
        }" class="flex justify-start box-border h-full">
        <div :class="{
          'bg-slate-200 text-slate-500' : !modelValue,
          'bg-medeval text-white' : modelValue
        }" class=" h-full w-10 rounded flex items-center justify-center  font-semibold">{{ globalState.translate('toggle.' + (modelValue ? 'on' : 'off')) }}</div>
      </div>
    </div>
  </div>
</template>
