import { ref } from 'vue';
import Question, { QuestionOptions } from './Question.js';


export interface TextQuestionConfugrationItem {
    text: string;
}

export default class TextQuestion extends Question {

    
    public answer = ref<string>('');


    constructor(options: QuestionOptions) {
        super(options);
    }


    public setAnswer(response: string, dontSave = false) : void {
        if (response === null) return;
        
        this.answer.value = response;
        this.setAnswered();
        if (!dontSave) this.save();
    }


    public getAnswer() : string {
        return this.answer.value;
    }

    public loadAnswer(input: string) : void {
        this.setAnswer(input, true);
    }

    public getStringAnswer() : string {
        return this.getAnswer().toString();
    }


    public getConfiguration() : TextQuestionConfugrationItem[] {
        return this.options.map((item, index) => {
            return {
                text: item.name,
            };
        });
    }
}