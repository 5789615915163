<script lang="ts" setup>
import { ref, defineEmits, reactive, watch, inject } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import GlobalState from '../../lib/GlobalState.js';


const globalState = inject('globalState') as GlobalState;


interface MarkedRange {
    start: Date;
    end: Date;
}

interface BaseOptions {
    rangeMode: boolean;
    errorMessage?: string | null;
    markedRanges?: MarkedRange[];
}

interface RangeOptions extends BaseOptions {
    rangeStart: Date;
    rangeEnd: Date;
    date?: Date;
}

interface DateOptions extends BaseOptions {
    date: Date;
    rangeStart?: Date;
    rangeEnd?: Date;
}

type CalendarOptions = RangeOptions | DateOptions;


const props = defineProps<CalendarOptions>();

const emit = defineEmits<{
    'update:rangeStart': [date: Date | null],
    'update:rangeEnd': [date: Date | null],
    'update:date': [date: Date],
    'dateSet': [date: Date],
    'rangeSelected': [start: Date, end: Date],
}>();



interface Day {
    day: number;
    date: Date;
    isThisMonth: boolean;
    isWeekend: boolean;
    key: number;
    selectionStart: boolean;
    selectionEnd: boolean;
    selected: boolean;
    additionalSelected?: boolean;
    additionalSelectionStart?: boolean;
    additionalSelectionEnd?: boolean;
}


class Calendar {
    // can a range be selected?
    public readonly rangeMode : boolean;

    // weekday names stay the same
    public readonly weekDayNames : string[] = globalState.getDateFormatter().getWeekDayNames();

    // the current month name
    public monthName: string = '';

    // the user may display an error message
    public errorMessage: string | null = null;

    // the calendar data
    public data: Day[] = [];

    // used to stiwtch motnhs and rendering the calendat
    private monthReference: Date = new Date();

    // the current selection
    private rangeStart: Date | null = null;
    private rangeEnd: Date | null = null;
    private date: Date | null = null;

    // needed for rendering
    public rangeSelected: boolean = false;

    // additional ranges may be marked in the calendar
    private markedRanges: MarkedRange[] = [];



    constructor(options: CalendarOptions) {
        this.rangeMode = options.rangeMode || false;

        if (options.markedRanges) {
            this.markedRanges = this.consolidateMarkedRanges(options.markedRanges);
        }

        if (this.rangeMode) {
            if (!options.rangeStart || !options.rangeEnd) {
                throw new Error('rangeMode requires rangeStart and rangeEnd');
            }

            this.setRangeStart(options.rangeStart);
            this.setRangeEnd(options.rangeEnd);

        } else {
            if (!options.date) {
                throw new Error('date mode requires date');
            }
            this.date = this.getCleanDate(options.date);

            this.refreshData(this.getCleanDate(options.date));
        }

        this.errorMessage = options.errorMessage || null;
    }



    public setRangeStart(date: Date) : void {
        this.rangeStart = this.getCleanDate(date);
        this.refreshData(date);
    }

    public setRangeEnd(date: Date) : void {
        this.rangeEnd = this.getCleanDate(date);
        this.refreshData(date);
    }

    public setDate(date: Date) : void {
        this.date = this.getCleanDate(date);
        this.refreshData(date);
    }


    public previous() : void {
      this.refreshData(new Date(this.monthReference.getFullYear(), this.monthReference.getMonth() - 1, 1));
    }


    public next() : void {
      this.refreshData(new Date(this.monthReference.getFullYear(), this.monthReference.getMonth() + 1, 1));
    }


    public select(day: Day) : void {
      if (this.rangeMode) {
          if (this.rangeStart && this.rangeEnd || !this.rangeStart && !this.rangeEnd) {
              this.rangeStart = day.date;
              this.rangeEnd = null;
              this.refreshData(day.date);
          } else if (this.rangeStart) {
              if (day.date.getTime() < this.rangeStart.getTime()) {
                  this.rangeEnd = this.rangeStart;
                  this.rangeStart = day.date;
              } else if (day.date.getTime() > this.rangeStart.getTime()) {
                  this.rangeEnd = day.date;
              }

              emit('update:rangeStart', this.rangeStart);
              emit('update:rangeEnd', this.rangeEnd);
              emit('rangeSelected', this.rangeStart, this.rangeEnd!);

              this.refreshData(day.date);
          }
      } else {
          this.date = day.date;

          emit('update:date', this.date);
          emit('dateSet', this.date);

          this.refreshData(day.date) 
      }
    }



    private markSelection() : void {
        if (this.rangeMode) {
            if (this.rangeStart && this.rangeEnd) {
                this.rangeSelected = true;
                for (const item of this.data) {
                    if (item.date.getTime() === this.rangeStart.getTime()) {
                        item.selectionStart = true;
                    } else if (item.date.getTime() === this.rangeEnd.getTime()) {
                        item.selectionEnd = true;
                    } else if (item.date.getTime() > this.rangeStart.getTime() && item.date.getTime() < this.rangeEnd.getTime()) {
                        item.selected = true;
                    }
                }
            } else if (this.rangeStart) {
                this.rangeSelected = false;

                const item = this.getItem(this.rangeStart);

                if (item) {
                    item.selectionStart = true;
                }
            }
        } else {
            const item = this.getItem(this.date!);

            if (item) {
                item.selected = true;
            }
        }

        this.markAdditionalRanges();
    }


    private markAdditionalRanges() : void {
        for (const range of this.markedRanges) {
            let previousItem : Day | null = null;
            let nextItem : Day | null = null;


            for (const [index, item] of this.data.entries()) {
                if (index > 0) previousItem = this.data[index - 1];
                if (index < this.data.length - 1) nextItem = this.data[index + 1];
                const itemIsSelected = item.selected || item.selectionStart || item.selectionEnd;

                
                if (item.date.getTime() >= range.start.getTime() && item.date.getTime() <= range.end.getTime()) {
                    if (!itemIsSelected && nextItem && (nextItem.selected || nextItem.selectionStart || nextItem.selectionEnd)) {
                        item.additionalSelectionEnd = true;
                    }
                    
                    if (!itemIsSelected && previousItem && (previousItem.selected || previousItem.selectionStart || previousItem.selectionEnd)) {
                        item.additionalSelectionStart = true;
                    }
                }
                
                if (item.date.getTime() === range.start.getTime()) {
                    if (!itemIsSelected) {
                        item.additionalSelectionStart = true;
                    }
                } else if (item.date.getTime() === range.end.getTime()) {
                    if (!itemIsSelected) {
                        item.additionalSelectionEnd = true;
                    }
                } else if (item.date.getTime() > range.start.getTime() && item.date.getTime() < range.end.getTime()) {
                    if (!itemIsSelected && !item.additionalSelectionStart && !item.additionalSelectionEnd) {
                        item.additionalSelected = true;
                    }
                }

            }
        }
    }


    private consolidateMarkedRanges(ranges: MarkedRange[]) : MarkedRange[] {
        const newRanges : MarkedRange[] = [];

        // ranges can be overlapping, combine those
        for (const range of ranges) {
            let found = false;

            for (const newRange of newRanges) {
                if (range.start.getTime() >= newRange.start.getTime() && range.start.getTime() <= newRange.end.getTime()) {
                    if (range.end.getTime() > newRange.end.getTime()) {
                        newRange.end = range.end;
                    }
                    found = true;
                    break;
                } else if (range.end.getTime() >= newRange.start.getTime() && range.end.getTime() <= newRange.end.getTime()) {
                    if (range.start.getTime() < newRange.start.getTime()) {
                        newRange.start = range.start;
                    }
                    found = true;
                    break;
                }
            }

            if (!found) {
                newRanges.push(range);
            }
        }

        return newRanges;
    }


    private getCleanDate(date: Date) : Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }


    private refreshData(date: Date) : void {
        this.monthReference = date;

        // remove all items
        this.data.splice(0, this.data.length);

        this.monthName = this.getCalendarMothString(date);

        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const weekDay = firstDay.getDay();
        
        const daysToFill = weekDay === 1 ? 7 : weekDay === 0 ? 6 : weekDay - 1;
        for (let i = daysToFill * -1; i < 0; i++) {
            const currentDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i);
            this.pushDayItem(currentDate, date);
        }

        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const daysInMonth = lastDay.getDate();
        for (let i = 0; i < daysInMonth; i++) {
            const currentDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i);
            this.pushDayItem(currentDate, date);
        }

        const daysAfter = 7 - (this.data.length % 7);
        for (let i = 1; i <= daysAfter; i++) {
            const currentDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate() + i);
            this.pushDayItem(currentDate, date);
        }

        if (this.data.length <= 35) {
            for (let i = 1; i <= 7; i++) {
                const currentDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate() + daysAfter + i);
                this.pushDayItem(currentDate, date);
            }
        }

        this.markSelection();
    }


    private pushDayItem(currentDate: Date, currentMonth: Date) : Day {
        this.data.push({
            day: currentDate.getDate(),
            date: currentDate,
            key: currentDate.getTime(),
            isThisMonth: currentDate.getMonth() === currentMonth.getMonth(),
            isWeekend: currentDate.getDay() === 0 || currentDate.getDay() === 6,
            selectionStart: false,
            selectionEnd: false,
            selected: false,
            additionalSelected: false,
            additionalSelectionStart: false,
            additionalSelectionEnd: false,
        });

        return this.data[this.data.length - 1];
    }


    private getItem(date: Date) : Day | null {
        for (const item of this.data) {
            if (item.date.getTime() === date.getTime()) {
                return item;
            }
        }

        return null;
    }

    private getCalendarMothString(date: Date) : string {
        return globalState.getDateFormatter().getMonthName(date) + ' ' + date.getFullYear();
    }
}

const calendar = reactive(new Calendar({
    rangeMode: props.rangeMode || false,
    date: props.date,
    rangeStart: props.rangeStart,
    rangeEnd: props.rangeEnd,
    errorMessage: props.errorMessage,
    markedRanges: props.markedRanges,
} as CalendarOptions));

watch(() => props.rangeStart, () => {
    if (props.rangeStart) calendar.setRangeStart(props.rangeStart);
});

watch(() => props.rangeEnd, () => {
    if (props.rangeEnd) calendar.setRangeEnd(props.rangeEnd);
});

watch(() => props.date, () => {
    if (props.date) calendar.setDate(props.date);
});

</script>

<template>
  <div class="w-fit">
    <div class="flex border rounded-lg" :class="errorMessage ? ' border-red-500' : ''">
      <div class="grid grid-cols-7 bg-white rounded-lg overflow-hidden select-none">
        <div @click="calendar.previous" class="h-12 cursor-pointer flex justify-center items-center hover:bg-slate-200"><ChevronLeftIcon class="h-5 w-5" aria-hidden="true" /></div>
        <div class="col-span-5 h-12 flex items-center justify-center text-lg">{{ calendar.monthName }}</div>
        <div @click="calendar.next" class="h-12 cursor-pointer flex justify-center items-center hover:bg-slate-200"><ChevronRightIcon class="h-5 w-5" aria-hidden="true" /></div>
        
        <div class="h-12 w-12 flex items-center justify-center bg-slate-100" v-for="day of calendar.weekDayNames">{{ day }}</div>
        <div v-for="day of calendar.data"
          @click="calendar.select(day)" 
          :key="day.key" 
          :class="{
            'text-slate-300': !day.isThisMonth,
            'bg-medeval-200': day.selectionStart || day.selectionEnd || day.selected,
            'bg-orange-100': day.additionalSelected || day.additionalSelectionStart || day.additionalSelectionEnd,
            'rounded-none': day.selected && calendar.rangeMode || day.additionalSelected,
            'rounded-r-none': day.selectionStart && calendar.rangeSelected || day.additionalSelectionStart,
            'rounded-l-none': day.selectionEnd && calendar.rangeSelected || day.additionalSelectionEnd,
            'text-slate-400': day.isThisMonth && day.isWeekend,
            'text-slate-900': !day.isWeekend && day.isThisMonth
            }"
          class="h-12 cursor-pointer w-12 flex items-center justify-center rounded-lg hover:bg-medeval hover:text-white" >
          {{ day.date.getDate() }}
        </div>
      </div>
    </div>


    <div v-if="errorMessage" class="h-9">
      <div class="text-red-500 text-sm mt-2">{{ errorMessage }}</div>
    </div>
  </div>
</template>
