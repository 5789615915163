<script setup lang="ts">
import Button from '../../ui/Button.vue';
import GlobalState from '../../../lib/GlobalState.js';
import { inject, ref } from 'vue';
import Mentoring, { Evaluation } from '../src/Mentoring.js';

const globalState = inject('globalState') as GlobalState;


const { mentoring } = defineProps<{
  mentoring: Mentoring
}>();

const pendingMentoringEvaluations = ref<Evaluation[]>(mentoring.getPendingMentoringEvaluations());
const pendingEvaluations = ref<Evaluation[]>(mentoring.getPendingEvaluations());


const emit = defineEmits<{
  'back': [],
  'next': [],
}>();


const back = () => emit('back');

const resume = async() => {
  await mentoring.continue();
  emit('next');
};

const newMentoring = async() => {
  await mentoring.startNew();
  emit('next');
}


</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24 text-lg">
          <div class="text-2xl font-4xl mb-3 ">{{ globalState.translate('mentoring.resume.title') }}</div>
          <div class="mt-8">{{ globalState.translate('mentoring.resume.question') }}</div>
        <div class="font-4xl mb-16">
          <div>
            <div class="mb-4 mt-12 text-lg">{{ globalState.translate('mentoring.personselector.included-evaluations') }}</div>
            <div class="grid grid-cols-3 text-slate-900 border-b-2 text-lg">
              <div>{{ globalState.translate('mentoring.personselector.from') }}</div>
              <div>{{ globalState.translate('mentoring.personselector.to') }}</div>
              <div>{{ globalState.translate('mentoring.personselector.evaluator') }}</div>
            </div>
            <div v-for="evaluation of pendingMentoringEvaluations" :key="evaluation.created.toISOString()" class="grid grid-cols-3 text-lg">
              <div>{{ globalState.formatDate(evaluation.timespanStart) }}</div>
              <div>{{ globalState.formatDate(evaluation.timespanEnd) }}</div>
              <div>{{ evaluation.isSelfEvaluation ? globalState.translate('mentoring.personselector.self-evaluation') : evaluation.user }}</div>
            </div>
          </div>

          <div v-if="pendingEvaluations.length">
            <div class="mb-4 mt-16 text-lg">{{ globalState.translate('mentoring.personselector.not-included-evaluations') }}</div>
            <div class="grid grid-cols-3 text-slate-900 border-b-2 text-lg">
              <div>{{ globalState.translate('mentoring.personselector.from') }}</div>
              <div>{{ globalState.translate('mentoring.personselector.to') }}</div>
              <div>{{ globalState.translate('mentoring.personselector.evaluator') }}</div>
            </div>
            <div v-for="evaluation of pendingMentoringEvaluations" :key="evaluation.created.toISOString()" class="grid grid-cols-3 text-lg">
              <div>{{ globalState.formatDate(evaluation.timespanStart) }}</div>
              <div>{{ globalState.formatDate(evaluation.timespanEnd) }}</div>
              <div>{{ evaluation.isSelfEvaluation ? globalState.translate('mentoring.personselector.self-evaluation') : evaluation.user }}</div>
            </div>
          </div>
        </div>
        <div class="flex">
          <Button  :text="globalState.translate('mentoring.resume.back')" @click="back" class="my-5"></Button>
          <div class="w-full flex justify-end">
            <Button :text="globalState.translate('mentoring.resume.resume')" @click="resume" class="my-5"></Button>
            <Button :text="globalState.translate('mentoring.resume.new')" @click="newMentoring" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

