<script lang="ts" setup>
import { ref, watch } from 'vue';
import OptionButton from './OptionButton.vue';


export interface Option {
  name: string,
  id: number,
  [key: string]: any,
}

interface InternalOption {
  option: Option
  selected: boolean;
}

const props = defineProps<{
  options: Option[],
  modelValue?: Option | null;
}>();

const internalOptions : InternalOption[] = props.options.map(option => {
  return {
    option,
    selected: props.modelValue ? props.modelValue === option : false,
  }
});


const emit = defineEmits<{
  'click': [],
  'update:modelValue': [value: Option],
}>();

watch(props.options, () => {
  for (const option of props.options) {
    const internalOption = internalOptions.find(internalOption => internalOption.option === option);
    if (!internalOption) {
      internalOptions.push({
        option,
        selected: false,
      });
    }
  }

  for (const internalOption of internalOptions) {
    if (!props.options.includes(internalOption.option)) {
      internalOptions.splice(internalOptions.indexOf(internalOption), 1);
    }
  }

  if (props.modelValue) {
    for (const internalOption of internalOptions) {
      internalOption.selected = internalOption.option === props.modelValue;
    }
  }
});

watch(() => props.modelValue, () => {
  for (const internalOption of internalOptions) {
    internalOption.selected = internalOption.option === props.modelValue;
  }
});


const click = (option: InternalOption) => {
  for (const internalOption of internalOptions) {
    internalOption.selected = internalOption === option;
  }

  emit('click');
  emit('update:modelValue', option.option);
}


</script>

<template>
  <div>
    <OptionButton v-for="option in internalOptions" :key="option.option.id" :name="option.option.name" :modelValue="option.selected" @click="click(option)" />
  </div>
</template>
