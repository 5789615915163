// @ts-ignore
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import router from './lib/Router.js';
import GlobalState from './lib/GlobalState.js';
import './index.css';



(async() => {
    const globalState = new GlobalState();
    await globalState.load();


    router.beforeEach(async (to, from, next) => {
        if (to.path === '/auth/validate') {
            // don't do the login check, if the user is already on the validate page
            // this will trigegr an invalid redirect to the sign in page since the login
            // happens after this hook
            next();
            return;
        }
        const isSignedIn = await globalState.getAuthentication().isSignedIn();
        
        if (!isSignedIn && to.path !== '/auth/email-login') {
            globalState.setRedirectUrl(to.path);
            next('/auth/email-login');
        } else {
            next();
        }
    });


    const app = createApp({});

    app.config.errorHandler = (err: Error, vm: any, info: any) => {
        globalState.displayError(err);
    };

    app.provide('globalState', globalState);

    app.use(router);
    app.mount('#app');

})();
