<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = defineProps<{
  modelValue: boolean
}>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean],
}>();



const click = () => {
  emit('update:modelValue', !props.modelValue);
}


</script>

<template>
  <div>
    <div @click="click" :class="modelValue ? 'bg-medeval border-slate-200' : ''" class="border border-slate-300 w-8 h-8 bg-gray-5 rounded hover:border-medeval hover:border-4 cursor-pointer flex items-center justify-center" >
      <div v-if="modelValue" class="material-symbols-outlined text-white">done</div>
    </div>
  </div>
</template>