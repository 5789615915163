<script setup lang="ts">
import Button from '../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';

import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const globalState = inject('globalState') as GlobalState;
const errorMessage = ref<string>('');


const signOut = async() => {
  try {
    await globalState.getAuthentication().signOut();
  } catch (e: any) {
    console.log(e);
    globalState.displayError(e);
  }
  
  window.location.href = '/';
}

signOut();


</script>

<template>
  <div class="h-screen w-screen m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col items-center mb-24 w-1/3">
        <div class="text-xl mb-16"> {{ globalState.translate('auth.signout.text') }}</div>
      </div>
    </div>
  </div>
</template>

