<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

export interface ComboBoxItem {
  id: number;
  name: string;
  [key: string]: any;
  indent?: number;
}

interface InternalComboboxItem extends ComboBoxItem {
  selected: boolean;
}

const props = defineProps<{
  items: ComboBoxItem[],
  modelValue: ComboBoxItem | null,
  errorMessage: string | null,
  label?: string,
  placeholder?: string,
}>();

const emit = defineEmits<{
  'change': [item: ComboBoxItem],
  'update:modelValue': [item: ComboBoxItem | null],
}>();


const items : InternalComboboxItem[] = [];
const preselectedItem = props.modelValue ? items.find(item => item.id === props.modelValue!.id) || null : null;

const query = ref(preselectedItem ? preselectedItem.name : '');
const hasFocus = ref(false);
const showMenu = ref(false);
const isActive = ref(false);
const combobox = ref<HTMLDivElement | null>(null);
const selectedItem = ref<InternalComboboxItem | null>(preselectedItem);
const markedIndex = ref(0);
const itemcontainer = ref<HTMLDivElement | null>(null);
const scrollcontainer = ref<HTMLDivElement | null>(null);


const updateItems = () => {
  items.splice(0, items.length);

  for (const item of props.items) {
    items.push({
      ...item,
      selected: props.modelValue ? props.modelValue === item : false,
    });

    if (props.modelValue && props.modelValue === item) {
      selectedItem.value = items[items.length - 1];
      query.value = item.name;
    }
  }
}

watch([props.items], () => {
  updateItems();
});

updateItems();



document.addEventListener('click', (event) => {
  if (hasFocus.value) return;
  const target = event.target as HTMLElement;
  if (!combobox.value) return;
  const el = combobox.value as HTMLElement;
  if (!el.contains(event.target as HTMLElement)) {
    showMenu.value = false;
    isActive.value = false;
  }
});

const selectItem = (item : InternalComboboxItem) => {
  if (selectedItem.value === item) return;

  if (selectedItem.value) {
    selectedItem.value.selected = false;
  }

  item.selected = true;
  selectedItem.value = item;
  query.value = item.name;

  showMenu.value = false;

  const emitItem = props.items.find(item => item.id === selectedItem.value!.id);
  
  if (emitItem) {
    emit('change', emitItem);
    emit('update:modelValue', emitItem);
  }
}



const filteredItems = computed(() => {
  if ((!selectedItem.value || selectedItem.value && selectedItem.value.name !== query.value) && query.value && query.value.length) {
    markedIndex.value = 0;
    return items.filter(item => item.name.toLowerCase().includes(query.value.toLowerCase()));
  }

  return items;
});


const textChanged = (event : Event) => {
  const queryValue = (event.target as HTMLInputElement).value;
  if  (queryValue === query.value) return;

  if (!selectedItem.value || selectedItem.value && selectedItem.value.name !== query.value) {
    showMenu.value = true;
    markedIndex.value = 0;
    if (selectedItem.value) {
      selectedItem.value.selected = false;
      selectedItem.value = null;
    }
  }

  query.value = queryValue;
}



const scrollToMarked = () => {
  if (scrollcontainer.value) {
    const el = scrollcontainer.value.querySelector(`:nth-child(${markedIndex.value})`);
    if (el) el.scrollIntoView();
  }
};



const navigate = (event : KeyboardEvent) => {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    if (markedIndex.value < filteredItems.value.length - 1) {
      markedIndex.value++;
      scrollToMarked();
    }
  } else if (event.key === 'ArrowUp') {
    event.preventDefault();
    if (markedIndex.value > 0) {
      markedIndex.value--;
      scrollToMarked();
    }
  } else if (event.key === 'Enter') {
    event.preventDefault();
    if (filteredItems.value.length) {
      selectItem(filteredItems.value[markedIndex.value]);
    }
  }
}


watch(() => props.errorMessage, () => {
  if (props.errorMessage && props.errorMessage.length) {
    showMenu.value = false;
  }
});


</script>

<template>
  <div ref="combobox" @click="isActive = true" class="flex flex-col relative z-1">

    <div v-if="label" class="text-gray-600 text-base ml-5 mb-4">{{ label }}</div>

    <div class="relative">
      <div tabindex="0" @keydown="navigate" :class="(errorMessage ? 'border-red-500' : '') + ' ' + (isActive ? 'border-medeval' : 'border-gray-400')" class="relative outline-none h-12 border bg-white rounded-full z-2 flex hover:border-medeval" >
        <input @keyup="textChanged" @focusin="hasFocus = true" @focusout="hasFocus = false" :value="query" @focus="showMenu = true" type="text" class="h-full text-slate-800 w-full rounded-full px-4 py-2 border-none focus:outline-none focus:ring-0 ml-1 " :placeholder="placeholder" />
        <div @click="showMenu = !showMenu" class="w-12 mr-1 flex justify-center items-center cursor-pointer">
          <ChevronUpDownIcon class="w-6 h-6 ChevronUpDownIconm-auto"></ChevronUpDownIcon>
        </div>
      </div>

      <div ref="itemcontainer" v-if="showMenu" @blur="showMenu = false" class="max-h-60 w-full absolute -mt-4 pt-8 pb-2 border-b border-l border-r rounded-b-md border-gray-400 shadow-md shadow-slate-200 z-10 selectbg">
        <div ref="scrollcontainer" class="overflow-auto w-full -mt-2 max-h-48">
          <div v-for="(item, index) of filteredItems" :class="markedIndex === index ? 'bg-slate-100' : '' " class="select-none h-10 flex items-center -ml-3 cursor-pointer" @mousemove="markedIndex = index" @click="selectItem(item)" :key="item.id">
            <div class="ml-6 w-7">
              <CheckIcon v-if="item.selected" class="w-5 h-5"></CheckIcon>
            </div>
            <span class=" overflow-hidden text-ellipsis whitespace-nowrap mr-5" :class="{
              'ml-0 font-bold': item.indent === 0,
              'ml-4 font-semibold text-slate-500': item.indent === 1,
              'ml-8': item.indent === 2,
              'ml-12': item.indent === 3,
              'ml-16': item.indent === 4,
              'ml-20': item.indent === 5,
            }">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="errorMessage" class="h-9">
      <div class="text-red-500 text-sm mt-2 ml-5">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<style scoped>
    .selectbg {
        background-color: #0c365c;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15px, rgba(1255, 255, 255,1) 17px, rgba(1255, 255, 255, 1) 100%);
    }
</style>