import GlobalState from '../GlobalState.js';
import { Locale, UserManager, UserRole } from './index.js';


export interface ReferencedUser {
    id: number;
    name: string;
}


export class User {
    public _firstName: string = '';
    public _lastName: string = '';
    public _email: string = '';
    public _id: number | null = null;
    public registrationId: string = '';

    public firstnameError: string = '';
    public lastnameError: string = '';
    public emailError: string = '';
    public registrationIdError: string = '';
    public userRoleError: string = '';
    public menteeOfError: string = '';
    public localeError: string = '';
    
    public canMentor: boolean = false;
    public canBeMentored: boolean = false;

    private _userRoles: UserRole[] = [];
    public mentorOf: ReferencedUser[] = [];
    public _menteeOf: ReferencedUser | null = null;
    private _locale: Locale | null = null;

    private isNewUser : boolean = false;
    private readonly userManager: UserManager;
    private readonly globalState: GlobalState;



    constructor(userManager: UserManager, globalState: GlobalState) {
        this.userManager = userManager;
        this.globalState = globalState;
    }


    public async load(id: number) : Promise<void> {
        const locales = await this.userManager.getLocales();
        const roles = await this.userManager.getRoles();
        const mentors = await this.userManager.getMentors();

        const response = await this.globalState.getClient().get(`/rf/admin/user/${id}`)
            .expect(200)
            .useRole('user-admin')
            .send();

        const user = await response.json();
        

        this._firstName = user.firstName;
        this._lastName = user.lastName;
        this._email = user.email;
        this._id = user.id;
        this.registrationId = user.registrationId;

        if (user.userRoleIds) {
            for (const roleId of user.userRoleIds) {
                const role = roles.find(r => r.id === roleId);
                if (role) this.addRole(role);
            }
        }

        if (user.localeId) this.locale = locales.find(l => l.id === user.localeId)!;
        if (user.mentorId) this.menteeOf = mentors.find(m => m.id === user.mentorId)!;
    }


    public async save() : Promise<void> {
        const isValid = await this.validate();

        if (!isValid) {
            throw new Error('User is not valid');
        };

        const payload = {
            firstName: this._firstName,
            lastName: this._lastName,
            email: this._email,
            userRoleIds: this._userRoles.map(r => r.id),
            mentorId: this._menteeOf?.id,
            localeId: this._locale?.id,
            registrationId: this.registrationId
        };

        if (this.id) {
            const response = await this.globalState.getClient().update('/rf/admin/user/' + this.id)
                .expect(200, 409)
                .useRole('user-admin')
                .setJSONBody(payload).send();
            
            if (response.status() === 409) throw new Error('User already exists');
        } else {
            const response = await this.globalState.getClient().post('/rf/admin/user/create')
                .expect(200, 409)
                .useRole('user-admin')
                .setJSONBody(payload).send();

            if (response.status() === 409) throw new Error('User already exists');
        
            const { id } = await response.json();
            this.id = id;
        }


        if (this.globalState.getAuthentication().getUserId() === this.id) {
            await this.globalState.getAuthentication().refreshJWT();
            await this.globalState.getTranslations().reload();
        }
    }


    public async deactivate() : Promise<void> {
        if (!this.id) throw new Error('User has no id');

        await this.globalState.getClient().delete('/rf/admin/user/' + this.id)
            .expect(200)
            .useRole('user-admin')
            .send();
    }

    public hasRole(role: string) : boolean {
        return this._userRoles.some(r => r.roles.includes(role));
    }
    

    public async validate() : Promise<boolean> {
        let status = true;

        if (!this._firstName) this.firstnameError = this.globalState.translate('user-manager.edit-user.error.frist-name'), status = false;
        if (!this._lastName) this.lastnameError = this.globalState.translate('user-manager.edit-user.error.last-name'), status = false;
        if (!this._email) this.emailError = this.globalState.translate('user-manager.edit-user.error.email'), status = false;
        if (!this._userRoles.length) this.userRoleError = this.globalState.translate('user-manager.edit-user.error.group'), status = false;
        if (!this._locale) this.localeError = this.globalState.translate('user-manager.edit-user.error.locale'), status = false;


        // check if the user is an exception
        const reg = /^(?:(?:pascal.frey|tobi.anliker)\+[^@]+@gmail.com)|(?:lina\+[^@]+@vanderweg.ch)$/gi;
        if (this._email && reg.test(this._email)) return status;

        const domains = await this.userManager.getDomains();
        const domainString = domains.map(d => '@' + d.domain).join(' or ');
        if (this._email && !domains.some(d => this._email.endsWith(d.domain))) this.emailError = this.globalState.translate('user-manager.edit-user.error.emai-domain', [['domains', domainString]]), status = false;

        return status;
    }

    public create() : User {
        this.isNewUser = true;
        return this;
    }


    public addRole(userRole: UserRole) {
        if (this._userRoles.find(r => r.id === userRole.id)) return;
        this._userRoles.push(userRole);
        this.checkFlags();

        this.userRoleError = '';
    }

    public removeRole(userRole: UserRole) {
        this._userRoles = this._userRoles.filter(r => r.id !== userRole.id);
        this.checkFlags();

        this.userRoleError = '';
    }

    private checkFlags() {
        this.canMentor = this._userRoles.some(r => r.roles.includes('mentor'));
        this.canBeMentored = this._userRoles.some(r => r.roles.includes('mentee'));

        if (!this.canBeMentored) this.removeMentor();
        if (!this.canMentor) this.removeMentees();
    }


    public get userRoles() : UserRole[] {
        return this._userRoles;
    }

    public set menteeOf(mentee: ReferencedUser | null) {
        if (mentee) this.setMentor(mentee);
        else this.removeMentor();
    }

    public get menteeOf() : ReferencedUser | null {
        return this._menteeOf;
    }

    public set locale(locale: Locale) {
        this._locale = locale;
        this.localeError = '';
    }

    public get locale() : Locale | null {
        return this._locale;
    }

    public set firstName(firstName: string) {
        this._firstName = firstName;
        if (firstName) this.firstnameError = '';
    }

    public get firstName() : string {
        return this._firstName;
    }

    public set lastName(lastName: string) {
        this._lastName = lastName;
        if (lastName) this.lastnameError = '';
    }

    public get lastName() : string {
        return this._lastName;
    }

    public set email(email: string) {
        this._email = email;
        if (email) this.emailError = '';
    }

    public get email() : string {
        return this._email;
    }

    public set id(id: number | null) {
        this._id = id;
    }

    public get id() : number | null {
        return this._id;
    }

    private setMentor(mentor: ReferencedUser) {
        if (!this.canBeMentored) throw new Error('User cannot be mentored');
        this.menteeOfError = '';
        this._menteeOf = mentor;
    }

    private removeMentor() {
        this._menteeOf = null;
    }

    

    private addMentee(mentee: ReferencedUser) {
        if (!this.canMentor) throw new Error('User cannot mentor');
        const existing = this.mentorOf.find(m => m.id === mentee.id);
        if (existing) return;

        this.mentorOf.push(mentee);
    }

    private removeMentee(mentee: ReferencedUser) {
        if (!this.canMentor) throw new Error('User cannot mentor');
        this.mentorOf = this.mentorOf.filter(m => m.id !== mentee.id);
    }


    private removeMentees() {
        this.mentorOf.splice(0, this.mentorOf.length);
    }
}
