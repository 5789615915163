import { ref } from "vue";
import Question, { QuestionOptions } from "./Question";


export default class Textquestion extends Question {

    public answer = ref<string>('');


    constructor(options: QuestionOptions) {
        super(options);

        if (options.answer) {
            this.setAnswer(options.answer);
        }
    }


    public setAnswer(response: string) : void {
        if (response === null) return;
        
        this.answer.value = response;
        this.setAnswered();
    }


    public getAnswer() : string {
        return this.answer.value;
    }
}