<script setup lang="ts">
import Button from '../../ui/Button.vue';
import GlobalState from '../../../lib/GlobalState.js';
import { inject, ref } from 'vue';

const globalState = inject('globalState') as GlobalState;


const emit = defineEmits<{
  'back': [],
}>();


const back = () => emit('back');


</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24 text-lg">
        <div class="mt-8">{{ globalState.translate('mentoring.no-evaluations.message') }}</div>
       
        <div class="flex">
          <Button  :text="globalState.translate('mentoring.no-evaluations.back')" @click="back" class="my-5"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

