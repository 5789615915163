<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter, useRoute } from 'vue-router';
import Button from '../ui/Button.vue';
import { UserListUser } from '../../lib/user/UserManager.js';

const router = useRouter();
const globalState = inject('globalState') as GlobalState;
const userManager = globalState.getUserManager();
const users = ref<UserListUser[]>([]);


(async() => {
  for (const user of await userManager.listUsers()) users.value.push(user);
})();


</script>
<template>
  <div class="">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('user.list.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('user.list.description') }}</p>
      </div>
    </div>
    <div class="w-full flex justify-end">
        <Button :text="globalState.translate('user.list.listaction.add')" :is-primary="true" @click="$router.push('/admin/user/new')" />
      </div>
    <div class="mt-4 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base text-gray-300 sm:pl-0">{{ globalState.translate('user.list.listtitle.id') }}</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0">{{ globalState.translate('user.list.listtitle.name') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('user.list.listtitle.email') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('user.list.listtitle.role') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('user.list.listtitle.mentor') }}</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="user in users" :key="user.id">
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-300 sm:pl-0">{{ user.id }}</td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-base  text-gray-900 sm:pl-0">{{ user.name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ user.email }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ user.userRoles.join(', ') }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ user.mentoredBy }}</td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-base font-medium sm:pr-0">
                  <div @click="router.push(`/admin/user/${user.id}`)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('user.list.listaction.edit') }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>