import YesNoQuestion from './YesNoQuestion.js';
import MandatoryScaleQuestion from './MandatoryScaleQuestion.js';
import ScaleQuestion from './ScaleQuestion.js';
import Question from './Question.js';
import TextQuestion from './TextQuestion';
import MandatoryAbsoluteScaleQuestion from './MandatoryAbsoluteScaleQuestion';
import GlobalState from '../../../lib/GlobalState.js';
import YesNoScaleQuestion from './YesNoScaleQuestion.js';
import { useRoute } from 'vue-router';



export interface QuestionOption {
    name: string;
    order: number;
    value: number;
}

interface QuestionType {
    identifier: string;
    options: QuestionOption[]
}

interface QuestionData {
    id: number;
    question: string;
    dimension: string;
    order: number;
    type: QuestionType;
}

interface Questionnaire {
    id: number;
    questions: QuestionData[];
}


interface EvaluationAnswerItem {
    questionId: number;
    answer: string;

}

interface EvaluationData {
    id: number;
    evaluationUserId: number;
    reviewerUserId: number,
    evaluationStartDate: Date,
    evaluationEndDate: Date,
    evaluationDate: Date,
    answers: EvaluationAnswerItem[];
}


export default class Evaluation {


    // the suer that is evaluated
    private evaluationUserId?: number;
    private evaluationUserName?: string;

    // the reviewer
    private reviewerId?: number;
    private questionnaireId?: number;
    private evaluationId?: number;

    // has the evaluation been created?
    private evaluationCreated: boolean = false;


    // end date of the previous evaluation
    private lastEvaluationEndDate : Date | null = null;

    // start date of the current evaluation
    private evaluationStartDate : Date | null = null;

    // end date of the current evaluation
    private evaluationEndDate : Date | null = null;

    // date when the evaluation has taken place
    private evaluationDate: Date | null = null;

    // questions and answers
    private readonly questions : Question[] = [];

    // the question that is currently being answered
    private currentQuestionIndex = 0;

    private readonly globalState : GlobalState;

    public readonly role : string = 'reviewer';

    constructor(globalState : GlobalState) {
        this.globalState = globalState;

        if (this.globalState.getAuthentication().isSelfEvaluator() && this.isSelfEvaluation()) {
            this.role = 'self-evaluator';
        } else {
            this.role = 'reviewer';
        }
    }


    public async resume({
        evaluationUserId,
        reviewerId,
        evaluationUserName,
        evaluationId,
        evaluationStartDate,
        evaluationEndDate,
        evaluationDate,
    } : {
        evaluationUserId : number,
        reviewerId : number,
        evaluationUserName : string,
        evaluationId : number,
        evaluationStartDate : Date,
        evaluationEndDate : Date,
        evaluationDate : Date,
    }) {
        this.reviewerId = reviewerId;
        this.evaluationUserId = evaluationUserId;
        this.evaluationUserName = evaluationUserName;
        this.evaluationId = evaluationId;
        this.evaluationStartDate = evaluationStartDate;
        this.evaluationEndDate = evaluationEndDate;
        this.evaluationDate = evaluationDate;

        await this.loadQuestionnaire();
        await this.loadEvaluation(evaluationId);
        this.evaluationCreated = true;
    }



    private async loadEvaluation(evaluationId: number) : Promise<void> {
        const response = await this.globalState.getClient()
            .get('/rf/eval')
            .setParameters({
            evalUserId: this.evaluationUserId!,
            evaluationId,
        })
        .useRole(this.role)
        .expect(200)
        .send()

        if (response.hasStatus(200)) {
            const data = await response.json() as EvaluationData;
            this.evaluationId = data.id;

            this.evaluationStartDate = new Date(data.evaluationStartDate);
            this.evaluationEndDate = new Date(data.evaluationEndDate);
            this.evaluationDate = new Date(data.evaluationDate);

            for (const answer of data.answers) {
                const question = this.questions.find(q => q.id === answer.questionId);
                if (question) {
                    question.loadAnswer(answer.answer);
                }
            }

        }
    }



    public async startNew({
        evaluationUserId,
        reviewerId,
        evaluationUserName,
    } : {
        evaluationUserId : number,
        reviewerId : number,
        evaluationUserName : string,
    }) {
        this.reviewerId = reviewerId;
        this.evaluationUserId = evaluationUserId;
        this.evaluationUserName = evaluationUserName;

        await this.loadQuestionnaire();
    }


    private async loadQuestionnaire() : Promise<void> {
        const response = await this.globalState.getClient().get('/rf/eval/questionnaire')
            .setParameter('evalUserId', this.evaluationUserId)
            .useRole(this.role)
            .send();

        if (response.hasStatus(200)) {
            const data = await response.json() as Questionnaire;
            this.questionnaireId = data.id;
            if (data) this.setupQuestions(data.questions);
        }
    }


    private async createEvaluation() : Promise<void> {
        const response = await this.globalState.getClient().post('/rf/eval')
            .setJSONBody({
                evaluationUserId: this.evaluationUserId,
                reviewerUserId: this.reviewerId,
                evaluationStartDate: this.evaluationStartDate,
                evaluationEndDate: this.evaluationEndDate,
                questionnaireId: this.questionnaireId,
            })
            .expect(200)
            .useRole(this.role)
            .send();

        if (response.hasStatus(200)) {
            const data = await response.json() as { id: number };
            this.evaluationId = data.id;
        }
    }


    public getEvaluationUserName() : string {
        return this.evaluationUserName!;
    }

    public getLastEvaluationDate() : Date  {
        if (!this.lastEvaluationEndDate) {
            throw new Error('Cannot get last evaluation date. No evaluation has been started');
        }

        return this.lastEvaluationEndDate;
    }

    public getEvaluationDate() : Date {
        if (!this.evaluationDate) {
            throw new Error('Cannot get evaluation date. No evaluation has been started');
        }

        return this.evaluationDate;
    }

    public getEvaluationStartDate() : Date {
        if (!this.evaluationStartDate) {
            throw new Error('Cannot get evaluation start date. No evaluation has been started');
        }

        return this.evaluationStartDate;
    }

    public getEvaluationEndDate() : Date {
        if (!this.evaluationEndDate) {
            throw new Error('Cannot get evaluation end date. No evaluation has been started');
        }

        return this.evaluationEndDate;
    }

    public getNextQuestion() : Question | null {
        if (this.currentQuestionIndex >= this.questions.length) return null;

        return this.questions[this.currentQuestionIndex++];
    }


    public getPreviousQuestion() : Question | null {
        if (this.currentQuestionIndex <= 0) return null;

        return this.questions[this.currentQuestionIndex--];
    }

    public getQuestions() : Question[] {
        return this.questions;
    }





    public async setDates(startDate: Date, endDate: Date) : Promise<void> {
        this.evaluationStartDate = startDate;
        this.evaluationEndDate = endDate;
        this.evaluationDate = new Date();

        if (!this.evaluationCreated) {
            this.evaluationCreated = true;
            await this.createEvaluation();
        } else {
            await this.globalState.getClient().update(`/rf/eval/${this.evaluationId}/dates`)
                .setJSONBody({
                    start: this.evaluationStartDate,
                    end: this.evaluationEndDate,
                })
                .useRole(this.role)
                .expect(200)
                .send();
        }
    }

    public hasDatesSet() : boolean {
        return this.evaluationStartDate !== null && this.evaluationEndDate !== null;
    }

    private async setupQuestions(questionData : QuestionData[]) : Promise<void> {
        
        for (const item of questionData) {
            const options = {
                id: item.id,
                text: item.question,
                category: item.dimension,
                order: item.order,
                options: item.type.options,
                evaluation: this
            };

            switch (item.type.identifier) {
                case 'mandatory-scale':
                    this.questions.push(new MandatoryScaleQuestion(options));
                    break;
                case 'mandatory-absolute-scale':
                    this.questions.push(new MandatoryAbsoluteScaleQuestion(options));
                    break;
                case 'yes-no-scale':
                    this.questions.push(new YesNoScaleQuestion(options));
                    break;
                case 'scale':
                    this.questions.push(new ScaleQuestion(options));
                    break;
                case 'yes-no':
                    this.questions.push(new YesNoQuestion(options));
                    break;
                case 'text':
                    this.questions.push(new TextQuestion(options));
                    break;
                default:
                    throw new Error(`Failed to set up questions: Invalid question type: ${item.type}`);
            }
        }
    }


    public async saveQuestion(question: Question) : Promise<void> {
        await this.globalState.getClient().post('/rf/eval/answer')
            .setJSONBody({
                evaluationId: this.evaluationId,
                questionId: question.id,
                answer: question.getStringAnswer(),
            })
            .useRole(this.role)
            .expect(200)
            .send();
        }

    public async finish() : Promise<void> {
        await this.globalState.getClient().update(`/rf/eval/${this.evaluationId}/finished`)
            .expect(200)
            .useRole(this.role)
            .send();
    }

    public async delete() : Promise<void> {
        await this.globalState.getClient().delete('/rf/eval/' + this.evaluationId)
            .expect(200)
            .useRole(this.role)
            .send();
    }


    public isSelfEvaluation() : boolean {
        return window.location.pathname.endsWith('/self');
    }
}