import Authentication from './Authentication.js';
import ClientRequest from './ClientRequest.js';
import GlobalState from '../GlobalState.js';


export interface QueryParameters {
    [key: string]: string | number | boolean 
}

export interface RequestConfig {
    method: string,
    path: string,
    parameters?: QueryParameters,
    body?: any,
    headers?: {
        [key: string]: string,
    },
    noJWTRefresh?: boolean,
    no401Retry?: boolean,
}




export default class Client {
    
    private readonly url = `https://api.${window.location.hostname}`;
    private locale = 'en---';
    private readonly globalState: GlobalState;
    

    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public update(path: string) : ClientRequest {
        return new ClientRequest({
            globalState: this.globalState,
            method: 'PATCH',
            path,
        });
    }

    public delete(path: string) : ClientRequest {
        return new ClientRequest({
            globalState: this.globalState,
            method: 'DELETE',
            path,
        });
    }

    public post(path: string) : ClientRequest {
        return new ClientRequest({
            globalState: this.globalState,
            method: 'POST',
            path,
        });
    }

    public get(path: string) : ClientRequest {
        return new ClientRequest({
            globalState: this.globalState,
            method: 'GET',
            path,
        });
    }

    getAPIUrl() : string {
        return this.url;
    }


    private getAuthentication() : Authentication {
        return this.globalState.getAuthentication();
    }

    /**
     * Execute a request against the API.
     * 
     * @param path request path
     * @param config the configuration for the request
     * @param retryAttempt do not set this! This is used internally to retry a request after a 401 response.
     */
    public async request(config: RequestConfig, retryAttempt = false) : Promise<Response> {
        const headers = config.headers ? structuredClone(config.headers) : {};
        const method = config.method.toLocaleUpperCase();
        let body : any;

        if (config.no401Retry) retryAttempt = true;

        if (!headers['Accept']) headers['Accept'] = 'application/json';
        if (!headers['x-csrf-token']) headers['x-csrf-token'] = this.getAuthentication().getCSRFToken();
        if (!headers['Accept-Language']) headers['Accept-Language'] = this.locale;
        
        if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
            body = config.body;
        }
        
        let url = this.url + config.path;

        // @ts-ignore
        if (config.parameters) url += '?'  + new URLSearchParams(config.parameters);
        
        let response;

        try {
            response = await fetch(url, {
                method,
                credentials: 'include',
                headers,
                body,
            });
        } catch (e: any) {
            this.globalState.displayError(e);
            throw e;
        }


        // this may be causedd by an expired jwt token, try to refresh anr repeat the request
        if (response.status === 401 && !config.noJWTRefresh) {
            if (retryAttempt) {
                console.log('not refreshing jwt token, already tried to refresh it');
                return response;
            }

            const refreshOk = await this.getAuthentication().refreshJWT();
            
            if (refreshOk) {
                const secondReponse = await this.request(config, true);

                await this.getAuthentication().loadUserInfo();
                
                if (secondReponse.status === 401) {
                    if (!this.getAuthentication().isSignedIn()) {
                        // toddo, show login overlay to continue
                        // @lina: be aware, that the refresh auth endpoint may retun 
                        // a not ok status code, if the user is not signed in anymore
                        return secondReponse;
                    } else {
                        return secondReponse;
                    }
                }

                return secondReponse;
            } else {
                return response;
            }
        }

        return response;
    }


    public getURL() : string {
        return this.url;
    }
}