export interface DimensionParam {
    width: string;
    height: string;
}


export interface Dimension {
    width: number;
    height: number;
}



export const propsConfig = {
    width: {
        type: String,
        default: '0'
    },
    height: {
        type: String,
        default: '0'
    },
}





export default class SVGRenderer {


    private readonly width : number = 0;
    private readonly height : number = 0;

    constructor(width ?: number, height ?: number) {
        if (width) this.width = width;
        if (height) this.height = height;
    }


    public getDimensions({width, height} : DimensionParam) : Dimension {
        const n_width = parseInt(width, 10);
        const n_height = parseInt(height, 10);

        const ratio = this.width / this.height;
        let realHeight = 0;
        let realWidth = 0;

        if (n_width === 0) {
            if (n_height === 0) {
                realHeight = this.height;
                realWidth = this.width;
            } else {
                realHeight = n_height;
                realWidth = n_height * ratio;
            }
        } else {
            if (n_height === 0) {
                realWidth = n_width;
                realHeight = n_width * ratio;
            } else {
                realWidth = n_width;
                realHeight = n_height;
            }
        }
        
        return {
            width: Math.round(realWidth),
            height: Math.round(realHeight),
        }
    }
}