import Question from "./Question";
import TextQuestion from "./TextQuestion";
import GlobalState from '../../../lib/GlobalState.js';

export interface Evaluation {
    created: Date,
    timespanStart: Date,
    timespanEnd: Date,
    user: string,
    id: number,
    isSelfEvaluation: boolean;
}
  
export interface MentoringData {
    created: Date,
    id: number,
    evaluations: Evaluation[],
    reportId: number,
}

interface Report {
    id: number,
    name: string,
    identifier: string,
}
  
interface ReportData {
    report: Report,
    evaluations: Evaluation[],
}


interface MentoringQuestion {
    id: number;
    category: string;
    type: 'text';
    order: number;
    answer?: string;
}

interface Dimension {
    id: number;
    name: string;
    order: number;
    answer?: string;
}


export interface Person {
    id: number;
    name: string;
}

export default class Mentoring {
    
    private nextDate : Date | null = null;

    private menteeUserId: number | null = null;
    private mentorUserId: number;
    private menteeName: string | null = null;
    private mentoringId?: number;
    private reportId?: number;

    // questions and answers
    private readonly questions : Question[] = [];

    private readonly globalState : GlobalState;

    public static readonly role = 'mentor';

    private pendingMentorings : MentoringData[] = [];
    private pendingEvaluations : Evaluation[] = [];


    constructor({
        mentorUserId,
        globalState,
    } : { 
        mentorUserId: number,
        globalState: GlobalState,
    }) {
        this.mentorUserId = mentorUserId;
        this.globalState = globalState;
    }


    public async setPerson(menteeUserId: number, menteeName: string) : Promise<void> {
        this.menteeUserId = menteeUserId;
        this.menteeName = menteeName;

        await this.loadPendingEvaluations();
        await this.loadPendingMentorings();
    }


    public async create() : Promise<void> {
        if (this.menteeUserId === null) throw new Error('Cannot create mentoring: Mentee not set');
        if (this.reportId === undefined) throw new Error('Cannot create mentoring: Report not set');
        if (this.pendingEvaluations.length === 0) throw new Error('Cannot create mentoring: No pending evaluations');

        const response = await this.globalState.getClient().post('/rf/mentoring')
            .setJSONBody({
                reportId: this.reportId,
                evaluationIds: this.pendingEvaluations.map(e => e.id),
                menteeUserId: this.menteeUserId,
            })
            .useRole(Mentoring.role)
            .expect(200)
            .send();

        const { mentoringId, dimensions } = await response.json() as { mentoringId: number, dimensions: Dimension[]};

        this.mentoringId = mentoringId;
        const questions : MentoringQuestion[] = [];

        for (const dimension of dimensions) {
            questions.push({
                id: dimension.id,
                category: dimension.name,
                type: 'text',
                order: dimension.order,
            });
        }

        this.setupQuestions(questions);
    }


    public async finish() {
        await this.globalState.getClient().update(`/rf/mentoring/${this.mentoringId}/finished`)
            .expect(200)
            .useRole(Mentoring.role)
            .send();
    }


    public getMenteeUserId() : number {
        if (this.menteeUserId === null) throw new Error('Mentee not set');
        return this.menteeUserId;
    }

    public getMenteeUserName() : string {
        if (this.menteeName === null) throw new Error('Mentee not set');
        return this.menteeName;
    }


    public getMentoringId() : number | undefined {
        return this.mentoringId;
    }
    
    public getQuestions() : Question[] {
        return this.questions;
    }


    public async startNew() : Promise<void> {
        if (this.menteeUserId === null) throw new Error('Cannot delete mentoring: Mentee not set');
        if (this.pendingMentorings.length === 0) throw new Error('Cannot delete mentoring: No pending mentorings');

        await this.globalState.getClient().delete(`/rf/mentoring/${this.pendingMentorings[0].id}`)
            .expect(200)
            .useRole(Mentoring.role)
            .send();

        
        await this.loadPendingEvaluations();
        await this.create();
    }



    public async continue() : Promise<void> {
        if (this.menteeUserId === null) throw new Error('Cannot continue mentoring: Mentee not set');
        if (this.pendingMentorings.length === 0) throw new Error('Cannot continue mentoring: No pending mentorings');

        this.mentoringId = this.pendingMentorings[0].id;
        this.reportId = this.pendingMentorings[0].reportId;

        const response = await this.globalState.getClient().get(`/rf/mentoring/${this.mentoringId}`)
            .setParameter('reportId', this.reportId)
            .expect(200)
            .useRole(Mentoring.role)
            .send();

        const { dimensions } = await response.json() as { dimensions: Dimension[]};
        const questions : MentoringQuestion[] = [];

        for (const dimension of dimensions) {
            questions.push({
                id: dimension.id,
                category: dimension.name,
                type: 'text',
                order: dimension.order,
                answer: dimension.answer,
            });
        }

        this.setupQuestions(questions);
    }



    public setNextDate(date: Date) : void {
        this.nextDate = date;
    }

    public getNextDate() : Date | null {
        return this.nextDate;
    }


    private async setupQuestions(questionData : MentoringQuestion[]) : Promise<void> {
        questionData.sort((a, b) => a.order - b.order);

        for (const item of questionData) {
            const options = { ...item, mentoring: this };
            if (item.type === 'text') {
                this.questions.push(new TextQuestion(options));
            } else {
                throw new Error(`Failed to set up questions: Invalid question type: ${item.type}`);
            }
        }
    }


    public async saveQuestion(question: Question) : Promise<void> {
        await this.globalState.getClient().post('/rf/mentoring/answer')
            .setJSONBody({
                mentoringId: this.mentoringId,
                dimensionId: question.id,
                answer: question.getAnswer(),
            })
            .expect(200)
            .useRole(Mentoring.role)
            .send();
    }



    public async createSchedule(date: Date) : Promise<void> {
        await this.globalState.getClient().post('/rf/mentoring/schedule')
            .setJSONBody({
                menteeUserId: this.menteeUserId,
                date: date.toISOString(),
            })
            .expect(200)
            .useRole(Mentoring.role)
            .send();
    }


    public async updateSchedule(id: number, date: Date) : Promise<void> {
        await this.globalState.getClient().update(`/rf/mentoring/schedule/${id}`)
            .setJSONBody({
                date: date.toISOString(),
            })
            .expect(200)
            .useRole(Mentoring.role)
            .send();
    }


    public async getSchedule() : Promise<{date: Date, id: number} | null> {
        const response = await this.globalState.getClient().get('/rf/mentoring/schedule')
            .setParameter('menteeUserId', this.menteeUserId)
            .expect(200, 404)
            .useRole(Mentoring.role)
            .send();

        if (response.hasStatus(404)) return null;

        const { date, id } = await response.json() as { date: string, id: number};

        if (!date) return null;

        return {
            date: new Date(date),
            id,
        };
    }


    public async loadPersons() : Promise<Person[]> {
        const response = await this.globalState.getClient().get('/rf/mentoring/mentees')
            .expect(200)
            .useRole(Mentoring.role)
            .send();
        
        const data = await response.json() as {id: number, firstName: string, lastName: string}[];

        return data.map(({ id, firstName, lastName }) => ({
            id,
            name: `${firstName} ${lastName}`,
        }));
    }


    public hasPendingMentorings() : boolean {
        return this.pendingMentorings.length > 0;
    }

    public getPendingMentoringEvaluations() : Evaluation[] {
        return this.pendingMentorings[0].evaluations;
    }

    private async loadPendingMentorings() : Promise<void> {
        if (this.menteeUserId === null) throw new Error('Cannot load pending mentorings: Mentee not set');

        const response = await this.globalState.getClient().get('/rf/mentoring/pending-mentorings')
          .setParameters({ menteeUserId: this.menteeUserId })
          .useRole(Mentoring.role)
          .expect(200, 404)
          .send();
      
      
        if (response.status() === 200) {
            const mentorings = await response.json() as MentoringData[];
            this.pendingMentorings = mentorings.map(m => ({
                created: new Date(m.created),
                id: m.id,
                evaluations: m.evaluations.map(e => ({
                    created: new Date(e.created),
                    timespanStart: new Date(e.timespanStart),
                    timespanEnd: new Date(e.timespanEnd),
                    user: e.user,
                    id: e.id,
                    isSelfEvaluation: e.isSelfEvaluation,
                })),
                reportId: m.reportId,
            }));
        }
    }


    public hasPendingEvaluations() : boolean {
        return this.pendingEvaluations.length > 0;
    }

    public getPendingEvaluations() : Evaluation[] {
        return this.pendingEvaluations;
    }

    private async loadPendingEvaluations() : Promise<void> {
        if (this.menteeUserId === null) throw new Error('Cannot load pending evaluations: Mentee not set');

        const response = await this.globalState.getClient().get('/rf/mentoring/pending-evaluations')
          .setParameters({ menteeUserId: this.menteeUserId })
          .useRole(Mentoring.role)
          .expect(200, 404)
          .send();
      
      
        if (response.status() === 200) {
            const data = await response.json() as ReportData[];
            console.log(data);

            if (data.length > 0) {
                this.pendingEvaluations = data[0].evaluations.map(e => ({
                    created: new Date(e.created),
                    timespanStart: new Date(e.timespanStart),
                    timespanEnd: new Date(e.timespanEnd),
                    user: e.user,
                    id: e.id,
                    isSelfEvaluation: e.isSelfEvaluation,
                }));

                this.reportId = data[0].report.id;
            }
        }
    }
}