<script setup lang="ts">
import Button from '../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';

import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const globalState = inject('globalState') as GlobalState;
const errorMessage = ref<string>('');


const signIn = async() => {
  const oneTimePassword = route.query.token as string;
  const targetRoute = route.query.route as string;
  const validToken = await globalState.getAuthentication().OTPLogin(oneTimePassword);

  if (validToken) {
    router.push(targetRoute || '/app/dashboard');
  } else {
    errorMessage.value = globalState.translate('auth.validate.error');
  }
}

signIn();

const goToLogin = () => {
  window.location.href = '/';
}

</script>

<template>
  <div class="h-screen w-screen flex items-center justify-center ">
      <div class="flex flex-col items-center mb-24 w-1/3">
        <div v-if="errorMessage" class="flex flex-col items-center justify-center" >
          <div class="text-red-500 mb-8 text-xl">{{ errorMessage }}</div>
          <Button @click="goToLogin" class="mt-8" :text="globalState.translate('auth.validate.goto-login')"></Button>
        </div>
        <div v-else class="text-xl mb-16"> {{ globalState.translate('auth.validate.text') }}</div>
      </div>
  </div>
</template>

