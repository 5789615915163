<script setup lang="ts">
import ComboBox, { ComboBoxItem } from '../../ui/ComboBox.vue';
import Button from '../../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../../lib/GlobalState.js';
import Evaluation from '../src/Evaluation.js';

const globalState = inject('globalState') as GlobalState;


const props = defineProps<{
  modelValue: ComboBoxItem | null,
  evaluation: Evaluation,
}>();


const persons = ref<ComboBoxItem[]>([]);

const loadPersons = async() => {
  const response = await globalState.getClient().get('/rf/eval/users')
    .expect(200)
    .useRole(props.evaluation.role)
    .send();

  const data = await response.json();

  for (const { id, firstName, lastName } of data) {
    persons.value.push({
      id,
      name: firstName + ' ' + lastName,
    });
  }

  if (props.evaluation.isSelfEvaluation()) {
    if (data.length !== 1) {
      globalState.displayError(new Error(`Failed to load self evaluation user (${data.length})!`))
    }

    selectedPerson.value = {
      id: data[0].id,
      name: data[0].firstName + ' ' + data[0].lastName,
    }

    emit('update:modelValue', selectedPerson.value);
    emit('next');
  }
}

loadPersons();


const emit = defineEmits<{
  'next': [],
  'update:modelValue': [person: ComboBoxItem | null],
}>();


const selectedPerson = ref<ComboBoxItem | null>(props.modelValue);
const personErroMessage = ref<string | null>(null);

const next = () => {
  if (!props.modelValue) {
    personErroMessage.value = globalState.translate('evaluation.personselector.errormessage');
    return;
  }

  emit('next');

}

watch([selectedPerson], () => {
  personErroMessage.value = null;
  emit('update:modelValue', selectedPerson.value);
});


</script>

<template>
  <div class="h-full w-full">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-4xl sm:text-5xl font-thin mb-12 sm:mb-32 mx-5">{{ globalState.translate('evaluation.personselector.title') }}</div>
        <div v-if="persons.length">
          <div class="text-xl sm:text-2xl mb-8 sm:mb-16 mx-5">{{ globalState.translate('evaluation.personselector.chooseperson') }}</div>
          <ComboBox :error-message="personErroMessage" v-model="selectedPerson" :items="persons" :selected-item="persons[0]" :placeholder="globalState.translate('evaluation.personselector.placeholder')"></ComboBox>
          <!--div class="mx-5 mt-3">
            <div class="underline">{{ globalState.translate('evaluation.personselector.personmissing') }}</div>
          </div-->
          <div class="flex justify-end mt-6">
            <Button :text="globalState.translate('evaluation.personselector.next')" @click="next()" class="my-5" v-bind:is-primary="true"></Button>
          </div>
        </div>
        <div v-else>
          <div class="text-2xl mb-16 mx-5">{{ globalState.translate('evaluation.personselector.nopersons') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

