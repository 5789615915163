<script lang="ts" setup>
import { ref, defineEmits, reactive, watch, inject, computed } from 'vue';
import Calendar from './Calendar.vue';
import GlobalState from '../../lib/GlobalState.js';

const globalState = inject('globalState') as GlobalState;
const showMenu = ref(false);

const props = defineProps<{
  date: Date,
  errorMessage?: string | null,
}>();

const emit = defineEmits<{
  'timeSelected': [date: Date | null],
  'update:date': [date: Date],
}>();

class DateTimeSelector {
  public hours : string[] = Array.from(Array(24).keys()).map(i => i.toString().padStart(2, '0'));
  public minutes : string[] = ['00', '15', '30', '45'];

  public minute : number = 0;
  public hour : number = 0;

  public calendarDate;

  private lastDateSet : number = 0;

  constructor(date: Date) {
    this.calendarDate = new Date(date);
    this.hour = date.getHours();
    this.minute = 15 * (Math.floor(date.getMinutes() / 15));
  }


  public emitUpdate() {
    const date = new Date(this.calendarDate);
    date.setHours(this.hour, this.minute, 0, 0);

    if (this.lastDateSet === date.getTime()) return;
    emit('update:date', date);
    emit('timeSelected', date);
  }


  public setMinute(minute: number) {
    this.minute = minute;
    this.emitUpdate();
  }

  public setHour(hour: number) {
    this.hour = hour;
    this.emitUpdate();
  }

  public setDate(date: Date) {
    this.lastDateSet = date.getTime();
    this.calendarDate = new Date(date);
    this.hour = date.getHours();
    this.minute = 15 * (Math.floor(date.getMinutes() / 15));
  }
}

const selector = reactive(new DateTimeSelector(props.date));


watch(() => props.date, () => {
  selector.setDate(props.date);
});


watch(() => selector.calendarDate, () => {
  selector.emitUpdate();
});


const currentTime = computed(() => {
  const timeDate = new Date();
  timeDate.setHours(selector.hour, selector.minute, 0, 0);
  return globalState.formatDate(timeDate, 'time') + '';
});
</script>

<template>
  <div class="w-fit relative">
    <Calendar :range-mode="false" v-model:date="selector.calendarDate" :errorMessage="props.errorMessage"></Calendar>

    <div :class="showMenu ? ' rounded-b-lg time-button-shadow' : ' rounded-lg '" class="mt-8 bg-white border text-lg flex w-full cursor-pointer" >
      <div @click="showMenu = !showMenu" class="py-4 w-full flex justify-center select-none">{{ currentTime }}</div>
      
      <div ref="itemcontainer" v-if="showMenu" @blur="showMenu = false" class="time-shadow h-96 w-full -ml-[1px] select-none absolute -mt-96 bg-white border-t border-r border-l p-3 pt-4 rounded-t-lg overflow-hidden">
        
        <div ref="scrollcontainer" class="overflow-auto w-full  grid grid-cols-3">
          <div class="col-span-2 flex justify-center">Hours</div>
          <div class="flex justify-center">Minutes</div>

          <div class="col-span-2 grid grid-cols-4 gap-0 w-fit h-fit justify-center">
            <div v-for="h in selector.hours" 
              @click="selector.setHour(parseInt(h, 10))" 
              :class="(selector.hour === parseInt(h, 10) ? 'bg-medeval text-white border' : 'border-none') + ' ' + (parseInt(h, 10) > 19 || parseInt(h, 10) < 8 ? 'text-slate-300' : '')" 
              class="cursor-pointer w-12 h-12 border-gray-200 p-2 rounded flex items-center justify-center hover:bg-medeval hover:text-white text-base">
              {{ h }}
            </div>
          </div>
          <div class="flex justify-center">
            <div class="grid grid-cols-1 gap-0 w-fit h-fit mt-24">
              <div v-for="m in selector.minutes" 
                @click="selector.setMinute(parseInt(m, 10))" 
                :class="(selector.minute === parseInt(m, 10) ? 'bg-medeval text-white border' : 'border-none')" 
                class="cursor-pointer w-12 h-12 border-gray-200 p-2 rounded flex items-center justify-center hover:bg-medeval hover:text-white text-base">
                {{ m }}
              </div>
            </div>
          </div>

          <div class="col-span-3 flex justify-center mt-4">
            <div class="w-24 flex items-center justify-center border border-slate-300 hover:bg-medeval hover:text-white rounded-lg" @click="showMenu = false">OK</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .time-shadow {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    clip-path: inset(-10px -10px 0px -10px);
  }
  .time-button-shadow {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
  }
</style>