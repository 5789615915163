import { ref } from 'vue';
import Question, { QuestionOptions } from './Question.js';


export interface MandatoryAbsoluteScaleQuestionConfugrationItem {
    text: string;
    value: number;
    isStartItem: boolean;
    isEndItem: boolean;
    displayText: boolean;
}



export default class MandatoryAbsoluteScaleQuestion extends Question {

    
    public answer = ref<number | null>(null);


    constructor(options: QuestionOptions) {
        super(options);
    }


    public setAnswer(response: number, dontSave = false) : void {
        if (response <= 0 || response > 5) throw new Error('MandatoryScaleQuestion: Invalid response value, value must be between 1 and 5');
        this.answer.value = response;
        this.setAnswered();
        if (!dontSave) this.save();
    }

    public getAnswer() : number | null {
        return this.answer.value;
    }

    public loadAnswer(input: string) : void {
        this.setAnswer(parseInt(input, 10), true);
    }

    public getStringAnswer() : string | null {
        const answer = this.getAnswer();
        if (answer === null) return null;
        return answer.toString();
    }

    public getConfiguration() : MandatoryAbsoluteScaleQuestionConfugrationItem[] {
        return this.options.map((item, index) => {
            return {
                text: item.name,
                value: item.value,
                isStartItem: index === 0,
                isEndItem: index === 4,
                displayText: index === 0 || index === 2 || index === 4,
            };
        });
    }
}