<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import ComboBox, { ComboBoxItem } from '../ui/ComboBox.vue';
import OptionGroup, { Option } from '../ui/OptionGroup.vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid';
import InfoComponent from '../ui/InfoComponent.vue';

interface Topic {
  id: number,
  name: string,
  type: string,
  order: string;
}

interface AnalyticsGroup {
    id: number;
    name: string;
    identifier: string;
    order: number;
    topics: Topic[];
}


interface DimensionRank {
    value: number;
    name: string;
    order: number;
}


interface EvaluationReportComment {
    mentor: string;
    dimension: string;
    comment: string;
    date: string;
}


interface MentorAnswer {
    dimension: string;
    answer: string;
    date: string;
    mentor: string;
}


interface Mentoring {
    id: number;
    mentor: string;
    reviewerCount: number;
    evaluationCount: number;
    created: string;
    timespanStart: string;
    timespanEnd: string;
}

interface EvaluationDistribution {
    count: number;
    monthIndex: string;
}

interface Mentee {
  userId: number,
  ranking: string, 
  rankingOrder: number,
  rankingColor: string,
  name: string,
  answerCount: number,
  quality: string,
  open?: boolean,
  expanded?: boolean,
  dimensions: DimensionRank[],
  comments: EvaluationReportComment[],
  mentoringComments: MentorAnswer[],
  mentorings: Mentoring[],
  evaluations: EvaluationDistribution[],
}

const globalState = inject('globalState') as GlobalState;
const topics = ref<ComboBoxItem[]>([]);
const selectedTopic = ref<ComboBoxItem | null>(null);
const topicErrorMessage = ref<string | null>(null);
const mentees = ref<Mentee[]>([]);
const groups = ref<AnalyticsGroup[]>([]);
const selectedGroup = ref<AnalyticsGroup | null>(null);
const loading = ref<boolean>(true);
const timespans = ref<ComboBoxItem[]>([ {
  id: 2,
  name: globalState.translate('supervision.mentees.timespan.6-months'),
  identifier: '6-months'
}, {
  id: 4,
  name: globalState.translate('supervision.mentees.timespan.12-months'),
  identifier: '12-months'
}, {
  id: 5,
  name: globalState.translate('supervision.mentees.timespan.24-months'),
  identifier: '24-months'
}]);
const selectedTimespan = ref<ComboBoxItem>(timespans.value[0]);


watch(selectedGroup, () => {
  if (selectedGroup.value) {
    topics.value.splice(0, topics.value.length);

    for (const item of selectedGroup.value.topics) {
      topics.value.push({
        id: item.id,
        type: item.type,
        indent: item.type === 'questionnairePart' ? 1 : (item.type === 'question' ? 2 : 0),
        name: item.name,
      });
    }

    selectedTopic.value = topics.value[0];
  }
});

watch(selectedTimespan, () => {
  if (selectedTimespan.value) {
    loadData();
  }
});


const load = async() => {
  const response = await globalState.getClient().get(`/rf/supervision/mentee-report-topics`)
    .expect(200)
    .useRole('supervisor')
    .send();
  
  if (response.status() === 200) {
    const data = await response.json() as AnalyticsGroup[];

    data.sort((a, b) => a.order - b.order);

    for (const item of data) {
      groups.value.push(item);
    }

    selectedGroup.value = groups.value[0];
  }
}

load();





const loadData = async() => {
  if (!selectedTopic.value) {
    topicErrorMessage.value = globalState.translate('supervision.reports.topic-error');
    return;
  }

  loading.value = true;

  const response = await globalState.getClient().get(`/rf/supervision/mentee-report`)
    .setParameters({
      id: selectedTopic.value.id,
      type: selectedTopic.value.type,
      analyticsGroup: selectedGroup.value!.identifier,
      timespan: selectedTimespan.value!.identifier,
    })
    .expect(200)
    .useRole('supervisor')
    .send();

  if (response.status() === 200) {
    const data = await response.json() as Mentee[];

    mentees.value.splice(0, mentees.value.length);

    for (const item of data) {
      const quality = item.answerCount < 3 ? 'poor' : (item.answerCount <= 4 ? 'fair' : (item.answerCount <= 7 ? 'good' : 'excellent'));

      // sort comments by date descending
      item.comments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      item.mentoringComments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      item.mentorings.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

      mentees.value.push({
        userId: item.userId,
        ranking: item.ranking,
        rankingColor: item.rankingColor,
        rankingOrder: item.rankingOrder,
        name: item.name,
        answerCount: item.answerCount,
        dimensions: item.dimensions,
        comments: item.comments,
        mentoringComments: item.mentoringComments,
        quality: globalState.translate(`supervision.mentees.list.data-quality.${quality}`),
        mentorings: item.mentorings,
        evaluations: item.evaluations,
      });
    }

    mentees.value.sort((a, b) => {
      // sort by ranking and withing each ranking by name
      if (a.ranking === b.ranking) {
        return a.name.localeCompare(b.name);
      }

      return a.rankingOrder - b.rankingOrder;
    });

  
    loading.value = false;
  }
}


function interpolate(hexcolor1: string, hexcolor2: string, value: number): string {
    if (value < 0 || value > 1) {
        throw new Error('Value must be between 0 and 1');
    }

    // Convert hex colors to RGB
    const color1 = hexToRgb(hexcolor1);
    const color2 = hexToRgb(hexcolor2);

    if (!color1 || !color2) {
        throw new Error('Invalid hex color');
    }

    // Interpolate each color channel
    const r = Math.round(color1.r + (color2.r - color1.r) * value);
    const g = Math.round(color1.g + (color2.g - color1.g) * value);
    const b = Math.round(color1.b + (color2.b - color1.b) * value);

    // Convert the result back to hex
    return rgbToHex(r, g, b);
}

function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the leading #
    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
        // Convert shorthand hex to full hex
        hex = hex.split('').map(char => char + char).join('');
    }

    if (hex.length !== 6) {
        return null;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number): string {
    return '#' + [r, g, b].map(channel => {
        const hex = channel.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');
}


const getQualityColor = (answerCount: number) => {
  const value = answerCount / 10;
  return interpolate('#FFD595', '#04D700', value > 1 ? 1 : value);
}

const getDimensionColor = (index: number) => {
  return ['#8FFFC3', '#82FFE8', '#82D2FF', '#A4A3FF', '#D782FF'][index];
}

const downloadMentoringReport = async (id: number) => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  });

  await globalState.getClient().get(`/report/mentoring`).setParameters({
      mentoringId: id,
    })
    .useRole('supervisor')
    .expect(200)
    .download();
}

const downloadEvaluationgReport = async (id: number, menteeUserId: number) => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  });

  await globalState.getClient().get(`/report/evaluation/${id}`).setParameters({
      isMentorReport: true,
      menteeUserId,
    })
    .useRole('supervisor')
    .expect(200)
    .download();
}

watch(selectedTopic, () => {
  if (selectedTopic.value) {
    loadData();
  }
});


</script>

<template>
  <div class="max-w-6xl">
    
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('supervision.mentees.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('supervision.mentees.description') }}</p>
      </div>
    </div>

    <div class="flex items-start mt-6">
      <div class="w-1/2">
        <div class="mb-4">{{ globalState.translate('supervision.mentees.show-data-for') }}</div>
        <OptionGroup :options="groups" v-model="selectedGroup" class="flex flex-col gap-2" ></OptionGroup>
      </div>
      <div class="w-1/2">
        <div class="mb-4">{{ globalState.translate('supervision.mentees.timespan') }}</div>
        <OptionGroup :options="timespans" v-model="selectedTimespan" class="flex flex-col gap-2" ></OptionGroup>
      </div>
    </div>

    <div v-if="selectedGroup && selectedGroup.topics.length > 1" class="flex flex-col w-full mt-8">
      <div class="text-lg mb-2">{{ globalState.translate('supervision.mentees.filter-data-by') }}</div>
      <div class="flex-auto">
        <ComboBox :error-message="topicErrorMessage" v-model="selectedTopic" :items="topics" :placeholder="globalState.translate('supervision.mentees.topic-selector.placeholder')"></ComboBox>
      </div>
    </div>

    <div class="mt-12 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div v-if="loading" class="h-full text-xl flex items-center justify-center">
            {{ globalState.translate('supervision.mentees.loading') }}
          </div>
          <table v-else class="min-w-full divide-y divide-gray-300 max-w-full">
            <thead>
              <tr class="">
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('supervision.mentees.list.listtitle.name') }}</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0 ">
                  <div class="flex items-center">
                    <div class="inline-block">{{ selectedGroup?.name }}</div>
                    <InfoComponent :text="globalState.translate(`supervision.mentees.info-bubble.${selectedGroup?.identifier}.text`)" :title="globalState.translate(`supervision.mentees.info-bubble.${selectedGroup?.identifier}.title`)"></InfoComponent>
                  </div>
                  
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
                  <div class="flex items-center">
                    <div class="inline-block">{{ globalState.translate('supervision.mentees.list.listtitle.data-quality') }}</div>
                    <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.data-quality.text')" :title="globalState.translate('supervision.mentees.info-bubble.data-quality.title')"></InfoComponent>
                  </div>
                </th>
                <th scope="col" class="py-3.5 pl-3 pr-4 sm:pr-0"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="mentee of mentees" :key="mentee.userId" >
                <tr class=" cursor-pointer border-t border-slate-200" @click="mentee.expanded = !mentee.expanded" :class="{'bg-slate-50': mentee.expanded }" >
                  <td class="h-1">
                    <div :class="{'border-l font-semibold': mentee.expanded }" class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">{{ mentee.name }}</div></td>
                  <td class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <span :style="`background-color: ${mentee.rankingColor}DD`" class="text-white text-xs px-4 py-2 rounded-full font-bold">{{ mentee.ranking }}</span>
                  </td>
                  <td class="whitespace-nowrap px-3  text-base text-gray-500">
                    <span :style="`background-color: ${getQualityColor(mentee.answerCount)}DD`" class="text-white text-xs px-4 py-2 rounded-full font-bold">{{ mentee.quality }}</span>
                  </td>
                  <td class="">
                    <div :class="{'border-r': mentee.expanded }" class="whitespace-nowrap px-3 text-base h-full flex items-center justify-end">
                      <ChevronDownIcon v-if="!mentee.expanded" class="w-6 h-6 m-2 cursor-pointer"></ChevronDownIcon>
                      <ChevronUpIcon v-else class="w-6 h-6 m-2 cursor-pointer"></ChevronUpIcon>
                    </div>
                  </td>
                </tr>
                
                <tr v-if="mentee.expanded" class="max-h-screen">
                  <td colspan="4" class="">
                    <div class="bg-slate-100 px-4 pt-4 border-x text-sm text-slate-400 ">
                      {{ globalState.translate('supervision.mentees.data-explanation', [['count', mentee.answerCount.toString()], ['quality', mentee.quality]]) }}
                    </div>
                    <div class="grid grid-cols-3 py-4 px-4 bg-slate-100 border-b border-x rounded-b mb-4 gap-4">
                      <div class="">
                        <div class="font-semibold mb-2 flex">
                          {{ globalState.translate('supervision.mentees.evaluation-comments') }}
                          <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.eval-comments.text')" :title="globalState.translate('supervision.mentees.info-bubble.eval-comments.title')"></InfoComponent>
                        </div>
                        <div class="overflow-x-hidden overflow-y-auto flex flex-col gap-2 max-h-screen ">
                          <div v-if="mentee.comments.length" v-for="comment of mentee.comments" :key="comment.date" class=" rounded-lg bg-white p-2">
                            <div class="text-sm text-gray-500 flex items-center overflow-hidden">
                              <div class="text-slate-800 font-semibold mr-2 flex-grow overflow-hidden whitespace-nowrap text-ellipsis">{{ comment.dimension }}</div>
                              <div class="flex items-center"> 
                                <div class=" text-xs mr-2 text-slate-800 overflow-hidden whitespace-nowrap text-ellipsis">{{ comment.mentor }}</div>
                                <div class="text-xs overflow-hidden whitespace-nowrap text-ellipsis">- {{ globalState.formatDate(new Date(comment.date), 'date-mid') }}</div>
                              </div>
                            </div>
                            <div class="text-sm border-t max-h-32 overflow-hidden line-clamp-6 pt-2 text-gray-700">{{ comment.comment }}</div>
                          </div>
                          <div v-else>
                            {{ globalState.translate('supervision.mentees.no-comments') }}
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <div class="font-semibold mb-2 flex">
                          {{ globalState.translate('supervision.mentees.mentoring-comments') }}
                          <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.mentoring-comments.text')" :title="globalState.translate('supervision.mentees.info-bubble.mentoring-comments.title')"></InfoComponent>
                        </div>
                        <div class="overflow-x-hidden overflow-y-auto flex flex-col gap-2 max-h-screen">
                          <div v-if="mentee.mentoringComments.length" v-for="comment of mentee.mentoringComments" :key="comment.date" class=" rounded-lg bg-white p-2">
                            <div class="text-sm text-gray-500 flex overflow-hidden items-center">
                              <div class="text-slate-800 font-semibold mr-2 flex-grow overflow-hidden whitespace-nowrap text-ellipsis">{{ comment.dimension }}</div>
                              <div class="flex items-center"> 
                                <div class="text-xs mr-1 text-slate-800 whitespace-nowrap text-ellipsis">{{ comment.mentor }}</div>
                                <div class="text-xs whitespace-nowrap">{{ globalState.formatDate(new Date(comment.date), 'date-mid') }}</div>
                              </div>
                            </div>
                            <div class="text-sm border-t max-h-32 overflow-hidden line-clamp-6 pt-2 text-gray-700">{{ comment.answer }}</div>
                          </div>
                          <div v-else>
                            {{ globalState.translate('supervision.mentees.no-comments') }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="flex flex-col gap-1">
                        <div class="font-semibold mb-2 flex">
                          {{ globalState.translate('supervision.mentees.dimension-performance') }}
                          <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.performance.text')" :title="globalState.translate('supervision.mentees.info-bubble.performance.title')"></InfoComponent>
                        </div>
                        <div v-if="mentee.dimensions.length" v-for="(dimension, index) of mentee.dimensions" :key="dimension.name" class="border-2 border-slate-200 rounded" :style="`border-color:${getDimensionColor(index)}AA;`">
                          <div :style="`width:${dimension.value * 100}%; background-color:${getDimensionColor(index)}AA;`"  class="overflow-visible whitespace-nowrap px-2 p-1">{{ dimension.name }}</div>
                        </div>
                        <div v-else>
                          {{ globalState.translate('supervision.mentees.no-data') }}
                        </div>
                        
                        <div class="mt-6 font-semibold mb-2 flex">
                          {{ globalState.translate('supervision.mentees.distribution.title') }}
                          <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.eval-distribution.text')" :title="globalState.translate('supervision.mentees.info-bubble.eval-distribution.title')"></InfoComponent>
                        </div>
                        <div v-if="mentee.evaluations.some(e => e.count > 0)">
                          <div class="bg-white rounded flex flex-col items-start p-1 overflow-x-auto ">
                            <div class="flex items-end mt-4 gap-[1px] text-xs h-10 flex-row-reverse">
                              <div v-for="evaluation of mentee.evaluations.slice(mentee.evaluations.length > 13 ? 13 : 1)" :key="evaluation.monthIndex" :class="{
                                'h-0': evaluation.count === 0,
                                'h-3': evaluation.count === 1,
                                'h-6': evaluation.count === 2,
                                'h-9': evaluation.count >= 3,
                              }" class="w-7 bg-blue-300 flex items-end justify-center border-b"> {{ evaluation.count }}</div>
                            </div>
                            <div class="flex mt-4 text-xs gap-[1px] mb-4 flex-row-reverse">
                              <div v-for="evaluation of mentee.evaluations.slice(mentee.evaluations.length > 13 ? 13 : 1)" :key="evaluation.monthIndex" class="w-7 -rotate-90 flex justify-end mb-2 whitespace-nowrap">{{ globalState.getDateFormatter().getShortMonthName(new Date(evaluation.monthIndex)) }} {{ new Date(evaluation.monthIndex).getFullYear().toString().substring(2) }}</div>
                            </div>
                          </div>
                          <div v-if="mentee.evaluations.length > 13" class="bg-white rounded flex flex-col items-start p-1 overflow-x-auto mt-2 ">
                            <div class="flex items-end mt-4 gap-[1px] text-xs h-10 flex-row-reverse">
                              <div v-for="evaluation of mentee.evaluations.slice(1, 13)" :key="evaluation.monthIndex" :class="{
                                'h-0': evaluation.count === 0,
                                'h-3': evaluation.count === 1,
                                'h-6': evaluation.count === 2,
                                'h-9': evaluation.count >= 3,
                              }" class="w-7 bg-blue-300 flex items-end justify-center border-b"> {{ evaluation.count }}</div>
                            </div>
                            <div class="flex mt-4 text-xs gap-[1px] mb-4 flex-row-reverse">
                              <div v-for="evaluation of mentee.evaluations.slice(1, 13)" :key="evaluation.monthIndex" class="w-7 -rotate-90 flex justify-end mb-2 whitespace-nowrap">{{ globalState.getDateFormatter().getShortMonthName(new Date(evaluation.monthIndex)) }} {{ new Date(evaluation.monthIndex).getFullYear().toString().substring(2) }}</div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          {{ globalState.translate('supervision.mentees.no-data') }}
                        </div>
                       

                        <div class="font-semibold mb-2 mt-6 flex">
                          {{ globalState.translate('supervision.mentees.mentorings') }}
                          <InfoComponent :text="globalState.translate('supervision.mentees.info-bubble.mentorings.text')" :title="globalState.translate('supervision.mentees.info-bubble.mentorings.title')"></InfoComponent>
                        </div>
                        <div v-if="mentee.mentorings.length" v-for="mentoring of mentee.mentorings" :key="mentoring.id" class="flex flex-col mb-2 rounded bg-white p-2 text-sm">
                          <div class="flex border-b mb-2">
                            <div class="font-semibold">{{ globalState.translate('supervision.mentees.mentorings.by') }} {{ mentoring.mentor }}</div>
                            <div class="flex-grow flex justify-end">{{ globalState.formatDate(new Date(mentoring.created), 'date-mid') }}</div>
                          </div>
                          <div>{{ globalState.translate('supervision.mentees.mentorings.text', [['evalcount', mentoring.evaluationCount.toString()], ['personcount', mentoring.reviewerCount.toString()]]) }}</div>
                          <div>{{ globalState.translate('supervision.mentees.mentorings.range', [['start', globalState.formatDate(new Date(mentoring.timespanStart), 'date-mid')], ['end', globalState.formatDate(new Date(mentoring.timespanEnd), 'date-mid')]]) }} </div>
                          <div @click="downloadEvaluationgReport(mentoring.id, mentee.userId)" class="text-sm text-medeval underline cursor-pointer mt-1">{{ globalState.translate('supervision.mentees.mentorings.download-report') }}</div>
                          <div @click="downloadMentoringReport(mentoring.id)" class="text-sm text-medeval underline cursor-pointer">{{ globalState.translate('supervision.mentees.mentorings.download-mentoring-report') }}</div>
                        </div>
                        <div v-else class="text-base text-gray-500">{{ globalState.translate('supervision.mentees.no-reports') }}</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fadeHeight-enter-active, .fadeHeight-leave-active {
  transition: all 0.3s ease;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  max-height: 2300px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>