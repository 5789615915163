import { ref } from 'vue';
import Mentoring from './Mentoring.js';


export interface QuestionData {
    id: number;
    category: string;
    order: number;
}

export interface QuestionOptions extends QuestionData {
    mentoring: Mentoring,
    answer?: string,
}



export default class Question {

    public readonly id: number;
    public readonly category: string;
    public readonly order : number;

    private isQuestionAnswered : boolean = false;

    public answer = ref<string | null>(null);

    private readonly mentoring: Mentoring;

    constructor({
        id,
        category,
        order,
        mentoring,
    } : QuestionOptions) {
        this.id = id;
        this.category = category;
        this.order = order;
        this.mentoring = mentoring;
    }

    public setAnswered() : void {
        this.isQuestionAnswered = true;
    }

    public isAnswered() : boolean {
        return this.isQuestionAnswered;
    }

    public async save() : Promise<void> {
        await this.mentoring.saveQuestion(this);
    }

    public setAnswer(response: any) : void {
        throw new Error('Not implemented');
    }

    public getAnswer() : any {
        throw new Error('Not implemented');
    }
}