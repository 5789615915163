import ClientRequest from './ClientRequest.js';




export default class ClientResponse {

    private readonly request: ClientRequest;
    private readonly response: Response;

    constructor(request: ClientRequest, response: Response) {
        this.request = request;
        this.response = response;
    }

    public async json() : Promise<any> {
        const data = await this.response.json();
        return data.data;
    }

    public async text() : Promise<string> {
        return await this.response.text();
    }

    public async blob() : Promise<Blob> {
        return await this.response.blob();
    }
    
    public status() : number {
        return this.response.status;
    }

    public hasStatus(code: number) : boolean {
        return this.response.status === code;
    }

    public getHeader(key: string) : string {
        return this.response.headers.get(key) || '';
    }

    hasHeader(key: string) : boolean {
        return this.response.headers.has(key);
    }
}