import Client, { QueryParameters, RequestConfig } from './Client.js';
import ClientResponse from './ClientResponse.js';
import GlobalState from '../GlobalState.js';


export interface Headers {
    [key: string]: string;
}

export type ClientRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export default class ClientRequest {

    private readonly globalState: GlobalState;
    private body: any;
    private readonly headers: { [key: string]: string } = {};
    private readonly method: ClientRequestMethod = 'GET';
    private readonly parameters: QueryParameters = {};
    private path: string = '';
    private readonly hasBody : boolean;
    private retryOn401: boolean = true;

    private expectedStatusCodes: number[] = [];

    constructor({
        globalState,
        method,
        path
    } : {
        globalState: GlobalState,
        method?: ClientRequestMethod,
        path?: string,
    }) {
        this.globalState = globalState;
        this.method = method || 'GET';
        this.path = path || '';
        this.hasBody = ['POST', 'PUT', 'PATCH'].includes(this.method);
    }


    public useRole(role: string) : ClientRequest {
        /*if (!this.globalState.getAuthentication().hasRole(role)) {
            const err = new Error(`Cannot use role ${role}: the user does not have that role!`);
            this.globalState.displayError(err);
            throw err;
        }*/

        this.setHeader('X-RF-Role', role);
        return this;
    }

    public setHeader(key: string, value: string) : ClientRequest {
        this.headers[key] = value;
        return this;
    }

    public setHeaders(headers: Headers) : ClientRequest {
        for (const [key, value] of Object.entries(headers)) {
            this.setHeader(key, value);
        }

        return this;
    }

    public setJSONBody(body: any) : ClientRequest {
        this.setHeader('Content-Type', 'application/json');
        return this.setBody(JSON.stringify(body));
    }

    public noRetry() : ClientRequest {
        this.retryOn401 = false;
        return this;
    }

    public getNoRetry() : boolean {
        return this.retryOn401;
    }


    public setBody(body: any) : ClientRequest {
        if (body === undefined) return this;
        if (!this.hasBody) throw new Error(`Cannot set body for ${this.method} request!`);
        this.body = body;
        return this;
    }

    public setPath(path: string) : ClientRequest {
        this.path = path;
        return this;
    }

    public setParameter(key: string, value: any) : ClientRequest {
        this.parameters[key] = value;
        return this;
    }

    public setParameters(parameters: QueryParameters) : ClientRequest {
        for (const [key, value] of Object.entries(parameters)) {
            this.setParameter(key, value);
        }

        return this;
    }


    public expect(...statusCodes: number[]) : ClientRequest {
        this.expectedStatusCodes = statusCodes;
        return this;
    }


    public async download(filename?: string) {
        const response = await this.send();
        const blob = await response.blob();

        if (!blob) throw new Error('Missing data for download');

        if (!filename) {
            const header = response.getHeader('Content-Disposition');
            if (!header) throw new Error('Missing content disposition header');
            
            const parts = header.split(';');
            filename = parts[1].split('=')[1].replaceAll("\"", "");
        }

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }


    public async send(body?: any) : Promise<ClientResponse> {
        this.setBody(body);

        const config : RequestConfig = {
            path: this.path,
            method: this.method,
            parameters: this.parameters,
            headers: this.headers,
            body: this.body,
            no401Retry: !this.retryOn401,
        };

        const response = await this.globalState.getClient().request(config);
        
        if (this.expectedStatusCodes.length > 0 && !this.expectedStatusCodes.includes(response.status)) {
            const reponseText = await response.text();
            this.globalState.displayRequestError(config, response.status, this.expectedStatusCodes, reponseText);
            throw new Error(`[${config.method}] request to ${config.path} returned the status code ${response.status}, expected one of ${this.expectedStatusCodes.join(', ')}${reponseText ? `: ${reponseText.substring(0, 200)}` : ''}!`);
        }

        return new ClientResponse(this, response);
    }
}