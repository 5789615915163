<script setup lang="ts">
import Button from '../../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import Evaluation from '../src/Evaluation.js';
import GlobalState from '../../../lib/GlobalState.js';
import Calendar from '../../ui/Calendar.vue';

const globalState = inject('globalState') as GlobalState;

interface DateRange {
  from: Date;
  to: Date;
}

const { evaluation } = defineProps<{
  evaluation: Evaluation
}>();

const emit = defineEmits<{
  'back': [],
  'next': [],
}>();


const dateValue = ref<string>('');
const errorMessage = ref<string | undefined>();
const rangeSet = ref<boolean>(false);
const fromDate = ref<Date | undefined>();
const toDate = ref<Date | undefined>();

const back = () => emit('back');
const next = async() => {
  if (!rangeSet.value || !fromDate.value || !toDate.value) {
    if (!errorMessage.value) errorMessage.value = globalState.translate('evaluation.dateselector.errormessage.not-set');
    return;
  }
  
  await evaluation.setDates(fromDate.value, toDate.value);

  emit('next');
}

const displayDate = (from: Date, to: Date) => {
  dateValue.value = '';

  const endOFWeek = new Date();
  if (endOFWeek.getDay() !== 0) {
    endOFWeek.setDate(endOFWeek.getDate() + (7 - endOFWeek.getDay()));
  }

  if (to.getTime() > endOFWeek.getTime()) {
    rangeSet.value = false;
    errorMessage.value = globalState.translate('evaluation.dateselector.errormessage.future');
    return;
  }

  const diffInDays = Math.round((to.getTime() - from.getTime()) / (1000 * 3600 * 24));
  if (diffInDays < 2) {
    rangeSet.value = false;
    errorMessage.value = globalState.translate('evaluation.dateselector.errormessage.too-short');
    return;
  }

  errorMessage.value = undefined;
  dateValue.value = globalState.formatDate(from) + ' - ' + globalState.formatDate(to);
}

const setThisWeek = () => {
  fromDate.value = globalState.getDateFormatter().getPrevious('mon', 2);
  toDate.value = globalState.getDateFormatter().addDays(fromDate.value, 4);
  rangeSet.value = true;
  displayDate(fromDate.value, toDate.value);
  rangeSet.value = true;
}


const setLastWeek = () => {
  fromDate.value = globalState.getDateFormatter().getPrevious('mon', 9);
  toDate.value = globalState.getDateFormatter().addDays(fromDate.value, 4);
  
  displayDate(fromDate.value, toDate.value);
  rangeSet.value = true;
}

const setLastTwoWeeks = () => {
  fromDate.value = globalState.getDateFormatter().getPrevious('mon', 16);
  toDate.value = globalState.getDateFormatter().addDays(fromDate.value, 11);
  
  displayDate(fromDate.value, toDate.value);
  rangeSet.value = true;
}

const selectRange = (start: Date, end: Date) => {
  rangeSet.value = true;
  displayDate(start, end);
}

if (evaluation && evaluation.hasDatesSet()) {
  fromDate.value = evaluation.getEvaluationStartDate();
  toDate.value = evaluation.getEvaluationEndDate();
  rangeSet.value = true;
  displayDate(evaluation.getEvaluationStartDate(), evaluation.getEvaluationEndDate());
} else {
  setLastWeek();
}



if (evaluation.isSelfEvaluation()) {
  setLastTwoWeeks();
  next();
}
</script>

<template>
  <div class="h-full w-full">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-xl sm:text-2xl font-4xl mb-8 sm:mb-16 mx-5">
          {{ globalState.translate('evaluation.dateselector.text') }} <span class="text-medeval">{{ evaluation.getEvaluationUserName() }}</span> 
        </div>
        <div class="flex flex-col items-center justify-center">
          <Calendar @range-selected="selectRange" v-model:range-start="fromDate" v-model:range-end="toDate" :range-mode="true" :error-message="errorMessage" />

          <div class="mx-5 mt-6 flex">
            <div @click="setThisWeek" class="underline cursor-pointer">{{ globalState.translate('evaluation.dateselector.thisWeek') }}</div>
            <span class="px-3">·</span>
            <div @click="setLastWeek" class="underline cursor-pointer">{{ globalState.translate('evaluation.dateselector.previousWeek') }}</div>
            <span v-if="evaluation.hasDatesSet()" class="px-3">·</span>
            <!--div v-if="evaluation.hasStartedEvaluation() && parseInt(daysSinceLastEval, 10) > 3" @click="setSinceLastEval" class="underline cursor-pointer">{{ globalState.translate('evaluation.dateselector.sinceLastEval', [['days', daysSinceLastEval]]) }}</div-->
          </div>
          <div class="mt-8 text-lg">
            {{ dateValue }}
          </div>
        </div>
       

        <div class="flex mt-8">
          <Button :text="globalState.translate('evaluation.dateselector.back')" @click="back" class="my-5"></Button>
          <div class="w-full flex justify-end">
            <Button :text="globalState.translate('evaluation.dateselector.next')" @click="next" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

