<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter, useRoute, matchedRouteKey } from 'vue-router';
import Button from '../ui/Button.vue';
import DateTimeSelector from '../ui/DateTimeSelector.vue';
import MentoringManager, { Mentoring } from './src/MentoringManager.js';
import { ComputerDesktopIcon } from '@heroicons/vue/24/outline';

const route = useRoute();
const router = useRouter();

const globalState = inject('globalState') as GlobalState;
const mentoringManager = new MentoringManager(globalState);
const errorMessage = ref<string | null>(null);
const mentoringDate = ref<Date>(new Date());
const mentoring = ref<Mentoring | null>(null);
const loading = ref<boolean>(true);

(async() => {
  const mentoringId = parseInt(route.params.id as string);
  mentoring.value = await mentoringManager.getMentoring(mentoringId);
  mentoringDate.value = new Date(mentoring.value!.date);
  loading.value = false;
})();


const cancelSchedule = async() => {
  await mentoringManager.cancelSchedule(mentoring.value!.id);
  globalState.showNotification({
    title: globalState.translate('mentorings.edit.cancelled.title'),
    text: globalState.translate('mentorings.edit.cancelled.text'),
    type: 'success'
  });
  router.push('/app/mentorings');
}

const updateDate = async() => {
  if (!mentoringDate.value) return;
  errorMessage.value = null;

  if (mentoringDate.value < new Date()) {
    errorMessage.value = globalState.translate('mentorings.edit.date-in-past');
    return;
  }

  const newMentoring = await mentoringManager.updateSchedule(mentoring.value!.id, mentoringDate.value) as Mentoring;
  mentoring.value = newMentoring;

  router.replace(`/app/mentorings/${mentoring.value.id}` );

  globalState.showNotification({
    title: globalState.translate('mentorings.edit.date-updated.title'),
    text: globalState.translate('mentorings.edit.date-updated.text'),
    type: 'success'
  });
}



const displayDate = computed(() => {
  return globalState.formatDate(mentoringDate.value, 'datetime-mid');
})


</script>
<template>
  <div class="">
    <div class="flex items-center ml-5">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('mentorings.edit.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('mentorings.edit.description') }}</p>
      </div>
    </div>
    <div v-if="!loading" class="mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 max-w-5xl relative">
      <div class="ml-5 grid grid-cols-2 gap-6 h-fit">

        <div class="">{{ globalState.translate('mentorings.edit.mentor') }}</div>
        <div class="font-semibold">{{ mentoring!.mentor.name }}</div>

        <div class="">{{ globalState.translate('mentorings.edit.mentee') }}</div>
        <div class="font-semibold">{{ mentoring!.mentee.name }}</div>

        <div class="">{{ globalState.translate('mentorings.edit.original-date') }}</div>
        <div class="font-semibold">{{ globalState.formatDate(mentoring!.date, 'datetime-mid') }}</div>

        <div class="">{{ globalState.translate('mentorings.edit.new-date') }}</div>
        <div class="font-semibold">{{ displayDate }}</div>
      </div>

      <div class="ml-5 flex items-end flex-col">
        <div>
          <div class="mb-8">{{ globalState.translate('mentorings.edit.datetime') }}</div>
          <DateTimeSelector v-model:date="mentoringDate" :errorMessage="errorMessage" class=""></DateTimeSelector>
        </div>
      </div>

      <div class="md:col-span-2 mt-6 ml-5 flex gap-6 justify-end">
        <Button :disabled="false" @click="cancelSchedule" :is-warning="true" :text="globalState.translate('mentorings.edit.cancel')" />
        <Button :disabled="false" @click="updateDate" :is-primary="true" :text="globalState.translate('mentorings.edit.save')"  />
      </div>
    </div>
  </div>
</template>