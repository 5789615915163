import { createRouter, createWebHistory } from 'vue-router';
import RootComponent from '../components/Root.vue';
import Evaluation from '../components/eval/Evaluation.vue';
import AppLayout from '../components/AppLayout.vue';

import Mentoring from '../components/mentoring/Mentoring.vue';
import Mentorings from '../components/mentoring/Mentorings.vue';
import MentoringsEdit from '../components/mentoring/Mentorings.edit.vue';
import MentoringCreate from '../components/mentoring/Mentoring.create.vue';

import SignOut from '../components/auth/SignOut.vue';
import EmailLogin from '../components/auth/EmailLogin.vue';
import Validate from '../components/auth/Validate.vue';
import Dashboard from '../components/generic/Dashboard.vue';

import Users from '../components/admin/Users.vue';
import EditUser from '../components/admin/Users.edit.vue';

import Profile from '../components/profile/Profile.vue';
import Imprint from '../components/about/Imprint.vue';

import MenteeReport from '../components/supervision/MenteeReport.vue';
import EvaluationComplianceReport from '../components/supervision/EvaluationComplianceReport.vue';
import OrganizationNotifications from '../components/organization/Notifications.vue';


const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        { path: '/', component: RootComponent, redirect: '/app/dashboard', children:[
            { path: 'app', component: AppLayout, children:[
                { path: 'evaluation', component: Evaluation },
                { path: 'evaluation/self', component: Evaluation },
                { path: 'mentoring', component: Mentoring },
                { path: 'mentorings', component: Mentorings },
                { path: 'mentorings/:id', component: MentoringsEdit },
                { path: 'mentoring/create', component: MentoringCreate },
                { path: 'loading', component: Mentoring },
                { path: 'dashboard', component: Dashboard }
            ]}, {
                path: 'auth', children: [
                    { path: 'email-login', component: EmailLogin },
                    { path: 'validate', component: Validate },
                    { path: 'sign-out', component: SignOut }
                ]
            }, {
                path: 'admin', component: AppLayout, children: [
                    { path: 'users', component: Users },
                    { path: 'user/:id', component: EditUser}
                ]
            }, {
                path: 'user', component: AppLayout, children: [
                    { path: 'profile', component: Profile }
                ]
            }, {
                path: 'about', component: AppLayout, children: [
                    { path: '', component: Imprint }
                ]
            }, {
                path: 'supervision', component: AppLayout, children: [
                    { path: 'reports', component: MenteeReport },
                    { path: 'evaluation-compliance-report', component: EvaluationComplianceReport }
                ]
            }, {
                path: 'organization', component: AppLayout, children: [
                    { path: 'notifications', component: OrganizationNotifications }
                ]
            }
        ]},
    ]
});



export default router; 
