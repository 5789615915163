import { ref } from 'vue';
import Client, { RequestConfig } from './base/Client.js';
import Translations, { TranslationReplacementValue } from "./base/Translations.js";
import Authentication from './base/Authentication.js';
import { UserManager } from './user/index.js';
import DateFormatter, { DateFormat } from './base/DateFormatter.js';



export interface Notification {
    text: string;
    title: string;
    type: 'info' | 'error' | 'warning' | 'success';
}

export interface DisplayedNotification extends Notification {
    id: number;
}

export default class GlobalState {

    private readonly translations;
    private readonly authentication : Authentication;
    private readonly userManager: UserManager;
    private readonly client: Client;
    private readonly dateFormatter: DateFormatter;

    public notifications = ref<DisplayedNotification[]>([]);
    private notificationId = 1;

    private redirectUrl?: string;
    private locale: string =  'en---';

    public error = ref<Error>(new Error());


    constructor() {
        this.client = new Client(this);
        this.authentication = new Authentication(this);
        this.translations = new Translations(this);
        this.userManager = new UserManager(this);
        this.dateFormatter = new DateFormatter(this);
    }


    public async load() : Promise<void> {
        await this.translations.load();
    }

    public async setLocale(locale: string) : Promise<void> {
        if (this.locale !== locale) {
            this.locale = locale;
            await this.translations.reload();
        }
    }

    public showNotification(notification: Notification, duration = 4000) : number {
        const id = this.notificationId++;
        this.notifications.value.push({ ...notification, id });

        setTimeout(() => {
            this.removeNotification(id);
        }, duration);

        return id;
    }

    public clearNotifications() : void {
        this.notifications.value = [];
    }

    public removeNotification(id: number) : void {
        this.notifications.value = this.notifications.value.filter(n => n.id !== id);
    }




    getDateFormatter() : DateFormatter {
        return this.dateFormatter;
    }

    getUserManager() : UserManager {
        return this.userManager;
    }

    getAuthentication() : Authentication {
        return this.authentication;
    }

    getTranslations() : Translations {
        return this.translations;
    }

    getClient() : Client {
        return this.client;
    }


    formatDate(date: Date, format : DateFormat = 'date'): string {
        return this.dateFormatter.format(date, format);
    }

    translate(key: string, values?: TranslationReplacementValue[]) : string {
        return this.translations.translate(key, values);
    }


    getLocale() : string {
        return this.locale;
    }


    setRedirectUrl(url: string) : void {
        this.redirectUrl = url;
    }

    getRedirectUrl() : string | void {
        return this.redirectUrl;
    }


    displayError(err: Error) {
        this.error.value = err;
        console.error(err);
    }

    displayRequestError(requestConfig: RequestConfig, status: number, expected: number[], text?: string) {
        console.log(this.error);
        this.error.value = new Error(`[${requestConfig.method}] request to ${requestConfig.path} returned the status code ${status}, expected one of ${expected.join(', ')}${text ? `: ${text.substring(0, 200)}` : ''}!`);
        console.error(this.error.value);
    }

    clearError() {
        this.error.value = new Error();
    }


    public saveKey(key: string, value: string) : void {
        localStorage.setItem(key, value);
    }

    public loadKey(key: string) : string | null {
        return localStorage.getItem(key);
    }

    public removeKey(key: string) : void {
        localStorage.removeItem(key);
    }
}