<script lang="ts" setup>
const props = defineProps<{
  text: string
  isPrimary?: boolean
  isWarning?: boolean
  disabled?: boolean
}>();

const emit = defineEmits<{
  'click': [],
}>();

const click = () => {
  if (!props.disabled) {
    emit('click');
  }
}


</script>

<template>
  <div class="w-fit">
    <div @click="click" 
      :class="{
        'border-slate-300 text-slate-400': disabled,
        'border-slate-300 border' : !disabled && !isPrimary && !isWarning,
        'border-medeval border-2': !disabled && isPrimary,
        'border-red-300 border-2 text-red-600 ': !disabled && isWarning,
      }"
      class=" select-none cursor-pointer font-bold h-12 min-w-[160px] px-6 hover:bg-cyan-50 hover:border-medeval bg-white rounded-full z-2 flex items-center justify-center">
      {{ text }}
    </div>
  </div>
</template>
