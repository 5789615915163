<script setup lang="ts">
import Mentoring from './src/Mentoring.js';
import { ComboBoxItem } from '../ui/ComboBox.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import SelectPerson, { SelectedReport } from './views/SelectPerson.vue';
import ReportDonload from './views/ReportDownload.vue';
import Questionnaire from './views/Questionnaire.vue';
import DateSelector from './views/DateSelector.vue';
import ResumeMentoring from './views/ResumeMentoring.vue';
import NoEvaluations from './views/NoEvaluations.vue';
import End from './views/End.vue';
import { useRouter } from 'vue-router';


type Stage = 'select-person' | 'resume' | 'no-evaluations' | 'download' | 'questionnaire' | 'date-selector' | 'end';


const createNewMentoring = () : Mentoring => {
  return new Mentoring({
    mentorUserId : globalState.getAuthentication().getUserId(),
    globalState,
  });
}

const router = useRouter();
const globalState = inject('globalState') as GlobalState;
const stage = ref<Stage>('select-person');
const selectedPerson = ref<ComboBoxItem | null>(null);
const stageHistory = ref<Stage[]>([]);
let mentoring : Mentoring = createNewMentoring();



watch(() => stage.value, (value) => {
  if (stage.value === 'select-person') {
    mentoring = createNewMentoring();
  }
});



const personSelected = async() => {
  stageHistory.value.push(stage.value);

  if (mentoring.hasPendingMentorings()) {
    stage.value = 'resume';
  } else if(!mentoring.hasPendingEvaluations()) {
    stage.value = 'no-evaluations';
  } else {
    await mentoring.create();
    stage.value = 'download';
  }
}


const resumeMentoring = () => {
  stage.value = 'download';
}



const downloadDone = () => {
  stageHistory.value.push(stage.value);
  stage.value = 'questionnaire';
}


const questionnaireDone = () => {
  stageHistory.value.push(stage.value);
  stage.value = 'date-selector';
}


const dateSelected = async () => {
  await mentoring?.finish();
  stageHistory.value.push(stage.value);
  stage.value = 'end';
}


const startNewMentoring = () => {
  stageHistory.value = [];
  stage.value = 'select-person';
}

const back = () => {
  stage.value = stageHistory.value.pop()!;
}


</script>

<template>
  <div class="w-full flex-1 flex flex-col">
    <div class="h-full flex-grow flex items-center justify-center">
      <SelectPerson v-if="stage === 'select-person'" :mentoring="mentoring" @next="personSelected"></SelectPerson>
      <NoEvaluations v-if="stage === 'no-evaluations'" @back="back"></NoEvaluations>
      <ResumeMentoring v-if="stage === 'resume'" :mentoring="mentoring" @back="back" @next="resumeMentoring"></ResumeMentoring>
      <ReportDonload v-if="stage === 'download'" :mentoring="mentoring" @back="back" @next="downloadDone"></ReportDonload>
      <Questionnaire v-if="stage === 'questionnaire'" :mentoring="mentoring!" @back="back" @next="questionnaireDone"></Questionnaire>
      <DateSelector v-if="stage === 'date-selector'" :mentoring="mentoring!" @back="back" @next="dateSelected"></DateSelector>
      <End v-if="stage === 'end'" :mentoring="mentoring!" @back="back" @new-mentoring="startNewMentoring"></End>
    </div>
  </div>
</template>

