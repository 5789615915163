import GlobalState from '../GlobalState.js';
import { Locale, UserManager } from './UserManager.js';





export default class UserProfile {


    globalState: GlobalState;

    public locales: Locale[] = [];
    public id: number = 0;
    
    public _firstName: string = '';
    public _lastName: string = '';
    public locale: Locale = { id: 0, name: '' };
    public primaryEmail?: string;
    public stateRegistryId?: string;
    public created?: Date;


    public firstnameError: string = '';
    public lastnameError: string = '';
    public localeError: string = '';


    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public async save() {
        const isValid = await this.validate();

        if (!isValid) {
            throw new Error('User is not valid');
        };

        const payload = {
            firstName: this._firstName,
            lastName: this._lastName,
            localeId: this.locale.id,
        };

        await this.globalState.getClient().update('/rf/user/profile')
            .expect(200)
            .useRole('user')
            .setJSONBody(payload).send();

        await this.globalState.getAuthentication().refreshJWT();
        await this.globalState.getTranslations().reload();

        window.location.reload();
    }

    private validate() : boolean{
        let status = true;

        if (!this.firstName) this.firstnameError = this.globalState.translate('user.profile.error.frist-name'), status = false;
        if (!this.lastName) this.lastnameError = this.globalState.translate('user.profile.error.last-name'), status = false;
        if (!this.locale || !this.locale.id) this.localeError = this.globalState.translate('user.profile.error.locale'), status = false;

        return status;
    }

    public async load() {
        this.locales = await this.loadLocales();

        const response = await this.globalState.getClient().get('/rf/user/profile')
            .useRole('user')
            .expect(200)
            .send();

        const data = await response.json();
        
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        const userLocale = this.locales.find(l => l.id === data.localeId);
        if (userLocale) this.locale = userLocale;
        this.primaryEmail = data.primaryEmail;
        this.stateRegistryId = data.stateRegistryId;
        this.created = new Date(data.created);
    }


    private async loadLocales() : Promise<Locale[]> {
        const response = await this.globalState.getClient().get('/rf/locales/organization')
            .useRole('user')
            .expect(200)
            .send();

        return await response.json();
    }


    public set firstName(firstName: string) {
        this._firstName = firstName;
        if (firstName) this.firstnameError = '';
    }

    public get firstName() : string {
        return this._firstName;
    }

    public set lastName(lastName: string) {
        this._lastName = lastName;
        if (lastName) this.lastnameError = '';
    }

    public get lastName() : string {
        return this._lastName;
    }
}