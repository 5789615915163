<script lang="ts" setup>
import { ref, watch } from 'vue';

const { name, modelValue } = defineProps<{
  name: string
  modelValue: boolean
}>();

const emit = defineEmits<{
  'click': [],
  'update:modelValue': [value: boolean],
}>();


const click = () => {
  emit('click');
  emit('update:modelValue', !modelValue);
}

</script>

<template>
  <div class="select-none w-fit flex items-center cursor-pointer" @click="click">
    <div :class="modelValue ? 'border-medeval bg-cyan-50' : 'border-slate-300'" class="cursor-pointer h-8 w-8 border-2 bg-white rounded-full flex items-center justify-center">
      <div :class="modelValue ? 'border-medeval bg-medeval' : 'border-slate-300 bg-white'" class="pointer-events-none h-6 w-6 border-2 rounded-full  flex items-center justify-center" >
        <div :class="modelValue ? 'bg-white' : ''" class="pointer-events-none h-1 w-1 rounded-full"></div>
      </div>
    </div>

    <div class="ml-2 flex items-center">{{ name }}</div>
  </div>
</template>
