<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import QuestionnaireOptionButton from '../../ui/QuestionnaireOptionButton.vue';
import Button from '../../ui/Button.vue';
import TextArea from '../../ui/TextArea.vue';
import Evaluation from '../src/Evaluation.js';
import Question from '../src/Question.js';
import ScaleQuestion from '../src/ScaleQuestion';
import MandatoryScaleQuestion from '../src/MandatoryScaleQuestion';
import MandatoryAbsoluteScaleQuestion from '../src/MandatoryAbsoluteScaleQuestion';
import YesNoScaleQuestion from '../src/YesNoScaleQuestion'
import YesNoQuestion from '../src/YesNoQuestion';
import TextQuestion from '../src/TextQuestion';
import GlobalState from '../../../lib/GlobalState.js';

const globalState = inject('globalState') as GlobalState;


const { evaluation } = defineProps<{
  evaluation: Evaluation
}>();

const emit = defineEmits<{
  'back': [],
  'next': [],
}>();


const questions : Question[] = evaluation.getQuestions();
const currentIndex = ref<number>(0);
const container = ref<HTMLDivElement | null>(null);
const errorMessage = ref<string | null>(null);


const select = () => {
  if (currentIndex.value === questions.length - 1) {
    emit('next');
    return;
  }
  
  errorMessage.value = null;
  goForward();
}

const getCurrentElement = () : HTMLDivElement => {
  if (!container.value) throw new Error('Container not set');
  return container.value!.children[currentIndex.value] as HTMLDivElement;
}

const getPreviousElement = () : HTMLDivElement | null => {
  if (!container.value) throw new Error('Container not set');
  if (currentIndex.value === 0) return null;
  return container.value!.children[currentIndex.value - 1] as HTMLDivElement;
}

const getNextElement = () : HTMLDivElement | null => {
  if (!container.value) throw new Error('Container not set');
  if (currentIndex.value === container.value!.children.length - 1) return null;
  return container.value!.children[currentIndex.value + 1] as HTMLDivElement;
}

const goForward = () => {
  const currentElement = getCurrentElement();
  const nextElement = getNextElement();

  currentElement.classList.add('remove-to-right');
  currentElement.classList.add('nopointer');

  nextElement?.classList.add( 'add-from-left');
  nextElement?.classList.remove('hide-element');

  const listener = () => {
    currentElement.removeEventListener("animationend", listener);

    currentElement.classList.remove('remove-to-right');
    currentElement.classList.add('hide-element');
    nextElement?.classList.remove('add-from-left');
    nextElement?.classList.remove('nopointer');
    currentIndex.value++;
  }

  currentElement.addEventListener("animationend", listener);
}

const goBack = () => {
  const currentElement = getCurrentElement();
  const previousElement = getPreviousElement();

  currentElement.classList.add('remove-to-left');
  currentElement.classList.add('nopointer');

  previousElement?.classList.add( 'add-from-right');
  previousElement?.classList.remove('hide-element');

  const listener = () => {
    currentElement.removeEventListener("animationend", listener);

    currentElement.classList.remove('remove-to-left');
    currentElement.classList.add('hide-element');
    previousElement?.classList.remove('add-from-right');
    previousElement?.classList.remove('nopointer');
    currentIndex.value--;
  }

  currentElement.addEventListener("animationend", listener);
}

const next = (question: Question) => {
  if (currentIndex.value === questions.length - 1) {
    emit('next');
    return;
  }

  if (question instanceof MandatoryScaleQuestion && question.answer.value === null ||
      question instanceof MandatoryAbsoluteScaleQuestion && question.answer.value === null) {
    // require an answer
    errorMessage.value = globalState.translate('evaluation.questionnaire.errorMessage');
    return;
  } else if (question instanceof ScaleQuestion && question.answer.value === null ||
      question instanceof YesNoQuestion && question.answer.value === null ||
      question instanceof YesNoScaleQuestion && question.answer.value === null) {
    // set default answer
    question.setAnswer(0);
  } else if (question instanceof TextQuestion) {
    // set answer
    question.setAnswer(question.answer.value);
  }

  errorMessage.value = null;

  goForward();
}

const back = () => {
  if (currentIndex.value === 0) {
    emit('back');
    return;
  }

  errorMessage.value = null;

  goBack();
}

</script>

<template>
  <div class="h-full">
    <div ref="container" class="h-full flex justify-center items-center overflow-hidden">
      <div v-for="(question, index) of questions" :key="index" 
        :class="currentIndex === index - 1 ? 'opacity-0 hide-element nopointer' : (currentIndex === index + 1 ? 'opacity-0 hide-element nopointer' : (currentIndex === index ? '' : 'opacity-0 hidden'))" 
        class="absolute top-24 sm:top-auto flex justify-center items-center w-screen">

        <div class="flex flex-col  h-fit sm:mb-24 items-center">
          <div class="hidden sm:block text-lg sm:text-2xl font-4xl mb-4 sm:mb-20 opacity-80">
            <span class="text-medeval mr-2">{{ evaluation.getEvaluationUserName() }}</span> 
          </div>

          <div class="text-lg sm:text-2xl font-4xl mb-4 sm:mb-20 px-8 flex items-center justify-center w-full">
            <div class="text-center sm:w-2/3">
              <div class="ml-2 text-xl opacity-50 mb-4">
                <span class="ml-2">{{ question.category }}</span> 
              </div>
              {{ question.text }}
            </div>
          </div>

          <div v-if="(question instanceof ScaleQuestion) || (question instanceof MandatoryScaleQuestion) || (question instanceof MandatoryAbsoluteScaleQuestion) || (question instanceof YesNoQuestion) || (question instanceof YesNoScaleQuestion)" class="flex flex-col sm:flex-row items-center sm:justify-center w-full border-y-2 sm:border-none">
            <QuestionnaireOptionButton v-for="option of question.getConfiguration()" 
              :text="option.text" 
              @click="select()" 
              v-bind:displayText="option.displayText" 
              v-bind:firstItem="option.isStartItem" 
              v-bind:lastItem="option.isEndItem" 
              :question="question" 
              v-bind:answer="option.value">
            </QuestionnaireOptionButton>
          </div>

          <div v-else-if="(question instanceof TextQuestion)" class="flex items-center w-full">
            <TextArea v-for="option of question.getConfiguration()" class="w-full sm:w-[740px]" v-model="question.answer.value" :placeholder="option.text"></TextArea>
          </div>

          <div v-if="errorMessage" class="flex items-center justify-center text-red-500">
            {{ errorMessage }}
          </div>

          <div class="flex w-full px-4  sm:mt-8">
            <Button v-if="evaluation.isSelfEvaluation() ? currentIndex > 0 : true" :text="globalState.translate('evaluation.questionnaire.back')" @click="back" class="my-5"></Button>
            <div class="w-full flex justify-end">
              <Button :text="globalState.translate('evaluation.questionnaire.next')" @click="next(question)" class="my-5 ml-4" v-bind:is-primary="true"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

  .hide-element {
    display: none;
  }

  .nopointer {
    pointer-events: none;
  }

  .remove-to-right {
    animation: fadeOutToRightAnimation 200ms 1 ease-out;
  }

  .add-from-left {
    animation: fadeInFromLeftAnimation 200ms 1 ease-out;
  }

  @keyframes fadeOutToRightAnimation {
    from {
      opacity: 1;
    }

    30% {
      opacity: 0.3;
    }

    to {
      transform: translate(-1000px, 0px);
      opacity: 0;
    }
  }

  @keyframes fadeInFromLeftAnimation {
    from {
      opacity: 0;
      transform: translate(1000px, 0px);
    }
    to {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }


  .remove-to-left {
    animation: fadeOutToLeftAnimation 200ms 1 ease-out;
  }

  .add-from-right {
    animation: fadeInFromrightAnimation 200ms 1 ease-out;
  }

  @keyframes fadeOutToLeftAnimation {
    from {
      opacity: 1;
    }
    
    30% {
      opacity: 0.3;
    }

    to {
      transform: translate(1000px, 0px);
      opacity: 0;
    }
  }

  @keyframes fadeInFromrightAnimation {
    from {
      opacity: 0;
      transform: translate(-1000px, 0px);
    }
    to {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
</style>
