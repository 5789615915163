<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import OptionButton from '../../ui/OptionButton.vue';
import Button from '../../ui/Button.vue';
import TextArea from '../../ui/TextArea.vue';
import Mentoring from '../src/Mentoring.js';
import Question from '../src/Question.js';
import TextQuestion from '../src/TextQuestion';
import GlobalState from '../../../lib/GlobalState.js';

const globalState = inject('globalState') as GlobalState;


const { mentoring } = defineProps<{
  mentoring: Mentoring
}>();

const emit = defineEmits<{
  'back': [],
  'next': [],
}>();


const questions : Question[] = mentoring.getQuestions();
const currentIndex = ref<number>(0);
const container = ref<HTMLDivElement | null>(null);
const errorMessage = ref<string | null>(null);


const getCurrentElement = () : HTMLDivElement => {
  if (!container.value) throw new Error('Container not set');
  return container.value!.children[currentIndex.value] as HTMLDivElement;
}

const getPreviousElement = () : HTMLDivElement | null => {
  if (!container.value) throw new Error('Container not set');
  if (currentIndex.value === 0) return null;
  return container.value!.children[currentIndex.value - 1] as HTMLDivElement;
}

const getNextElement = () : HTMLDivElement | null => {
  if (!container.value) throw new Error('Container not set');
  if (currentIndex.value === container.value!.children.length - 1) return null;
  return container.value!.children[currentIndex.value + 1] as HTMLDivElement;
}

const goForward = () => {
  const currentElement = getCurrentElement();
  const nextElement = getNextElement();

  currentElement.classList.add('remove-to-right');
  currentElement.classList.add('nopointer');

  nextElement?.classList.add( 'add-from-left');
  nextElement?.classList.remove('hide-element');

  const listener = () => {
    currentElement.removeEventListener("animationend", listener);

    currentElement.classList.remove('remove-to-right');
    currentElement.classList.add('hide-element');
    nextElement?.classList.remove('add-from-left');
    nextElement?.classList.remove('nopointer');
    currentIndex.value++;
  }

  currentElement.addEventListener("animationend", listener);
}

const goBack = () => {
  const currentElement = getCurrentElement();
  const previousElement = getPreviousElement();

  currentElement.classList.add('remove-to-left');
  currentElement.classList.add('nopointer');

  previousElement?.classList.add( 'add-from-right');
  previousElement?.classList.remove('hide-element');

  const listener = () => {
    currentElement.removeEventListener("animationend", listener);

    currentElement.classList.remove('remove-to-left');
    currentElement.classList.add('hide-element');
    previousElement?.classList.remove('add-from-right');
    previousElement?.classList.remove('nopointer');
    currentIndex.value--;
  }

  currentElement.addEventListener("animationend", listener);
}

const next = (question: Question) => {
  question.save();
  if (currentIndex.value === questions.length - 1) {
    emit('next');
    return;
  }

  errorMessage.value = null;

  goForward();
}

const back = (question: Question) => {
  question.save();
  if (currentIndex.value === 0) {
    emit('back');
    return;
  }

  errorMessage.value = null;

  goBack();
}

</script>

<template>
  <div class="h-full w-full">
    <div ref="container" class="h-full flex justify-center items-center overflow-hidden w-full">
      <div v-for="(question, index) of questions" :key="index" 
        :class="currentIndex === index - 1 ? 'opacity-0 hide-element nopointer' : (currentIndex === index + 1 ? 'opacity-0 hide-element nopointer' : (currentIndex === index ? '' : 'opacity-0 hidden'))" 
        class="absolute flex justify-center items-center w-full">

        <div class="flex flex-col sm:w-[1000px] w-full h-fit mb-20 items-center">
          <div class="text-2xl font-4xl mb-12 flex flex-col sm:flex-row items-center">
            <span class="text-medeval mr-2 block sm:inline-block">{{ mentoring.getMenteeUserName() }}</span> 
            <span class="hidden sm:inline-block px-3">-</span>
            <span class="block sm:inline-block">{{ question.category }}</span>
          </div>

          <div class="text-lg font-4xl mb-2 flex items-center justify-center w-full">
            <div class="text-center sm:w-2/3 px-4 mb-4">
              {{ globalState.translate('mentoring.questionnaire.goals', [['category', question.category]]) }}
            </div>
          </div>

          <div v-if="(question instanceof TextQuestion)" class="flex items-center w-full justify-center">
            <TextArea class="w-full px-8 sm:w-[740px]" v-model="question.answer.value" :placeholder="globalState.translate('mentoring.questionnaire.text-placeholder')"></TextArea>
          </div>

          <div v-if="errorMessage" class="flex items-center justify-center text-red-500">
            {{ errorMessage }}
          </div>

          <div class="flex w-full sm:px-32 px-8 mt-8">
            <Button :text="globalState.translate('mentoring.questionnaire.back')" @click="back(question)" class="my-5"></Button>
            <div class="w-full flex justify-end">
              <Button :text="globalState.translate('mentoring.questionnaire.next')" @click="next(question)" class="my-5 ml-4" v-bind:is-primary="true"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

  .hide-element {
    display: none;
  }

  .nopointer {
    pointer-events: none;
  }

  .remove-to-right {
    animation: fadeOutToRightAnimation 200ms 1 ease-out;
  }

  .add-from-left {
    animation: fadeInFromLeftAnimation 200ms 1 ease-out;
  }

  @keyframes fadeOutToRightAnimation {
    from {
      opacity: 1;
    }

    30% {
      opacity: 0.3;
    }

    to {
      transform: translate(-1000px, 0px);
      opacity: 0;
    }
  }

  @keyframes fadeInFromLeftAnimation {
    from {
      opacity: 0;
      transform: translate(1000px, 0px);
    }
    to {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }


  .remove-to-left {
    animation: fadeOutToLeftAnimation 200ms 1 ease-out;
  }

  .add-from-right {
    animation: fadeInFromrightAnimation 200ms 1 ease-out;
  }

  @keyframes fadeOutToLeftAnimation {
    from {
      opacity: 1;
    }
    
    30% {
      opacity: 0.3;
    }

    to {
      transform: translate(1000px, 0px);
      opacity: 0;
    }
  }

  @keyframes fadeInFromrightAnimation {
    from {
      opacity: 0;
      transform: translate(-1000px, 0px);
    }
    to {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
</style>
