<script setup lang="ts">
import Button from '../../ui/Button.vue';
import { inject, ref } from 'vue';
import Mentoring from '../src/Mentoring.js';
import GlobalState from '../../../lib/GlobalState.js';
import CheckIcon from 'vue-material-design-icons/Check.vue'

const globalState = inject('globalState') as GlobalState;

const { mentoring } = defineProps<{
  mentoring: Mentoring
}>();

const emit = defineEmits<{
  'back': [],
  'new-mentoring': [],
}>();



const back = () => emit('back');
const next = () => {
  emit('new-mentoring');
}


const downloadReport = async() => {
  await globalState.getClient().get(`/report/mentoring`).setParameters({
      mentoringId: mentoring.getMentoringId() || 0,
    })
    .useRole(Mentoring.role)
    .expect(200)
    .download();
}



</script>

<template>
  <div class="h-full w-full">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col sm:w-[700px] h-fit mb-24">
        <div class="text-2xl font-4xl mb-16 mx-5 flex justify-center items-center flex-col">
          <div class="mb-12">
            {{ globalState.translate('mentoring.end.text') }} <span class="text-medeval">{{ mentoring.getMenteeUserName() }}</span> 
          </div>
          <CheckIcon class="h-32 w-32 ml-2 text-medeval" :size="150"></CheckIcon>
          <div class="mt-8 text-lg">
            <div @click="downloadReport" class="hover:text-medeval underline cursor-pointer mt-6">{{ globalState.translate('mentoring.download.mentroingReport') }}</div>
          </div>
        </div>

        <div class="flex mt-4">
          <!--Button :text="globalState.translate('mentoring.end.back')" @click="back" class="my-5"></Button-->
          <div class="w-full flex justify-end">
            <!--Button :text="globalState.translate('evaluation.end.logout')" @click="next" class="my-5 ml-4"></Button-->
            <Button :text="globalState.translate('mentoring.end.newMentoring')" @click="next" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

