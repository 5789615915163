<script setup lang="ts">
import { inject } from 'vue';
import GlobalState from '../../lib/GlobalState.js';


const globalState = inject('globalState') as GlobalState;

</script>

<template>
  <div class="w-full h-full flex flex-col">
    <div class="h-full flex-grow">
      <div class="text-3xl">{{ globalState.translate('dashboard.welcome', [['name', globalState.getAuthentication().getFullName()]]) }}</div>

      <div class="text-xl mt-16">{{ globalState.translate('dashboard.introduction') }}</div>
      
    </div>
  </div>
</template>



