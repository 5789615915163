<script lang="ts" setup>
import { ref } from 'vue';

defineProps<{
  modelValue: string
  errorMessage?: string,
  label?: string,
  placeholder?: string,
}>();

const emit = defineEmits<{
  'update:modelValue': [text: string | null],
}>();

const isActive = ref(false);


const textChanged = (event : Event) => {
  const textValue = (event.target as HTMLInputElement).value;
  emit('update:modelValue', textValue);
}


</script>

<template>
  <div class="flex flex-col">
    <div v-if="label" class="text-gray-600 text-base ml-5 mb-4">{{ label }}</div>

    <div :class="(errorMessage ? 'border-red-500' : '') + ' ' + (isActive ? 'border-medeval' : 'border-gray-400')" class="relative outline-none h-72 border bg-white rounded-lg z-2 flex hover:border-medeval" >
      <textarea @focusin="isActive = true" @focusout="isActive=false" @keyup="textChanged" :value="modelValue" type="text" class="h-full text-slate-800 w-full px-4 py-2 border-none focus:outline-none focus:ring-0 ml-1 " :placeholder="placeholder" />
    </div>

    <div v-if="errorMessage" class="h-9">
      <div class="text-red-500 text-sm mt-2 ml-5">{{ errorMessage }}</div>
    </div>
  </div>
</template>