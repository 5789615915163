<script setup lang="ts">
import DataTable from '../ui/DataTable.vue';
import DataTableColumn from '../ui/DataTableColumn.vue';
import { inject, onMounted, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';


const globalState = inject('globalState') as GlobalState;

const data = ref([
  {
    id: 1,
    field: 'Name',
    value: 'SOFThera AG'
  },
  {
    id: 2,
    field: 'Adresse',
    value: 'Fluplatzstrasse 32A, 3122 Kehrsatz, Schweiz'
  },
  {
    id: 3,
    field: 'UID',
    value: 'CHE-309.573.982'
  },
  {
    id: 4,
    field: 'MWST',
    value: 'CHE-309.573.982 MWST'
  }
]);



</script>

<template>
  <div class="h-screen w-screen max-w-6xl">
    
    <h1 class="text-6xl font-extralight">{{ globalState.translate('about.imprint.title') }}</h1>

    <!--div class="mt-8">
      <DataTable :data="data">
        <DataTableColumn slot="header" :field="'field'" :label="'about.imprint.field'"></DataTableColumn>
        <DataTableColumn slot="header" :field="'value'" :label="'about.imprint.value'"></DataTableColumn>
      </DataTable>  
    </div-->


    <h4 class="text-3xl font-light mt-16">{{ globalState.translate('about.imprint.contact title') }}</h4>
    <div class="text-xl font-light mt-4">
      SOFThera AG<br />
      Fluplatzstrasse 32A<br />
      3122 Kehrsatz<br />
      Schweiz
    </div>


    <h4 class="text-3xl font-light mt-16">{{ globalState.translate('about.imprint.hr.title') }}</h4>
    <div class="text-xl font-light mt-4">
      {{ globalState.translate('about.imprint.hr.company-name') }} SOFThera AG<br />
      {{ globalState.translate('about.imprint.hr.no') }}: <a href="https://www.zefix.ch/en/search/entity/list/firm/1594554">CHE-309.573.982</a><br />
    </div>

    <h4 class="text-3xl font-light mt-16">{{ globalState.translate('about.imprint.vat.title') }}</h4>
    <div class="text-xl font-light mt-4">
      CHE-309.573.982 MWST
    </div>

    <h4 class="text-3xl font-light mt-16">{{ globalState.translate('about.imprint.privacy.title') }}</h4>
    <div class="text-xl font-light mt-4">
      {{ globalState.translate('about.imprint.privacy.dpo-text') }}
      <a target="_blank" href="mailto:dpo@softhera.com">{{ globalState.translate('about.imprint.privacy.dpo-mail-link') }}</a>
    </div>
    <div class="text-xl font-light mt-4">
      <a target="_blank" href="https://rational-feedback.com/privacy">{{ globalState.translate('about.imprint.privacy.link') }}</a>
    </div>

    <h4 class="text-3xl font-light mt-16">{{ globalState.translate('about.imprint.terms.title') }}</h4>
    <div class="text-xl font-light mt-4">
      <a target="_blank" href="https://rational-feedback.com/terms">{{ globalState.translate('about.imprint.terms.link') }}</a>
    </div>
</div>
</template>