import GlobalState from '../GlobalState.js';

type TranslationMap = Map<string, string>;
export type TranslationReplacementValue = [string, string];


export default class Translations {

    private readonly globalState: GlobalState;
    private readonly translations: TranslationMap = new Map();
    private readonly createdTranslations = new Set<string>();

    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public async load() : Promise<void> {
        const response = await this.globalState.getClient().get('/rf/string-translation/webapp').send();
        const data = await response.json() as { translations: { key: string, text: string }[] };

        for (const { key, text } of data.translations) {
            this.translations.set(key, text);
        }
    }


    public async reload() : Promise<void> {
        this.translations.clear();
        await this.load();
    }

    public translate(key: string, values?: TranslationReplacementValue[]) : string {
        if (this.translations.has(key)) {
            let text = this.translations.get(key);

            if (Array.isArray(values) && values.length > 0) {
                for (const [find, replace] of values) {
                    text = text!.replace(new RegExp(`\{\{\\s*${find}\\s*\}\}`, 'gi'), replace);
                }
            }

            return text!;
        }

        if (!this.createdTranslations.has(key)) {
            this.tryCreateTranslation(key);
            this.createdTranslations.add(key);
        }

        return `missing translation: ${key}`;
    }

    public async tryCreateTranslation(key: string) : Promise<void> {
        // reduce load on the server by only creating translations if the user has the necessary role
        if (!this.globalState.getAuthentication().hasRole('translation-admin')) return;

        await this.globalState.getClient().post('/rf/string-translation/webapp')
            .setJSONBody({ key })
            .useRole('translation-admin')
            .send();

        this.createdTranslations.add(key);
    }
}