<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter, useRoute, matchedRouteKey } from 'vue-router';
import Button from '../ui/Button.vue';
import DateTimeSelector from '../ui/DateTimeSelector.vue';
import MentoringManager, { Mentoring } from './src/MentoringManager.js';
import { ComputerDesktopIcon } from '@heroicons/vue/24/outline';
import ComboBox, { ComboBoxItem } from '../ui/ComboBox.vue';


const route = useRoute();
const router = useRouter();

const globalState = inject('globalState') as GlobalState;
const mentoringManager = new MentoringManager(globalState);
const errorMessage = ref<string | null>(null);
const mentoringDate = ref<Date>(new Date());
const loading = ref<boolean>(true);
const persons = ref<ComboBoxItem[]>([]);
const selectedPerson = ref<ComboBoxItem | null>(null);
const personErroMessage = ref<string | null>(null);

(async() => {
  loading.value = false;
  const mentees = await mentoringManager.getMentees();
  for (const { id, firstName, lastName } of mentees) {
      persons.value.push({
        id,
        name: firstName + ' ' + lastName,
      });
    }
})();



const save = async() => {
  if (!mentoringDate.value) return;
  if (!selectedPerson.value) {
    personErroMessage.value = globalState.translate('mentorings.create.person-missing');
    return;
  }
  errorMessage.value = null;
  personErroMessage.value = null;

  if (mentoringDate.value < new Date()) {
    errorMessage.value = globalState.translate('mentorings.create.date-in-past');
    return;
  }

  const newMentoring = await mentoringManager.createSchedule(selectedPerson.value!.id, mentoringDate.value) as Mentoring;

  router.replace(`/app/mentorings/${newMentoring.id}` );

  globalState.showNotification({
    title: globalState.translate('mentorings.create.created.title'),
    text: globalState.translate('mentorings.create.created.text'),
    type: 'success'
  });
}

const displayDate = computed(() => {
  return globalState.formatDate(mentoringDate.value, 'datetime-mid');
})


</script>
<template>
  <div class="">
    <div class="flex items-center ml-5">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('mentorings.create.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('mentorings.create.description') }}</p>
      </div>
    </div>
    <div v-if="!loading" class="mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 max-w-5xl relative">
      <div class="ml-5  h-fit">

       
       <ComboBox :label="globalState.translate('mentoring.create.combobox.label')" :error-message="personErroMessage" v-model="selectedPerson" :items="persons" :placeholder="globalState.translate('mentoring.create.placeholder')"></ComboBox>
      </div>

      <div class="ml-5 flex items-end flex-col">
        <div>
          <div class="mb-8">{{ globalState.translate('mentorings.create.datetime') }}</div>
          <DateTimeSelector v-model:date="mentoringDate" :errorMessage="errorMessage" class=""></DateTimeSelector>
        </div>
      </div>

      <div class="md:col-span-2 mt-6 ml-5 flex gap-6 justify-end">
        <Button :disabled="false" @click="save" :is-primary="true" :text="globalState.translate('mentorings.create.save')"  />
      </div>
    </div>
  </div>
</template>