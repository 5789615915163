import { Ref, ref } from 'vue';
import GlobalState from '../GlobalState.js';


export interface NavigationItem {
    name: string;
    path: string;
    icon: string;
    roles: string[];
    current: boolean
    children: NavigationItem[];
    parent: NavigationItem | null;
    open: boolean;
}


export default class Navigation  {

    private readonly globalState: GlobalState;
    private topItems = ref<NavigationItem[]>([]);
    private bottomItems = ref<NavigationItem[]>([]);
    private loaded = false;

    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    getTopNavigation() : Ref<NavigationItem[]> {
        return this.topItems;
    }

    getBottomNavigation() : Ref<NavigationItem[]> {
        return this.bottomItems;
    }


    public async load() {
        if (this.loaded) return;

        const response = await this.globalState.getClient().get('/rf/navigation/webapp,webapp-bottom').noRetry().expect(200).useRole('user').send();
        const list = await response.json() as [ identifier: string, items: NavigationItem[]][];

        for (const [identifier, items] of list) {
            if (identifier === 'webapp') {
                this.topItems.value = this.createReferenceTree(items, null);
            }

            if (identifier === 'webapp-bottom') {
                this.bottomItems.value = this.createReferenceTree(items, null);
            }
        }

        this.loaded = true;
    }



    private createReferenceTree(items: NavigationItem[], preantItem: NavigationItem | null) : NavigationItem[] {
        for (const item of items) {
            item.parent = preantItem;
            item.open = false;
            this.createReferenceTree(item.children, item);
        }

        return items;
    }
}