<script setup lang="ts">
import Button from '../../ui/Button.vue';
import GlobalState from '../../../lib/GlobalState.js';
import { inject } from 'vue';
import Mentoring from '../src/Mentoring.js';

const globalState = inject('globalState') as GlobalState;

const { mentoring } = defineProps<{
  mentoring: Mentoring
}>();

const emit = defineEmits<{
  'back': [],
  'next': [],
}>();

const back = () => emit('back');
const next = () => emit('next');


const downloadMenteeReport = async() => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  });

  await globalState.getClient().get(`/report/evaluation`).setParameters({
      menteeUserId: mentoring.getMenteeUserId(),
      isMentorReport: false,
    })
    .useRole(Mentoring.role)
    .expect(200)
    .download();


}

const downloadMentorReport = async() => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  });

  await globalState.getClient().get(`/report/evaluation`).setParameters({
      menteeUserId: mentoring.getMenteeUserId(),
      isMentorReport: true,
    })
    .useRole(Mentoring.role)
    .expect(200)
    .download();
}

</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-2xl font-4xl mb-16 flex flex-col item">
          <div>
            <span class="text-medeval">{{ mentoring.getMenteeUserName() }}</span> 
            <span class="px-3">-</span>
            {{ globalState.translate('mentoring.download.title') }}
          </div>
          <div class="mt-8">{{ globalState.translate('mentoring.download.text') }}</div>
          <div class="mt-8 text-lg">
            <div @click="downloadMenteeReport" class="mt-4 hover:text-medeval underline cursor-pointer">- {{ globalState.translate('mentoring.download.downloadMentee') }}</div>
            <div @click="downloadMentorReport" class="mt-1 hover:text-medeval underline cursor-pointer">- {{ globalState.translate('mentoring.download.downloadMentor') }}</div>
          </div>
        </div>
        <div class="flex">
          <Button :text="globalState.translate('mentoring.download.back')" @click="back" class="my-5"></Button>
          <div class="w-full flex justify-end">
            <Button :text="globalState.translate('mentoring.download.next')" @click="next" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

