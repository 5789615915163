<script lang="ts" setup>
import { ref, watch } from 'vue';
import Question from '../eval/src/Question.js';

const { question, answer } = defineProps<{
  text: string
  displayText: boolean
  firstItem?: boolean,
  lastItem?: boolean,
  question: Question,
  answer: number | boolean,
}>();

const emit = defineEmits<{
  'click': [],
}>();


const selectedAnswer = ref<boolean>(answer === question.answer.value);
const hover = ref(false);

const click = () => {
  question.setAnswer(answer);
  emit('click');
}

watch([question.answer], () => {
  selectedAnswer.value = answer === question.answer.value;
});


</script>

<template>
  <div class="w-full sm:w-auto sm:flex sm:items-center sm:justify-center" >
    <div @click="click" class="hidden select-none relative sm:flex flex-row h-32 justify-center items-center cursor-pointer" @mouseenter="hover = true" @mouseleave="hover = false">
      <div :class="firstItem ? '' : (hover || selectedAnswer ? 'bg-to-medeval' : 'bg-slate-200')" class="w-14 h-[6px]"></div>

      <div class="h-12 w-[200px] absolute top-0 flex justify-center">
        <div v-if="displayText" class="absolute text-gray-400 text-base">{{ text }}</div>
      </div>

      <div :class="hover || selectedAnswer ? 'border-medeval bg-cyan-50' : 'border-slate-300'" class="cursor-pointer h-10 w-10 border-2 bg-white rounded-full flex items-center justify-center">
        <div :class="hover || selectedAnswer ? 'border-medeval bg-medeval' : 'border-slate-300 bg-white'" class="pointer-events-none h-7 w-7 border-2 rounded-full  flex items-center justify-center" >
          <div :class="hover || selectedAnswer ? 'bg-white' : ''" class="pointer-events-none h-1 w-1 rounded-full"></div>
        </div>
      </div>

      <div class="h-12 w-[200px] absolute top-[100px] flex justify-center">
        <div v-if="hover || selectedAnswer" class="absolute text-gray-600 font-bold text-base">{{ text }}</div>
      </div>

      <div :class="lastItem ? '' : (hover || selectedAnswer ? 'bg-from-medeval' : 'bg-slate-200')" class="w-14 h-[6px]"></div>
    </div>


    <div @click="click" class="sm:hidden select-none w-full flex flex-col justify-center items-end cursor-pointer pr-8" @mouseenter="hover = true" @mouseleave="hover = false">
      <div :class="firstItem ? '' : (hover || selectedAnswer ? 'bg-to-medeval-v' : 'bg-slate-200')" class="h-4 w-[6px] mr-4"></div>

      <div class="flex items-center">
        <div class="">
          <div  class="text-gray-400 text-base pr-2" :class="{
            'font-bold': hover || selectedAnswer,
            'text-slate-800': displayText
          }">{{ text }}</div>
        </div>

        <div :class="hover || selectedAnswer ? 'border-medeval bg-cyan-50' : 'border-slate-300'" class="cursor-pointer h-10 w-10 border-2 bg-white rounded-full flex items-center justify-center">
          <div :class="hover || selectedAnswer ? 'border-medeval bg-medeval' : 'border-slate-300 bg-white'" class="pointer-events-none h-7 w-7 border-2 rounded-full  flex items-center justify-center" >
            <div :class="hover || selectedAnswer ? 'bg-white' : ''" class="pointer-events-none h-1 w-1 rounded-full"></div>
          </div>
        </div>

      </div>
      

      <div :class="lastItem ? '' : (hover || selectedAnswer ? 'bg-from-medeval-v' : 'bg-slate-200')" class="h-4 w-[6px] mr-4"></div>
    </div>
  </div>
</template>


<style scoped>

  .bg-to-medeval {
    background: linear-gradient(90deg, #E2E8F0 0%, #A5F3FC 100%);
  }

  .bg-from-medeval {
    background: linear-gradient(90deg, #A5F3FC 0%, #E2E8F0 100%);
  }

  .bg-to-medeval-v {
    background: linear-gradient(0deg, #E2E8F0 0%, #A5F3FC 100%);
  }

  .bg-from-medeval-v {
    background: linear-gradient(0deg, #A5F3FC 0%, #E2E8F0 100%);
  }
</style>
