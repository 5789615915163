<script setup lang="ts">
import Logo from '../lib/Logo.vue';
import InselLogo from '../lib/InselLogo.vue';
import Evaluation from './src/Evaluation.js';
import { ComboBoxItem } from '../ui/ComboBox.vue';
import { inject, ref } from 'vue';
import EvaluationSelectPerson from './views/SelectPerson.vue';
import EvaluationResume from './views/ResumeEvaluation.vue';
import EvaluationDate from './views/PeriodSelector.vue';
import EvaluationQuestionnaire from './views/Questionnaire.vue';
import End from './views/End.vue';
import GlobalState from '../../lib/GlobalState.js';

type Stage = 'select-person' | 'resume-eval' | 'date-selection' | 'resume-evaluation' | 'new-evaluation' | 'end';



const globalState = inject('globalState') as GlobalState;
const stage = ref<Stage>('select-person');
const selectedPerson = ref<ComboBoxItem | null>(null);
const stageHistory = ref<Stage[]>([]);

let evaluation :Evaluation = new Evaluation(globalState);


let evaluationUserId: number | null = null;
let reviewerId: number | null = null;
let evaluationUserName: string | null = null;


const resetEvaluation = () => {
  evaluation = new Evaluation(globalState);
  evaluationUserId = null;
  reviewerId = null;
  evaluationUserName = null;
}


const personSelected = async() => {
  evaluationUserId = selectedPerson.value!.id;
  reviewerId = globalState.getAuthentication().getUserId();
  evaluationUserName = selectedPerson.value!.name;

  const response = await globalState.getClient().get('/rf/eval/pending')
    .setParameter('evalUserId', selectedPerson.value!.id)
    .useRole(evaluation.role)
    .expect(200, 404)
    .send();

  stageHistory.value.push(stage.value);
  
  if (response.hasStatus(200)) {
    // ask if the user lieks to resume an evaluation

    const data = await response.json();

    await evaluation.resume({
      evaluationUserId: evaluationUserId!,
      reviewerId: reviewerId!,
      evaluationUserName: evaluationUserName!,
      evaluationId: data.id,
      evaluationDate: new Date(data.evaluationDate),
      evaluationEndDate: new Date(data.evaluationEndDate),
      evaluationStartDate: new Date(data.evaluationStartDate),
    });

    stage.value = 'resume-evaluation';
  } else {
    // start a new evaluation
    await evaluation.startNew({
      evaluationUserId,
      reviewerId,
      evaluationUserName,
    });

    stage.value = 'date-selection';
  }
}

const resumeEvaluation = async() => {
  stageHistory.value.push(stage.value);
  stage.value = 'new-evaluation';
}

const newEvaluation = async() => {
  await evaluation.delete();
  stageHistory.value = ['select-person'];
  evaluation = new Evaluation(globalState);

  await evaluation.startNew({
      evaluationUserId: evaluationUserId!,
      reviewerId: reviewerId!,
      evaluationUserName: evaluationUserName!,
  });

  stage.value = 'date-selection';
}

const dateSelected = () => {
  stageHistory.value.push(stage.value);
  stage.value = 'new-evaluation';
}

const questionsAnswered = () => {
  evaluation.finish();

  stageHistory.value.push(stage.value);
  stage.value = 'end';
}

const startNewEvaluation = () => {
  resetEvaluation();

  stageHistory.value = [];
  stage.value = 'select-person';
}


const back = () => {
  const stageValue = stageHistory.value.pop()!

  if (stageValue === 'select-person') resetEvaluation();

  stage.value = stageValue;
}



</script>

<template>
  <div class="w-full flex-1 flex flex-col">
    <div class="flex-1 flex-grow sm:m-8 flex items-center justify-center">
      <EvaluationSelectPerson v-if="stage === 'select-person'" 
        v-model="selectedPerson" 
        :evaluation="evaluation!"
        @next="personSelected">
      </EvaluationSelectPerson>

      <EvaluationResume v-else-if="stage === 'resume-evaluation'" 
        :evaluation="evaluation!" 
        @back="back" 
        @resume="resumeEvaluation" 
        @new="newEvaluation">
      </EvaluationResume>

      <EvaluationDate v-else-if="stage === 'date-selection'" 
        :evaluation="evaluation!" 
        @back="back" 
        @next="dateSelected">
      </EvaluationDate>

      <EvaluationQuestionnaire v-else-if="stage === 'new-evaluation'" 
        :evaluation="evaluation!" 
        @next="questionsAnswered" 
        @back="back">
      </EvaluationQuestionnaire>

      <End v-else-if="stage === 'end'" 
        @back="back" 
        @new-evaluation="startNewEvaluation" 
        :evaluation="evaluation!">
      </End>
    </div>
  </div>
</template>

