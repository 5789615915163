<script setup lang="ts">
import Button from '../../ui/Button.vue';
import { inject, ref } from 'vue';
import Evaluation from '../src/Evaluation.js';
import GlobalState from '../../../lib/GlobalState.js';
import CheckIcon from 'vue-material-design-icons/Check.vue'

const globalState = inject('globalState') as GlobalState;

const { evaluation } = defineProps<{
  evaluation: Evaluation
}>();

const emit = defineEmits<{
  'back': [],
  'new-evaluation': [],
}>();



const back = () => emit('back');
const next = () => {
  emit('new-evaluation');
}



</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-2xl font-4xl mb-16 mx-5 flex justify-center items-center flex-col">
          <div class="mb-12">
            {{ globalState.translate('evaluation.end.text') }} <span class="text-medeval">{{ evaluation.getEvaluationUserName() }}</span> 
          </div>
          <CheckIcon class="h-32 w-32 ml-2 text-medeval" :size="150"></CheckIcon>
        </div>

        <div class="flex mt-8">
          <!--Button :text="globalState.translate('evaluation.end.back')" @click="back" class="my-5"></Button-->
          <div class="w-full flex justify-end">
            <!--Button :text="globalState.translate('evaluation.end.logout')" @click="next" class="my-5 ml-4"></Button-->
            <Button :text="globalState.translate('evaluation.end.newEvaluation')" @click="next" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

