<script lang="ts" setup>
import { ref, watch } from 'vue';

defineProps<{
  text: string;
  title: string;
}>();

const isActive = ref(false);

const escapeHandler = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    toggle();
  }
}

const toggle = () => {
  isActive.value = !isActive.value;
  if (!isActive.value) {
    window.removeEventListener('keydown', escapeHandler);
  } else {
    window.addEventListener('keydown', escapeHandler);
  }
}


</script>

<template>
  <div class="inline-block relative">
    <div class="flex cursor-pointer hover:text-medeval h-6 w-6 text-slate-300 ml-1 select-none" @click="toggle">
      <span class="material-symbols-outlined h-4 w-4">info</span>
    </div>
    <div v-if="isActive" class="fixed top-0 left-0 w-screen h-screen" @click="toggle"></div>
    <div v-if="isActive" class="flex absolute w-96 min-h-40 max-h-96 rounded-lg shadow-2xl z-20 border p-3 bg-white flex-col">
      <div class="flex-grow-0 flex items-center text-slate-500">
        <div class="flex-grow">{{ title  }}</div>
        <div @click="toggle" class="mr-2 cursor-pointer"><span class="material-symbols-outlined h-4 w-4 text-slate-400">cancel</span></div>
      </div>
      <div class="flex-grow font-normal text-slate-700 overflow-y-auto overflow-x-hidden">{{ text }}</div>
    </div>
    
  </div>
</template>