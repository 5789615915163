<script setup lang="ts">
import ComboBox, { ComboBoxItem } from '../../ui/ComboBox.vue';
import Button from '../../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../../lib/GlobalState.js';
import Mentoring from '../src/Mentoring.js';


const globalState = inject('globalState') as GlobalState;



const { mentoring } = defineProps<{
  mentoring: Mentoring,
}>();



export interface SelectedReport {
  reportId: number,
  evaluationIds: number[],
}


const persons = ref<ComboBoxItem[]>([]);
const selectedPerson = ref<ComboBoxItem | null>(null);
const personErroMessage = ref<string | null>(null);


const loadPersons = async() => {
  for (const item  of await mentoring.loadPersons()) {
    persons.value.push(item);
  }
}

loadPersons();

const emit = defineEmits<{
  'next': [],
}>();

const next = async() => {
  if (!selectedPerson) {
    personErroMessage.value = globalState.translate('evaluation.personselector.errormessage');
    return;
  }

  await mentoring.setPerson(selectedPerson.value!.id, selectedPerson.value!.name);

  emit('next');
}





</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-5xl font-thin mb-32 mx-5">{{ globalState.translate('mentoring.personselector.title') }}</div>
        <div v-if="persons.length > 0">
          <div class="text-2xl font-4xl mb-16 mx-5">{{ globalState.translate('mentoring.personselector.text') }}</div>
          <ComboBox :error-message="personErroMessage" v-model="selectedPerson" :items="persons" :placeholder="globalState.translate('mentoring.personselector.placeholder')"></ComboBox>
          <!--div class="mx-5 mt-3">
            <div class="underline">{{ globalState.translate('mentoring.personselector.personmissing') }}</div>
          </div-->
         
          <div class="flex justify-end mt-6">
            <Button :disabled="!selectedPerson" :text="globalState.translate('mentoring.personselector.next')" @click="next()" class="my-5" v-bind:is-primary="true"></Button>
          </div>
        </div>
        <div v-else>
          <div class="text-2xl mb-16 mx-5">{{ globalState.translate('mentoring.personselector.nopersons') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

