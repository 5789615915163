import { DateTime } from 'luxon';
import GlobalState from '../GlobalState.js';


export type DateFormat = 'date' | 'date-mid' | 'time' | 'datetime' | 'datetime-mid';
export type ShortDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export default class DateFormatter {


    private readonly globalState: GlobalState;
    private readonly dayMap : Map<ShortDay, number> = new Map([
        ['mon', 0],
        ['tue', 1],
        ['wed', 2],
        ['thu', 3],
        ['fri', 4],
        ['sat', 5],
        ['sun', 6],
    ]);


    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public getPrevious(day: ShortDay, minDistance: number = 0) : Date {
        const now = new Date();
        const requestedDayIndex = this.dayMap.get(day)!; // 0 = mon, 1 = tue, ..., 6 = sun
        const todayIndex = now.getDay() === 0 ? 6 : now.getDay() - 1; // sun = 6, mon = 0, ..., sat = 5

        // get the number of days to move backwards to land on the requested day
        let diff = (todayIndex - requestedDayIndex) >= 0 ? todayIndex - requestedDayIndex : todayIndex + 7 - requestedDayIndex;

        // if the difference is less than the minimum distance, add 7 to the difference, to move back to the previous week
        if (minDistance > diff) diff += 7;

        return DateTime.fromJSDate(now).minus({days: diff}).toJSDate();
    }


    public subtractDays(date: Date, days: number) : Date {
        return DateTime.fromJSDate(date).minus({days}).toJSDate();
    }

    public addDays(date: Date, days: number) : Date {
        return DateTime.fromJSDate(date).plus({days}).toJSDate();
    }

    private getLocale() : string {
        return this.globalState.getLocale().replace('---', '');
    }


    public format(date: Date, dateFormat: DateFormat) : string {
        const locale = this.getLocale();

        switch (dateFormat) {
            case 'date':
                return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.DATE_SHORT);
            case 'date-mid':
                return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.DATE_MED);
            case 'time':
                return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE);
            case 'datetime':
                return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.DATETIME_SHORT);
            case 'datetime-mid':
                return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(DateTime.DATETIME_MED);

            default:
                throw new Error(`Unknown date format: ${dateFormat}`);
        }
    }


    public getMonthName(month: Date) : string {
        return DateTime.fromJSDate(month).setLocale(this.getLocale()).toLocaleString({month: 'long'});
    }

    public getShortMonthName(month: Date) : string {
        return DateTime.fromJSDate(month).setLocale(this.getLocale()).toLocaleString({month: 'short'});
    }


    public getWeekDayNames() : string[] {
        const now = DateTime.now().setLocale(this.getLocale()).startOf('week');
        const weekDayNames = [];

        for (let i = 0; i < 7; i++) {
            weekDayNames.push(now.plus({days: i}).toLocaleString({weekday: 'short'}));
        }
        
        return weekDayNames;
    }

    public getWeekDayName(day: Date) : string {
        return DateTime.fromJSDate(day).setLocale(this.getLocale()).toLocaleString({weekday: 'short'});
    }
}