<script setup lang="ts">
import { inject, reactive, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter, useRoute } from 'vue-router';
import ToggleSwitch from '../ui/ToggleSwitch.vue';

const route = useRoute();
const router = useRouter();


interface NotificationConfigiuration {
    id: number;
    enabled: boolean;
    offset: number;
    reference: string;
    name: string;
    description: string;
    type: string;
    typeDescription: string;
}


const globalState = inject('globalState') as GlobalState;
const configurations = reactive<NotificationConfigiuration[]>([]);
const loading = ref<boolean>(true);

(async() => {
  const response = await globalState.getClient().get('/rf/organization/notifications')
    .useRole('organization-admin')
    .send();

  if (response.hasStatus(200)) {
    const data = await response.json() as NotificationConfigiuration[];

    for (const config of data) {
      const type = config.reference !== null ? 'interval' : (config.offset === 0 ? 'immediate' : 'delayed');
      

      configurations.push({
        id: config.id,
        enabled: config.enabled,
        offset: config.offset,
        reference: config.reference,
        name: config.name,
        description: config.description,
        type,
        typeDescription: globalState.translate(`organization.notifications.type.${type}`),
      });
    }
  }

  loading.value = false;
})();


const update = (config: NotificationConfigiuration) => {
  globalState.getClient().update(`/rf/organization/notification/${config.id}`)
    .useRole('organization-admin')
    .setJSONBody({
      enabled: config.enabled,
    })
    .send();
}

</script>
<template>
  <div class="">
    <div class="flex items-center ml-5">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('organization.notifications.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('organization.notifications.description') }}</p>
      </div>
    </div>

    <div class="mt-12 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div v-if="loading" class="h-full text-xl flex items-center justify-center">
            {{ globalState.translate('organization.notifications.loading') }}
          </div>
          <table v-else class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('organization.notifications.listtitle.name') }}</th>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('organization.notifications.listtitle.description') }}</th>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('organization.notifications.listtitle.type') }}</th>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('organization.notifications.listtitle.configuration') }}</th>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('organization.notifications.listtitle.enabled') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="config of configurations" :key="config.id" >
                <tr class=" cursor-pointer border-t border-slate-200" >
                  <td class="h-10">
                    <div class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">{{ config.name }}</div>
                  </td>
                  <td class="pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <div class="px-3 text-base pl-4 h-full flex items-center">{{ config.description }}</div>
                  </td>
                  <td class="pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <div class="px-3 text-base pl-4 h-full flex items-center">{{ config.typeDescription }}</div>
                  </td>
                  <td v-if="config.type === 'interval'" class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <div class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">
                      {{ globalState.translate('organization.notifications.interval', [['day', globalState.getDateFormatter().getWeekDayName(new Date(config.reference))], ['time', globalState.getDateFormatter().format(new Date(config.reference), 'time') ]]) }}
                    </div>
                  </td>
                  <td v-else-if="config.type === 'delayed'" class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <div class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">
                      {{ globalState.translate('organization.notifications.delayed', [['offset', (config.offset / 3600).toString()]]) }}
                    </div>
                  </td>
                  <td v-else class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <div class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">
                      {{ globalState.translate('organization.notifications.immediate') }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <ToggleSwitch v-model="config.enabled" @click="update(config)" />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>