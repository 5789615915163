<script setup lang="ts">
import Button from '../ui/Button.vue';
import Email from '../ui/Email.vue';
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';

import Logo from '../lib/Logo.vue';


const globalState = inject('globalState') as GlobalState;

const successMessage = ref<string>('');
const errorMessage = ref<string>('');
const emailAddress = ref<string>('');
const loginOk = ref<boolean>(false);


const signIn = async(e?: Event) => {
  if (e) e.preventDefault();

  const email = emailAddress.value.trim();
  const route = globalState.getRedirectUrl();

  if (!/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
    errorMessage.value = globalState.translate('auth.login.invalidEmail');
    return;
  }

  try {
    globalState.getAuthentication().requestLoginEmail(email, route ?? undefined);
    successMessage.value = globalState.translate('auth.login.success', [['email', email]]);
    loginOk.value = true;
  } catch (e) {
    console.log(e);
  }
}

watch(emailAddress, () => {
  errorMessage.value = '';
});

const reload = () => {
  window.location.href = '/';
}

</script>

<template>
  <div class="h-screen w-screen flex items-center justify-center">
    <div class="flex flex-col items-center mb-24 max-w-96 sm:shadow-2xl sm:border sm:rounded-xl py-20 px-12">
      <form name="login" class="w-full flex justify-center flex-col items-center" @submit="signIn">
        <Logo class="mt-4 mb-12" height="100"></Logo>
        <div v-if="!loginOk" class="text-xl mb-8"> {{ globalState.translate('auth.login.title') }}</div>
        <Email v-if="!loginOk" class="w-full" name="email" v-model="emailAddress" :error-message="errorMessage"></Email>
        <Button v-if="!loginOk" class="mt-16" @click="signIn" :text="globalState.translate('auth.login.signing')"></Button>
        <div v-if="loginOk" class="flex flex-col items-center">
          <Button class="mt-4 min-w-56" @click="loginOk = false" :text="globalState.translate('auth.login.try-again')"></Button>
          <Button class="mt-4 min-w-56" @click="reload" :text="globalState.translate('auth.login.dashboard')"></Button>
        </div>
        <div class="text-xl mt-16" v-if="loginOk">
          {{ successMessage }}
        </div>
      </form>
    </div>
  </div>
</template>

