import { ref } from 'vue';
import Evaluation, { QuestionOption } from './Evaluation.js';


export interface QuestionData {
    id: number;
    text: string;
    category: string;
    order: number;
    options: QuestionOption[];
}

export interface QuestionOptions extends QuestionData {
    evaluation: Evaluation,
}



export default class Question {

    public readonly id: number;
    public readonly text: string;
    public readonly category: string;
    public readonly order : number;
    public readonly options : QuestionOption[];

    private isQuestionAnswered : boolean = false;

    public answer = ref<boolean | number | string | null>(null);

    private readonly evaluation: Evaluation;

    constructor({
        id,
        text,
        category,
        order,
        options,
        evaluation,
    } : QuestionOptions) {
        this.id = id;
        this.text = text;
        this.category = category;
        this.order = order;
        this.evaluation = evaluation;
        this.options = options;

        if (options.length && options[0].order) {
            this.options.sort((a, b) => a.order - b.order);
        }
    }

    public setAnswered() : void {
        this.isQuestionAnswered = true;
    }

    public isAnswered() : boolean {
        return this.isQuestionAnswered;
    }

    public async save() : Promise<void> {
        await this.evaluation.saveQuestion(this);
    }

    public setAnswer(response: any, dontSave = false) : void {
        throw new Error('Not implemented');
    }

    public getAnswer() : any {
        throw new Error('Not implemented');
    }

    public loadAnswer(input: string) : void {
        throw new Error('Not implemented');
    }

    public getStringAnswer() : string | null {
        throw new Error('Not implemented');
    }

    getConfiguration() : any {
        throw new Error('Not implemented');
    }
}