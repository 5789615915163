<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter, useRoute } from 'vue-router';
import Button from '../ui/Button.vue';
import Text from '../ui/Text.vue';
import Email from '../ui/Email.vue';
import ComboBox from '../ui/ComboBox.vue';
import CheckBox from '../ui/CheckBox.vue';
import { UserRole, MentorUser, User, Locale, Domain } from '../../lib/user/index.js';

const route = useRoute();
const router = useRouter();
const addNew = ref<boolean>(route.params.id === 'new');

const globalState = inject('globalState') as GlobalState;
const userManager = globalState.getUserManager();
const user = ref<User>(userManager.user());
const roles = ref<UserRole[]>([]);
const mentors = ref<MentorUser[]>([]);
const locales = ref<Locale[]>([]);
const domains = ref<Domain[]>([]);
const loading = ref<boolean>(true);
const saving = ref<boolean>(false);
const allRoles = ref<UserRole[]>([]);
let wasMentor = false;

const deleteButtonText = ref<string>(globalState.translate('user.edit.deactivate'));
const deleteWarned = ref<boolean>(false);

(async() => {
  try {
    if (addNew.value) user.value.create();
    else await user.value.load(parseInt(route.params.id as string, 10));

    if (user.value.hasRole('senior-physician')) {
      wasMentor = true;
    }

    for (const role of await user.value.userRoles) roles.value.push(role);
    for (const mentor of await userManager.getMentors()) mentors.value.push(mentor);
    for (const locale of await userManager.getLocales()) locales.value.push(locale);
    for (const domain of await userManager.getDomains()) domains.value.push(domain);
    for (const role of await userManager.getRoles()) {
      role.selected = roles.value.includes(role);
      allRoles.value.push(role);
    }
  } catch (e: any) {
    globalState.displayError(e);
  }
  
  loading.value = false;
})();



const save = async() => {
  saving.value = true;

  try {
    await user.value.save();

    if (wasMentor || user.value.hasRole('senior-physician')) {
      userManager.resetMentors();
    }

  } catch (e: any) {
    if (e.message.includes('exists')) {
      user.value.emailError = globalState.translate('user.edit.email-exists');
    } else if (e.message.includes('not valid')) {
      saving.value = false;
      return;
    } else {
      globalState.displayError(e);
    }

    saving.value = false;
    return;
  }

  saving.value = false;

  globalState.showNotification({
    title: globalState.translate('user.edit.save-notification.title'),
    text: globalState.translate('user.edit.save-notification.text'),
    type: 'success',
  });

  if (addNew.value) router.push('/admin/users');
}


const deactivate = async () =>  {
  if (globalState.getAuthentication().getUserId() === user.value.id) {
    globalState.showNotification({
      title: globalState.translate('user.edit.deactivate-self.title'),
      text: globalState.translate('user.edit.deactivate-self.text'),
      type: 'warning',
    });
    return;
  }

  if (!deleteWarned.value) {
    deleteWarned.value = true;
    deleteButtonText.value = globalState.translate('user.edit.delete-confirm');
    return;
  }

  await user.value.deactivate();

  globalState.showNotification({
    title: globalState.translate('user.edit.deactivated.title'),
    text: globalState.translate('user.edit.deactivated.text'),
    type: 'success',
  });

  router.push('/admin/users');
}


watch(allRoles, () => {
  for (const role of allRoles.value) {
    if (role.selected) user.value.addRole(role);
    else {
      if (role.identifier === 'user-admin' && globalState.getAuthentication().hasRole('user-admin') && user.value.id === globalState.getAuthentication().getUserId()) {
        globalState.showNotification({
          title: globalState.translate('user.edit.remove-user-admin-prohibited.title'),
          text: globalState.translate('user.edit.remove-user-admin-prohibited.text'),
          type: 'warning',
        });

        role.selected = true;

        return;
      }

      user.value.removeRole(role);
    }
  }
}, { deep: true });


</script>
<template>
  <div class="">
    <div class="flex items-center ml-5">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('user.edit.title.' + (addNew ? 'add' : 'edit'), [['action', '']]) }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('user.edit.description') }}</p>
      </div>
    </div>
    <div v-if="!loading" class="mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 max-w-5xl relative">
      <!--ComboBox :error-message="user.userRoleError" 
        class="md:col-span-2" 
        v-model="user.userRole" 
        name="group" :label="globalState.translate('user.edit.group-selector.label')" 
        :items="roles" 
        :placeholder="globalState.translate('user.edit.group-selector.placeholder')"></ComboBox>
      
      <div class="md:col-span-2 ml-4">
        <div v-if="user.userRole" class="text-base -mt-2 mb-8 text-slate-500">{{ user.userRole.description }}</div>
      </div-->

      

      <Text :error-message="user.firstnameError" 
        v-model="user.firstName" name="first-name" 
        :label="globalState.translate('user.edit.first-name')" 
        v-bind:required="true" />
      
      <Text :error-message="user.lastnameError" 
        v-model="user.lastName" name="last-name" 
        :label="globalState.translate('user.edit.last-name')" />

      <Text :error-message="user.registrationIdError" 
        v-model="user.registrationId" name="registration-id" 
        :label="globalState.translate('user.edit.registration-id')" />

      <Email :error-message="user.emailError" 
        v-model="user.email" name="email" 
        :label="globalState.translate('user.edit.email', [['domains', domains.map(d => '@' + d.domain).join(' or ')]])" />

      <ComboBox :error-message="user.localeError" 
        class=" " 
        v-model="user.locale" 
        name="locale" 
        :label="globalState.translate('user.edit.locale-selector.label')" 
        :items="locales" 
        :placeholder="globalState.translate('user.edit.locale-selector.placeholder')"></ComboBox>

      
      <div class="col-span-2 ml-5 mt-12">
        <div class="text-lg font-semibold mb-2">{{ globalState.translate('user.edit.roles') }}</div>
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base text-gray-900 sm:pl-0">{{ globalState.translate('user.edit.role-list.name') }}</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0">{{ globalState.translate('user.edit.role-list.description') }}</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="userRole of allRoles" :key="userRole.id">
              <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500 sm:pl-0">{{ userRole.name }}</td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-base  text-gray-900 sm:pl-0">{{ userRole.description }}</td>
              <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-base font-medium sm:pr-0">
                <div class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                  <CheckBox v-model="(userRole.selected as boolean)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ComboBox v-if="user.canBeMentored" 
        :error-message="user.menteeOfError" 
        class="md:col-span-2 mt-6" 
        v-model="user.menteeOf" 
        name="group" 
        :label="globalState.translate('user.edit.mentor-selector.label')" 
        :items="mentors" 
        :placeholder="globalState.translate('user.edit.mentor-selector.placeholder')"></ComboBox>

      <div v-if="user.canMentor" class="md:col-span-2 mt-6 ml-5">
        {{ globalState.translate('user.edit.mentee-selector.info') }}
      </div>

      <div class="md:col-span-2 mt-6 ml-5 flex">
        <div class="flex-grow"><Button v-if="!addNew" :disabled="saving" :is-warning="true" :text="deleteButtonText" class="mr-4 w-56" @click="deactivate" /></div>
        <div><Button :disabled="saving" :is-primary="true" :text="globalState.translate('user.edit.save')" @click="save" /></div>
      </div>
    </div>
  </div>
</template>