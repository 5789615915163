import Client from './Client.js';
import GlobalState from '../GlobalState.js';
import Navigation from './Navigation.js';
import Storage from './PeristentStorage.js';


interface LoginInfo {
    admin: boolean,
    csrfToken: string,
    defaultRole: string,
    roles: string[],
    sub: string,
    userId: number,
    tenantId: number,
    countryCode: string,
    languageCode: string,
}


interface UserInfo {
    firstName: string,
    id: number,
    lastName: string,
    primaryEmail: string,
    stateRegistryId: string,
    countryCode: string,
    languageCode: string,
}


export default class Authentication {

    private readonly globalState: GlobalState;
    private readonly storage : Storage;
    private readonly navigation: Navigation;

    private userIsSignedIn: boolean = false;

    private lastUserInfoLoad: number = 0;
    private userInforeloadInterval = 1000 * 60; // 1 minute


    constructor(globalState: GlobalState) {
        this.storage = new Storage('authentication');
        this.globalState = globalState;
        this.navigation = new Navigation(this.globalState);
    }


    public getLocale() : string {
        const languageCode = this.storage.get('languageCode');
        const countryCode = this.storage.get('countryCode');

        return languageCode ? `${languageCode}-${countryCode}` : 'en---';
    }


    /**
     * checks if the user is signed in. this may not represnt the truth because a cached value is used
     */
    public async isSignedIn() : Promise<boolean> {
        if (this.userIsSignedIn) {
            return true;
        }

        await this.loadUserInfo();

        return this.userIsSignedIn;
    }


    /**
     * Requests the server to send a login email to the user. Does always return 200 in order to prevent
     * email enumeration attacks.
     * 
     * @param email email address of the user
     * @param route  route to redirect to after login
     */
    public async requestLoginEmail(email: string, route?: string) : Promise<void> {
        const response = await this.getClient().post('/auth/email-login')
            .setJSONBody({ email, route })
            .expect(200)
            .send();
    }



    /**
     * log in the user using the one time password sent to the user via email
     * 
     * @param oneTimePassword one time password sent to the user via email
     */
    public async OTPLogin(oneTimePassword: string) : Promise<boolean>{
        const response = await this.getClient().post('/auth/otp-login')
            .setJSONBody({ oneTimePassword })
            .expect(200, 400)
            .send();

        if (response.status() === 400) {
            return false;
        }
        
        const payload = await response.json() as LoginInfo;
        
        await this.processLoginInfo(payload);
        
        await this.loadUserInfo();

        return true;
    }



    public async signOut() : Promise<void> {
        await this.getClient().post('/auth/logout').expect(200).send();
        
        this.resetData();

        window.location.href = '/';
    }



    /**
     * requests a new JWT from the server using the refresh token which is stored in a cookie.
     */
    public async refreshJWT() : Promise<boolean> {
        const response = await this.getClient().request({
            method: 'POST',
            path: '/auth/refresh',
            noJWTRefresh: true,
        });
        

        if (response.status === 200) {
            const payload = await response.json() as LoginInfo;
            
            await this.processLoginInfo(payload);
            return true;
        }

        this.resetData();
        return false;
    }


    /**
     * process the data received from the server after a successful login
     * 
     * @param data LoginInfo object returned by the server
     */
    private async processLoginInfo(data: LoginInfo) {
        this.userIsSignedIn = true;
        
        this.setCSRFToken(data.csrfToken);

        this.storage.setMany({
            userId: data.userId,
            tenantId: data.tenantId,
            defaultRole: data.defaultRole,
            roles: data.roles,
            admin: data.admin,
            countryCode: data.countryCode,
            languageCode: data.languageCode,
        });


        await this.globalState.setLocale(this.getLocale());
    }


    /**
     * loads the user info from the server
     */
    public async loadUserInfo() : Promise<boolean> {
        // return cached values
        if (this.lastUserInfoLoad + this.userInforeloadInterval > Date.now()) {
            return this.userIsSignedIn;
        }

        try {
            const response = await this.getClient().get('/auth/me')
                .useRole('user')
                .expect(200)
                .send();

            const data = await response.json() as UserInfo;
            
            if (data && data.id && data.id > 0) {
                this.userIsSignedIn = true;

                this.storage.setMany({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    primaryEmail: data.primaryEmail,
                    stateRegistryId: data.stateRegistryId,
                    countryCode: data.countryCode,
                    languageCode: data.languageCode,
                });


                await this.globalState.setLocale(this.getLocale());

                try {
                    await this.navigation.load();
                } catch (e: any) {
                    this.globalState.displayError(e);
                }

                return true;
            }
        } catch (e: any) {
            this.globalState.displayError(e);
        }

        this.resetData();
        return false;
    }

    public getNavigation() : Navigation {
        return this.navigation;
    }


    public setCSRFToken(token: string) : void {
        this.storage.set('csrfToken', token);
    }

    public getCSRFToken() : string {
        return this.storage.has('csrfToken') ? this.storage.get('csrfToken') : '';
    }



    private resetData() : void {
        this.storage.clear();
        this.userIsSignedIn = false;
    }


    private getClient() : Client {
        return this.globalState.getClient();
    }

    public getUserId() : number {
        if (!this.storage.has('userId')) throw new Error('Cannot return the userId: the user is not signed in!');
        return this.storage.get('userId');
    }

    public getRoles() : string[] {
        if (!this.storage.has('roles')) throw new Error('Cannot return the roles: the user is not signed in!');
        return this.storage.get('roles');
    }

    public getRole() : string {
        if (!this.storage.has('defaultRole')) throw new Error('Cannot return the default role: the user is not signed in!');
        return this.storage.get('defaultRole');
    }

    public hasRole(role: string) : boolean {
        return this.getRoles().includes(role);
    }

    public getFirstName() : string {
        if (!this.storage.has('firstName')) throw new Error('Cannot return the first name: the user is not signed in!');
        return this.storage.get('firstName');
    }
    
    public getTenantId() : number {
        if (!this.storage.has('tenantId')) throw new Error('Cannot return the tenant ID: the user is not signed in!');
        return this.storage.get('tenantId');
    }

    public getLastName() : string {
        if (!this.storage.has('lastName')) throw new Error('Cannot return the last name: the user is not signed in!');
        return this.storage.get('lastName');
    }

    public getFullName() : string {
        return `${this.getFirstName()} ${this.getLastName()}`;
    }

    public isSelfEvaluator() : boolean {
        return this.hasRole('self-evaluator');
    }
}

