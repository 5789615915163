<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import GlobalState from '../lib/GlobalState.js';
import Notification from './generic/Notification.vue';
import Button from './ui/Button.vue';


const globalState = inject('globalState') as GlobalState;
const showOverlay = ref<boolean>(false);
const message = ref<string>('');
const stack = ref<string[]>([]);

watch([globalState.error], () => {
  if (globalState.error.value && globalState.error.value.message && globalState.error.value.message.length) {
    showOverlay.value = true;
    message.value = globalState.error.value.message;
    if (globalState.error.value.stack) {
      stack.value = globalState.error.value.stack.split('\n');
    }
  }
});

</script>

<template>
  <div class="min-h-screen flex flex-col">
    <div v-if="showOverlay" class="absolute h-screen w-screen z-10 top-0 left-0 bg-white bg-opacity-70 flex items-center justify-center flex-col">
      <div class="w-2/3 rounded-lg border-2 bg-white shadow border-slate-100 flex flex-col items-center justify-center p-8">
        <div class="text-3xl text-red-500 mb-8">Ooops, an error occured!</div>
        <div class="text-xl mb-8 text-orange-400 " v-if="globalState.error.value !== null">{{ message }}</div>
        <div class="text-sm text-slate-400 mb-8 " v-if="globalState.error.value !== null">
          <div v-for="line in stack" :key="line">{{ line }}</div>
        </div>
        <div class="text-xl mb-8 text-green-600">Please reload the page and try again. If the error persists, please contact the support at support@softhera.com.</div>
        <div class="text-xl mb-8 text-green-600">We are sorry for the inconvenience.</div>
        <Button text="Close" @click="showOverlay = false"></Button>
      </div>
    </div>
    <router-view />
    <Notification />
  </div>
</template>
