<script setup lang="ts">
import { inject, ref } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import { useRouter } from 'vue-router';
import MentoringManager, { Mentoring, MentoringSchedule } from './src/MentoringManager.js';
import Button from '../ui/Button.vue';

const router = useRouter();
const globalState = inject('globalState') as GlobalState;
const mentoringManager = new MentoringManager(globalState);
const upcomingMentorings = ref<MentoringSchedule[]>([]);
const pastMentorings = ref<Mentoring[]>([]);

(async() => {
  for (const mentoring of await mentoringManager.getUpcomingMentorings()) {
    upcomingMentorings.value.push(mentoring);
  }

  for (const mentoring of await mentoringManager.getPastMentorings()) {
    pastMentorings.value.push(mentoring);
  }
})();



const downloadMenteeReport = async(mentoring: Mentoring) => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  })

  await globalState.getClient().get(`/report/evaluation/${mentoring.id}`).setParameters({
      isMentorReport: false,
      menteeUserId: mentoring.mentee.id
    })
    .useRole('mentor')
    .expect(200)
    .download();
}

const downloadMentorReport = async(mentoring: Mentoring) => {
  globalState.showNotification({
    title: globalState.translate('mentorings.download-report.notification.title'),
    text: globalState.translate('mentorings.download-report.notification.text'),
    type: 'info',
  })

  await globalState.getClient().get(`/report/evaluation/${mentoring.id}`).setParameters({
      isMentorReport: true,
      menteeUserId: mentoring.mentee.id
    })
    .useRole('mentor')
    .expect(200)
    .download();
}



const downloadMentoringReport = async(mentoring: Mentoring) => {
  await globalState.getClient().get(`/report/mentoring`).setParameters({
      mentoringId: mentoring.id
    })
    .useRole('mentor')
    .expect(200)
    .download();
}




const cancelSchedule = async(id: number) => {
  await mentoringManager.cancelSchedule(id);

  globalState.showNotification({
    title: globalState.translate('mentorings.edit.cancelled.title'),
    text: globalState.translate('mentorings.edit.cancelled.text'),
    type: 'success'
  });

  upcomingMentorings.value = upcomingMentorings.value.filter((mentoring) => mentoring.id !== id);
}


</script>

<template>
  <div class="">
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('mentoring.list.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('mentoring.list.description') }}</p>
      </div>
    </div>

    <div class="flex items-end justify-end max-w-6xl mt-16">
      <Button :text="globalState.translate('mentoring.create')" v-bind:is-primary="true" @click="router.push('/app/mentoring/create')" ></Button>
    </div> 

   
    <div class="flow-root max-w-6xl">
      <h2 class="text-3xl mb-8 font-light text-gray-900">{{ globalState.translate('mentoring.upcoming.title') }}</h2>
      <div v-if="upcomingMentorings.length" class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base text-gray-300 sm:pl-0">{{ globalState.translate('mentoring.list.listtitle.id') }}</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0">{{ globalState.translate('mentoring.list.listtitle.mentee') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('mentoring.list.listtitle.date') }}</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="mentoring in upcomingMentorings" :key="mentoring.id">
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-300 sm:pl-0">{{ mentoring.id }}</td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-base  text-gray-900 sm:pl-0">{{ mentoring.mentee.name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ globalState.formatDate(mentoring.date, 'datetime') }}</td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-base font-medium sm:pr-0 flex justify-end gap-4">
                  <div @click="router.push(`/app/mentorings/${mentoring.id}`)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.edit') }}
                  </div>
                  <div @click="cancelSchedule(mentoring.id)" class="text-red-600 hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.cancel') }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="">{{ globalState.translate('mentoring.upcoming.none') }}</div>

      <h2 class="text-3xl mb-8 mt-20 font-light text-gray-900">{{ globalState.translate('mentoring.past.title') }}</h2>
      <div v-if="pastMentorings.length" class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base text-gray-300 sm:pl-0">{{ globalState.translate('mentoring.list.listtitle.id') }}</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0">{{ globalState.translate('mentoring.list.listtitle.mentee') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('mentoring.list.listtitle.date') }}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-base font-semibold text-gray-900">{{ globalState.translate('mentoring.list.listtitle.completed') }}</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="mentoring in pastMentorings" :key="mentoring.id">
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-300 sm:pl-0">{{ mentoring.id }}</td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-base  text-gray-900 sm:pl-0">{{ mentoring.mentee.name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ globalState.formatDate(mentoring.date, 'datetime') }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-base text-gray-500">{{ globalState.translate('mentoring.list.item.done.' + mentoring.done)  }}</td>

                <td v-if="mentoring.done" class="relative whitespace-nowrap py-4 pl-3 text-right text-base font-medium flex items-end justify-end gap-4">
                  <div @click="downloadMentorReport(mentoring)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.download-mentor-report') }}
                  </div>
                  <div @click="downloadMenteeReport(mentoring)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.download-mentee-report') }}
                  </div>
                  <div @click="downloadMentoringReport(mentoring)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.download-mentoring-report') }}
                  </div>
                </td>
                <!--td v-else class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-base font-medium sm:pr-0">
                  <div @click="router.push(`/app/mentorings/${mentoring.id}`)" class="text-medeval hover:text-indigo-900 cursor-pointer select-none">
                    {{ globalState.translate('mentoring.list.listaction.continue') }}
                  </div>
                </td-->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="">{{ globalState.translate('mentoring.past.none') }}</div>
    </div>
  </div>
</template>

