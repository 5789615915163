<script setup lang="ts">
import GlobalState from '../../lib/GlobalState.js';
import SVGRenderer, { DimensionParam, propsConfig } from '../../lib/SVGRenderer';
import  { defineProps, inject } from 'vue';

const renderer = new SVGRenderer(153, 74);
const opts = defineProps(propsConfig);
const { width, height } = renderer.getDimensions(opts as DimensionParam);

const globalState = inject('globalState') as GlobalState;

</script>

<template>
  <img :src="`${globalState.getClient().getAPIUrl()}/rf/logo/product/width/${width}`" :width="width" :height="height" />
</template>