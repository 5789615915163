<script setup lang="ts">
import { inject, isShallow, ref } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import ComboBox from '../ui/ComboBox.vue';
import Text from '../ui/Text.vue';
import Button from '../ui/Button.vue';

import { Locale } from '../../lib/user/index.js';
import UserProfile from '../../lib/user/UserProfile.js';


const globalState = inject('globalState') as GlobalState;
const userManager = globalState.getUserManager();
const locales = ref<Locale[]>([]);
const loading = ref<boolean>(true);
const user = ref<UserProfile>(userManager.getProfile());
const saving = ref<boolean>(false);

(async() => {
  try {
    await user.value.load();

    for (const locale of await userManager.getLocales()) {
      locales.value.push(locale);
    }

  } catch (e: any) {
    globalState.displayError(e);
    return;
  }
  
  loading.value = false;
})();



const save = async() => {
  saving.value = true;

  try {
    await user.value.save();
  } catch (e: any) {
    if (e.message.includes('not valid')) {
      saving.value = false;
      return;
    } else {
      globalState.displayError(e);
    }

    saving.value = false;
    return;
  }

  saving.value = false;
}

</script>

<template>
  <div class="w-full h-full flex flex-col">
    <div class="h-full flex-grow">
      <h1 class="ml-5 text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('user.profile.title', [['name', globalState.getAuthentication().getFullName()]]) }}</h1>
      <p class="mt-4 ml-5 mb-8 text-base text-gray-700">{{ globalState.translate('user.profile.description') }}</p>
      
      <p class="mt-4 ml-5 mb-16 text-base text-gray-700">{{ globalState.translate('user.profile.id', [['id', user.id.toString()]]) }}</p>

      <div v-if="!loading" class="mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 max-w-5xl relative">


        <Text :error-message="user.firstnameError" 
          v-model="user.firstName" name="first-name" 
          :label="globalState.translate('user.profile.first-name')" 
          v-bind:required="true" />
        
        <Text :error-message="user.lastnameError" 
          v-model="user.lastName" name="last-name" 
          :label="globalState.translate('user.profile.last-name')"
          v-bind:required="true" />

        <ComboBox :error-message="user.localeError" 
          class=" " 
          v-model="user.locale" 
          name="locale" 
          :label="globalState.translate('user.profile.locale-selector.label')" 
          :items="user.locales" 
          :placeholder="globalState.translate('user.profile.locale-selector.placeholder')"></ComboBox>

        <div class="md:col-span-2 mt-6 ml-5 flex justify-end">
          <Button :disabled="saving" :is-primary="true" :text="globalState.translate('user.profile.save')" @click="save" />
        </div>
      </div>
      <div v-else class="h-full flex items-center justify-center">
        {{ globalState.translate('user.profile.loading') }}
      </div>

      
    </div>
  </div>
</template>



