import GlobalState from '../../../lib/GlobalState.js';



export interface MentoringSchedule {
    id: number;
    date: Date;
    mentee: {
        id: string;
        name: string;
    };
    mentor: {
        id: string;
        name: string;
    };
}


export interface Mentoring {
    id: number;
    date: Date;
    done: boolean
    mentee: {
        id: string;
        name: string;
    };
    mentor: {
        id: string;
        name: string;
    };
}

export interface Mentee {
    id: number;
    firstName: string;
    lastName: string;
}


export default class MentoringManager {

    globalState: GlobalState;

    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public async cancelSchedule(id: number) : Promise<void> {
        await this.globalState.getClient().delete(`/rf/mentoring/schedule/${id}`)
            .expect(200)
            .useRole('mentor')
            .send();
    }

    public async updateSchedule(id: number, date: Date) : Promise<Mentoring> {
        const response = await this.globalState.getClient().update(`/rf/mentoring/schedule/${id}`)
            .setJSONBody({
                date: date.toISOString(),
            })
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as { id: number };

        return this.getMentoring(data.id);
    }

    public async getUpcomingMentorings() : Promise<MentoringSchedule[]> {
        const response = await this.globalState.getClient().get('/rf/mentorings/upcoming')
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as MentoringSchedule[];

        return data.map(d => {
            d.date = new Date(d.date);
            return d;
        }).sort((a, b) => b.date.getTime() - a.date.getTime());
    }


    public async getPastMentorings() : Promise<Mentoring[]> {
        const response = await this.globalState.getClient().get('/rf/mentorings/past')
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as Mentoring[];

        return data.map(d => {
            d.date = new Date(d.date);
            return d;
        }).sort((a, b) => b.date.getTime() - a.date.getTime());
    }


    public async getMentoring(id: number) : Promise<Mentoring> {
        const response = await this.globalState.getClient().get(`/rf/mentorings/upcoming/${id}`)
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as Mentoring;

        data.date = new Date(data.date);

        return data;
    }


    public async getMentees() : Promise<Mentee[]> {
        const response = await this.globalState.getClient().get('/rf/mentoring/mentees')
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as Mentee[];

        return data;
    }


    public async createSchedule(menteeUserId: number, date: Date) : Promise<Mentoring> {
        const response = await this.globalState.getClient().post('/rf/mentoring/schedule')
            .setJSONBody({
                menteeUserId,
                date: date.toISOString(),
            })
            .expect(200)
            .useRole('mentor')
            .send();

        const data = await response.json() as { id: number };

        return this.getMentoring(data.id);
    }
}