<script setup lang="ts">
import Button from '../../ui/Button.vue';
import { inject, ref, watch } from 'vue';
import Mentoring from '../src/Mentoring.js';
import GlobalState from '../../../lib/GlobalState.js';
import DateTimeSelector from '../../ui/DateTimeSelector.vue';

const globalState = inject('globalState') as GlobalState;


const { mentoring } = defineProps<{
  mentoring: Mentoring
}>();

const emit = defineEmits<{
  'back': [],
  'next': [],
}>();


const mentoringDate = ref<Date>(new Date());
const errorMessage = ref<string | null>(null);
let scheduleId: number | null = null;


const in4weeks = globalState.getDateFormatter().addDays(new Date(), 28);
in4weeks.setMinutes(0, 0, 0);

(async() => {
  const schedule = await mentoring.getSchedule();
  if (schedule) {
    mentoringDate.value = schedule.date;
    scheduleId = schedule.id;
  }
})()

const setRecommendation = () => {
  errorMessage.value = null;
  mentoringDate.value = new Date(in4weeks);
}

const next = async () => {
  if (!mentoringDate.value) return;

  if (mentoringDate.value.getTime() < new Date().getTime() + 1000 * 60 * 60 * 24) {
    errorMessage.value = globalState.translate('mentoring.dateselector.error.past');
    return;
  }

  if (scheduleId) {
    await mentoring.updateSchedule(scheduleId, mentoringDate.value);
  } else {
    await mentoring.createSchedule(mentoringDate.value);
  }

  emit('next');
}

const updateDate = (date: Date | null) => {
  if (!date) return;
  errorMessage.value = null;
}

const back = () => {
  emit('back');
}
</script>

<template>
  <div class="h-full w-full m-8">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-2xl font-4xl mb-16 mx-5">
          {{ globalState.translate('mentoring.dateselector.text') }} <span class="text-medeval">{{ mentoring.getMenteeUserName() }}</span> 
        </div>

        <div class="flex items-center justify-center">
          <DateTimeSelector @time-selected="updateDate" v-model:date="mentoringDate" :errorMessage="errorMessage" class=""></DateTimeSelector>
        </div>
        

        <div class="mx-5 mt-4 flex justify-center">
          <div @click="setRecommendation" class="w-80 underline cursor-pointer select-none">{{ globalState.translate('mentoring.dateselector.recommendedDate', [['date', globalState.formatDate(in4weeks, 'date-mid')]]) }}</div>
        </div>

        <div class="flex mt-8">
          <Button :text="globalState.translate('mentoring.dateselector.back')" @click="back" class="my-5"></Button>
          <div class="w-full flex justify-end">
            <Button :text="globalState.translate('mentoring.dateselector.next')" @click="next" class="my-5 ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

