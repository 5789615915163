<script setup lang="ts">
import { inject, ref } from 'vue';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import GlobalState from '../../lib/GlobalState.js';

const globalState = inject('globalState') as GlobalState;

</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 z-50 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition v-for="notification of globalState.notifications.value"  enter-active-class="transform ease-out duration-600 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div  class="pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <InformationCircleIcon v-if="notification.type === 'info'" class="h-7 w-7 text-blue-400" aria-hidden="true" />
                <CheckCircleIcon v-if="notification.type === 'success'" class="h-7 w-7 text-green-400" aria-hidden="true" />
                <ExclamationCircleIcon v-if="notification.type === 'warning'" class="h-7 w-7 text-orange-400" aria-hidden="true" />
                <ExclamationTriangleIcon v-if="notification.type === 'error'" class="h-7 w-7 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-base font-medium text-gray-900">{{ notification.title }}</p>
                <p class="mt-1 text-base text-gray-500">{{ notification.text }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="globalState.removeNotification(notification.id)" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>