



export default class PeristentStorage {

    private readonly storage : Map<string, any> = new Map();
    private readonly storageKey : string = 'storage';

    constructor(name: string) {
        this.storageKey = `${name}-storage`;
        this.load();
    }

    public set(key: string, value: any) : void {
        this.storage.set(key, value);
        this.perist();
    }

    public setMany(values: {[index: string]: any}) : void {
        for (const [key, value] of Object.entries(values)) {
            this.storage.set(key, value);
        }
        this.perist();
    }

    public get(key: string) : any {
        return this.storage.get(key);
    }

    public remove(key: string) : void {
        this.storage.delete(key);
        this.perist();
    }

    public clear() : void {
        this.storage.clear();
        this.perist();
    }

    public has(key: string) : boolean {
        return this.storage.has(key);
    }

    private perist() : void {
        const data = JSON.stringify(Array.from(this.storage.entries()));
        localStorage.setItem(this.storageKey, data);
    }

    private load() : void {
        const data = localStorage.getItem(this.storageKey);
        if (data) {
            const storage = JSON.parse(data);
            for (const [key, value] of storage) {
                this.storage.set(key, value);
            }
        }
    }
}