<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue';
import GlobalState from '../../lib/GlobalState.js';
import ComboBox, { ComboBoxItem } from '../ui/ComboBox.vue';
import InfoComponent from '../ui/InfoComponent.vue';
import OptionGroup, { Option } from '../ui/OptionGroup.vue';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid';

interface Evaluation {
    id: number;
    created: string;
    menteeName: string;
}

interface EvaluationDistribution {
    count: number;
    monthIndex: string;
    evaluations: Evaluation[];
}

interface Reviewer {
    id: number;
    name: string;
    evaluations: EvaluationDistribution[];
    totalEvaluations: number;
    expanded?: boolean;
    ranking: string;
    rankingColor: string;
    rankingOrder: number;
}


const globalState = inject('globalState') as GlobalState;
const reviewers = ref<Reviewer[]>([]);

const loading = ref<boolean>(true);
const timespans = ref<ComboBoxItem[]>([ {
  id: 2,
  name: globalState.translate('supervision.evaluators.timespan.6-months'),
  identifier: '6-months'
}, {
  id: 3,
  name: globalState.translate('supervision.evaluators.timespan.9-months'),
  identifier: '9-months'
}, {
  id: 4,
  name: globalState.translate('supervision.evaluators.timespan.12-months'),
  identifier: '12-months'
}]);
const selectedTimespan = ref<ComboBoxItem>(timespans.value[0]);


watch(selectedTimespan, () => {
  if (selectedTimespan.value) {
    loadData();
  }
});




const loadData = async() => {
  loading.value = true;

  const response = await globalState.getClient().get(`/rf/supervision/evaluation-compliance-report`)
    .setParameters({
      timespan: selectedTimespan.value!.identifier,
    })
    .expect(200)
    .useRole('supervisor')
    .send();

  if (response.status() === 200) {
    const data = await response.json() as Reviewer[];

    data.sort((a, b) => b.totalEvaluations - a.totalEvaluations);

    reviewers.value.splice(0, reviewers.value.length);

    for (const item of data) {

      // sort comments by date descending
      item.evaluations.sort((a, b) => new Date(a.monthIndex).getTime() - new Date(b.monthIndex).getTime());

      for (const evaluation of item.evaluations) {
        evaluation.evaluations.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      }

      reviewers.value.push(item);
    }
  
    loading.value = false;
  }
}


loadData();



function interpolate(hexcolor1: string, hexcolor2: string, value: number): string {
    if (value < 0 || value > 1) {
        throw new Error('Value must be between 0 and 1');
    }

    // Convert hex colors to RGB
    const color1 = hexToRgb(hexcolor1);
    const color2 = hexToRgb(hexcolor2);

    if (!color1 || !color2) {
        throw new Error('Invalid hex color');
    }

    // Interpolate each color channel
    const r = Math.round(color1.r + (color2.r - color1.r) * value);
    const g = Math.round(color1.g + (color2.g - color1.g) * value);
    const b = Math.round(color1.b + (color2.b - color1.b) * value);

    // Convert the result back to hex
    return rgbToHex(r, g, b);
}

function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the leading #
    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
        // Convert shorthand hex to full hex
        hex = hex.split('').map(char => char + char).join('');
    }

    if (hex.length !== 6) {
        return null;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number): string {
    return '#' + [r, g, b].map(channel => {
        const hex = channel.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');
}


const getRankingColor = (answerCount: number) => {
  const value = answerCount / 10;
  return interpolate('#FFD595', '#04D700', value > 1 ? 1 : value);
}
</script>

<template>
  <div class=" max-w-6xl">
    
    <div class="flex items-center">
      <div class="flex-auto">
        <h1 class="text-5xl font-thin text-gray-900 border-b border-slate-300">{{ globalState.translate('supervision.evaluators.title') }}</h1>
        <p class="mt-4 text-base text-gray-700">{{ globalState.translate('supervision.evaluators.description') }}</p>
      </div>
    </div>

    <div class="flex items-start mt-6">
      <div class="w-1/2">
        <div class="mb-4">{{ globalState.translate('supervision.evaluators.timespan') }}</div>
        <OptionGroup :options="timespans" v-model="selectedTimespan" class="flex flex-col gap-2" ></OptionGroup>
      </div>
    </div>

    <div class="mt-12 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div v-if="loading" class="h-full text-xl flex items-center justify-center">
            {{ globalState.translate('supervision.mentees.loading') }}
          </div>
          <table v-else class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pr-3 text-left text-base pl-4">{{ globalState.translate('supervision.evaluators.list.listtitle.name') }}</th>
                <th scope="col" class="py-3.5 text-left text-base font-semibold text-gray-900">
                  <div class="flex items-center">
                    {{ globalState.translate('supervision.evaluators.list.listtitle.rank') }}
                    <InfoComponent :text="globalState.translate('supervision.evaluators.info-bubble.evaluation-count.text')" :title="globalState.translate('supervision.evaluators.info-bubble.evaluation-count.title')"></InfoComponent>
                  </div>
                </th>
                <th scope="col" class="py-3.5 text-left text-base font-semibold text-gray-900">
                  <div class="flex items-center">
                    {{ globalState.translate('supervision.evaluators.list.listtitle.evaluation-mean') }}
                    <InfoComponent :text="globalState.translate('supervision.evaluators.info-bubble.evaluation-mean.text')" :title="globalState.translate('supervision.evaluators.info-bubble.evaluation-mean.title')"></InfoComponent>
                  </div>
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="reviewer of reviewers" :key="reviewer.id" >
                <tr class=" cursor-pointer border-t border-slate-200" @click="reviewer.expanded = !reviewer.expanded" :class="{'bg-slate-50': reviewer.expanded }" >
                  <td class="h-1">
                    <div :class="{'border-l font-semibold': reviewer.expanded }" class="whitespace-nowrap px-3 text-base pl-4 h-full flex items-center">{{ reviewer.name }}</div></td>
                  <td class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <span :style="`background-color: ${getRankingColor(reviewer.totalEvaluations)}DD`" class="text-white text-xs px-4 py-2 rounded-full font-bold">{{ reviewer.totalEvaluations }}</span>
                  </td>
                  <td class="whitespace-nowrap  pl-4 pr-3 text-base  text-gray-900 sm:pl-0">
                    <span :style="`background-color: ${reviewer.rankingColor}`" class="text-white text-xs px-4 py-2 rounded-full font-bold">{{ reviewer.ranking }}</span>
                  </td>
                  <td class="">
                    <div :class="{'border-r': reviewer.expanded }" class="whitespace-nowrap px-3 text-base h-full flex items-center justify-end">
                      <ChevronDownIcon v-if="!reviewer.expanded" class="w-6 h-6 m-2 cursor-pointer"></ChevronDownIcon>
                      <ChevronUpIcon v-else class="w-6 h-6 m-2 cursor-pointer"></ChevronUpIcon>
                    </div>
                  </td>
                </tr>
                
                <tr v-if="reviewer.expanded" class="max-h-screen">
                  <td colspan="4" class="">
                    <div class="py-4 px-4 bg-slate-100 border-b border-x rounded-b mb-4 gap-4 columns-3">
                      
                      
                      <div class="flex flex-col gap-1 w-full break-inside-avoid-column">
                        <div class="font-semibold mb-2">{{ globalState.translate('supervision.evaluators.distribution.title') }}</div>
                        <div v-if="reviewer.totalEvaluations > 0" class="bg-white rounded flex flex-col items-center p-1 overflow-x-auto max-w-96">
                          <div class="flex items-end mt-4 gap-[1px] text-xs h-10 flex-row-reverse">
                            <div v-for="evaluation of reviewer.evaluations" :key="evaluation.monthIndex" :class="{
                              'h-0': evaluation.count === 0,
                              'h-2': evaluation.count === 1,
                              'h-4': evaluation.count === 2,
                              'h-6': evaluation.count === 3,
                              'h-8': evaluation.count >= 4,
                              'h-10': evaluation.count >= 5,
                            }" class="w-6 bg-blue-300 flex items-end justify-center border-b"> {{ evaluation.count }}</div>
                          </div>
                          <div class="flex mt-4 text-xs gap-[1px] mb-4 flex-row-reverse">
                            <div v-for="evaluation of reviewer.evaluations" :key="evaluation.monthIndex" class="w-6 -rotate-90 flex justify-end whitespace-nowrap mb-2">{{ globalState.getDateFormatter().getShortMonthName(new Date(evaluation.monthIndex)) }} {{ new Date(evaluation.monthIndex).getFullYear().toString().substring(2) }}</div>
                          </div>
                        </div>
                        <div v-else>
                          {{ globalState.translate('supervision.mentees.no-data') }}
                        </div>
                      </div>

                      
                      <div class="bg-white rounded p-2 flex flex-col gap-2 w-full mt-6 break-inside-avoid-column" v-for="evaluation of reviewer.evaluations.filter(e => e.count > 0)" :key="evaluation.monthIndex" >
                        <div class="border-b font-semibold">{{ globalState.translate('supervision.evaluators.evaluations.prefix') }} {{ globalState.getDateFormatter().getMonthName(new Date(evaluation.monthIndex)) }} {{ new Date(evaluation.monthIndex).getFullYear() }}</div>
                        <div class="text-slate-400" v-if="evaluation.evaluations.length === 0">{{ globalState.translate('no-data') }}</div>
                        <div v-else>
                          <div v-for="evalItem of evaluation.evaluations" :key="evaluation.monthIndex + 'kid'" class="flex items-center gap-4">
                            <div class="flex-auto">{{ evalItem.menteeName }}</div>
                            <div>{{ globalState.formatDate(new Date(evalItem.created), 'date-mid') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fadeHeight-enter-active, .fadeHeight-leave-active {
  transition: all 0.3s ease;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  max-height: 2300px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>