import GlobalState from '../GlobalState.js';
import UserProfile from './UserProfile.js';
import { User } from "./index.js";


export interface UserRole {
    id: number;
    name: string;
    description: string;
    identifier: string;
    roles: string[];
    selected?: boolean;
}


export interface Locale {
    id: number;
    name: string;
}


export interface MentorUser {
    id: number;
    name: string;
}

export interface Domain {
    id: number;
    domain: string;
}


export interface UserListUser {
    id: number;
    name: string;
    email: string;
    userRoles: string[];
    mentoredBy: string | null;
}


export class UserManager {

    private roles: UserRole[] = [];
    private locales: Locale[] = [];
    private mentors: MentorUser[] = [];
    private domains: Domain[] = [];

    private rolesLoaded: boolean = false;
    private localesLoaded: boolean = false;
    private mentorsLoaded: boolean = false;
    private domainsLoaded: boolean = false;


    private readonly globalState: GlobalState;
    private role = 'user-admin';


    constructor(globalState: GlobalState) {
        this.globalState = globalState;
    }


    public user() {
        return new User(this, this.globalState);
    }



    public async listUsers() : Promise<UserListUser[]> {
        const response = await this.globalState.getClient().get('/rf/admin/users')
            .useRole(this.role)
            .expect(200)
            .send();
        
        return await response.json();
    }


    public async getDomains() : Promise<Domain[]> {
        if (!this.domainsLoaded) {
            await this.loadDomains();
            this.domainsLoaded = true;
        }

        return this.domains;
    }


    public async getRoles() : Promise<UserRole[]> {
        if (!this.rolesLoaded) {
            await this.loadRoles();
            this.rolesLoaded = true;
        }

        return this.roles.slice();
    }


    public async getLocales() : Promise<Locale[]> {
        if (!this.localesLoaded) {
            await this.loadLocales();
            this.localesLoaded = true;
        }

        return this.locales;
    }


    public resetMentors() {
        this.mentorsLoaded = false;
        this.mentors = [];
    }

    public async getMentors() : Promise<MentorUser[]> {
        if (!this.mentorsLoaded) {
            await this.loadMentors();
            this.mentorsLoaded = true;
        }

        return this.mentors;
    }
    

    public getProfile() : UserProfile {
        return new UserProfile(this.globalState);
    }

    private async loadMentors() {
        const response = await this.globalState.getClient().get('/rf/admin/mentors')
            .useRole(this.role)
            .expect(200)
            .send();

        const mentors = await response.json() as MentorUser[];

        mentors.sort((a, b) => a.name.localeCompare(b.name));

        mentors.unshift({
            id: 0,
            name: this.globalState.translate('user-manager.mentoring.no-mentor'),
        });

        this.mentors = mentors;
        this.mentorsLoaded = true;
    }
    

    private async loadRoles() {
        const response = await this.globalState.getClient().get('/rf/admin/user-roles')
            .useRole(this.role)
            .expect(200)
            .send();

        this.roles = await response.json();
    }


    private async loadLocales() {
        const response = await this.globalState.getClient().get('/rf/locales/organization')
            .useRole('user')
            .expect(200)
            .send();

        this.locales = await response.json();
    }

    
    private async loadDomains() {
        const response = await this.globalState.getClient().get('/rf/admin/domains')
            .useRole(this.role)
            .expect(200)
            .send();

        this.domains = await response.json();
    }
}