<script setup lang="ts">
import Button from '../../ui/Button.vue';
import GlobalState from '../../../lib/GlobalState.js';
import { inject } from 'vue';
import Evaluation from '../src/Evaluation.js';

const globalState = inject('globalState') as GlobalState;


const { evaluation } = defineProps<{
  evaluation: Evaluation
}>();

const emit = defineEmits<{
  'back': [],
  'resume': [],
  'new': [],
}>();


const back = () => emit('back');
const resume = () => emit('resume');
const newEval = () => emit('new');


const lastEveluationDate = globalState.formatDate(evaluation.getEvaluationDate());
const evaluationStartDate = globalState.formatDate(evaluation.getEvaluationStartDate());
const evaluationEndDate = globalState.formatDate(evaluation.getEvaluationEndDate());

</script>

<template>
  <div class="h-full w-full">
    <div class="flex items-center justify-center h-full">
      <div class="flex flex-col w-[700px] h-fit mb-24">
        <div class="text-xl sm:text-2xl font-4xl mb-16">
          <div>
            {{ globalState.translate('evaluation.resume.text.1') }} 
            <span class="text-medeval">{{ evaluation.getEvaluationUserName() }}</span> 
            {{ globalState.translate('evaluation.resume.text.2', [['date', lastEveluationDate]]) }}
            <span class="text-medeval">{{ evaluationStartDate }} - {{ evaluationEndDate }}</span>.
          </div>
          <div class="mt-8">{{ globalState.translate('evaluation.resume.question') }}</div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <Button v-if="!evaluation.isSelfEvaluation()" :text="globalState.translate('evaluation.resume.back')" @click="back" class="my-5"></Button>
          <div class="w-full flex sm:justify-end flex-col sm:flex-row">
            <Button :text="globalState.translate('evaluation.resume.resume')" @click="resume" class="sm:my-5"></Button>
            <Button :text="globalState.translate('evaluation.resume.new')" @click="newEval" class="my-5 sm:ml-4" v-bind:is-primary="true"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

