<script setup lang="ts">
import GlobalState from '../../lib/GlobalState.js';
import SVGRenderer, { DimensionParam, propsConfig } from '../../lib/SVGRenderer';
import  { defineProps, inject, ref } from 'vue';

const renderer = new SVGRenderer(153, 74);
const opts = defineProps(propsConfig);
const { width, height } = renderer.getDimensions(opts as DimensionParam);

const globalState = inject('globalState') as GlobalState;
const img = ref<HTMLImageElement | null>(null);

(async() => {
  const response = await globalState.getClient().get(`/rf/logo/client`)
    .useRole('user')
    .expect(200)
    .send();

  const blob = await response.blob();

  if (img.value) img.value.src = URL.createObjectURL(blob);
})();

</script>

<template>
  <div>
    <img ref="img" :width="width" :height="height" />
  </div>
</template>