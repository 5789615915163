<script setup lang="ts">
import Logo from './lib/Logo.vue';
import ClientLogo from './lib/ClientLogo.vue';
import { useRoute, useRouter } from 'vue-router';
import { inject, ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import GlobalState from '../lib/GlobalState.js';
import { NavigationItem } from '../lib/base/Navigation.js';

const globalState = inject('globalState') as GlobalState;

const isLocalhost = window.location.hostname.includes('local.');
const isTestTenant = globalState.getAuthentication().getTenantId() === 1;

const router = useRouter();
const route = useRoute();

const navigationItems = globalState.getAuthentication().getNavigation().getTopNavigation();
const bottomNavigationItems = globalState.getAuthentication().getNavigation().getBottomNavigation();
const sidebarOpen = ref(false);

const findCurrentNavigationItem = (items: NavigationItem[]) : NavigationItem | null => {
  for (const item of items) {
    if (item.path === route.path) {
      return item;
    }
    if (item.children) {
      const child = findCurrentNavigationItem(item.children);
      if (child) return child;
    }
  }
  return null;
}

let lastOpenParent : NavigationItem | null  = null;
const currentNavigationItem = ref(findCurrentNavigationItem(navigationItems.value) || navigationItems.value[0]);
if (currentNavigationItem.value.parent) {
  currentNavigationItem.value.parent.open = true;
  lastOpenParent = currentNavigationItem.value.parent;
}

currentNavigationItem.value.current = true;


const toggleItem = (navigationItem: NavigationItem) => {
  // close previous opened item
  if (!navigationItem.open) {
    if (lastOpenParent && lastOpenParent !== navigationItem) {
      lastOpenParent.open = false;
    }

    lastOpenParent = navigationItem;
    navigationItem.open = true;
  } else {
    navigationItem.open = false;
  }
}

const navigate = (navigationItem: NavigationItem) => {
  currentNavigationItem.value.current = false;
  currentNavigationItem.value.open = false;

  if (currentNavigationItem.value.parent) {
    currentNavigationItem.value.parent.open = false;
  }

  navigationItem.current = true;
  navigationItem.open = true;

  if (navigationItem.parent) {
    navigationItem.parent.open = true;
  }

  currentNavigationItem.value = navigationItem;
  router.push(navigationItem.path);
  sidebarOpen.value = false;
}
</script>

<template>
  <div class="flex-1 flex flex-col">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <!-- Sidebar component -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                <div class="flex h-16 shrink-0 items-center mt-2">
                  <Logo class="cursor-pointer ml-1 mt-3" height="100"></Logo>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7 mt-2">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigationItems" :key="item.path">
                          <a @click="item.children.length > 0 ? toggleItem(item) : navigate(item)" :class="[item.current ? 'bg-gray-50 text-medeval' : 'text-gray-700 hover:text-medeval hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-lg font-normal leading-6 cursor-pointer no-underline']">
                            <div :class="(item.current ? '' : 'group-hover:text-medeval')" class="w-8 h-8">
                              <span class="material-symbols-outlined">{{ item.icon }}</span>
                            </div>
                            {{ item.name }}
                          </a>
                          <div v-if="item.open" class="">
                            <div v-for="child in item.children" :key="child.path">
                              <a @click="navigate(child)" :class="[child.current ? 'bg-gray-50 text-medeval' : 'text-gray-700 hover:text-medeval hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 pl-8 font-normal leading-6 cursor-pointer no-underline']">
                                <div :class="(child.current ? '' : 'group-hover:text-medeval')" class="w-6 h-6">
                                  <span class="material-symbols-outlined">{{ child.icon }}</span>
                                </div>
                                {{ child.name }}
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <nav class="flex flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in bottomNavigationItems" :key="item.path">
                          <a @click="item.children.length > 0 ? toggleItem(item) : navigate(item)" :class="[item.current ? 'bg-gray-50 text-medeval' : 'text-gray-700 hover:text-medeval hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-lg font-normal leading-6 cursor-pointer no-underline']">
                            <div :class="(item.current ? '' : 'group-hover:text-medeval')" class="w-8 h-8">
                              <span class="material-symbols-outlined">{{ item.icon }}</span>
                            </div>
                            {{ item.name }}
                          </a>
                          <div v-if="item.open" class="">
                            <div v-for="child in item.children" :key="child.path">
                              <a @click="navigate(child)" :class="[child.current ? 'bg-gray-50 text-medeval' : 'text-gray-700 hover:text-medeval hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 pl-8 font-normal leading-6 cursor-pointer no-underline']">
                                <div :class="(child.current ? '' : 'group-hover:text-medeval')" class="w-6 h-6">
                                  <span class="material-symbols-outlined">{{ child.icon }}</span>
                                </div>
                                {{ child.name }}
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

   

    <div :class="{
      'bg-orange-200' : isLocalhost,
      'bg-cyan-100' : isTestTenant && !isLocalhost,
    }" class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-8 py-4 shadow-sm">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700" @click="sidebarOpen = true">
        <Bars3Icon class="h-7 w-7 mt-2 mb-2" aria-hidden="true" />
      </button>
      <div class="flex-1 flex items-center">
        <Logo @click="router.push('/')" class="cursor-pointer" height="60"></Logo>
      </div>
      <div class="hidden sm:flex justify-end">
        <ClientLogo class="" height="70"></ClientLogo>
      </div>
    </div>

    <main class="flex flex-col flex-1 px-8 sm:px-12 md:px-20 py-10 overflow-auto">
      <router-view :key="$route.fullPath"></router-view>
    </main>
  </div>
</template>

